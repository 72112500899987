import { useState, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Paper,
  Popper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import Transitions from "../../../../../ui-component/Transitions";
import User1 from "atic-common-helpers/asset/images/user-round.svg";

// assets
import { IconLogout, IconSettings, IconUser, IconHelp } from "@tabler/icons";
import { clearUserDetails, userDetails } from "../../../../../features/auth/userSlice";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MainCard from "../../../../../ui-component/cards/MainCard";
import { Success } from "../../../../../atic-common-helpers/helpers/toast.helper";
import deleteAlert from "../../../../../component/HOC/deleteAlert";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LockIcon from '@mui/icons-material/Lock';
import { HiMiniUserCircle } from "react-icons/hi2";
import { MdDashboard } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
// Included by sanjana SOP-18
import axios from "axios";
import EmptyProfileImg from '../../../../../asset/images/UserProfile.jpg'
// End of SOP-18


// ==============================|| PROFILE MENU ||====================== ======== //

const ProfileSection = () => {
  const theme = useTheme();
  const customization = useSelector((state: any) => state.customization);
  const navigate = useNavigate();
  const userDetail = useSelector(userDetails);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);
  const dispatch = useDispatch();
  const isPerson: any = sessionStorage.getItem("GlobalVariable");
  const singedPerson = JSON.parse(isPerson);
  //console.log("singedPerson",singedPerson)

  const logout = () => {
    dispatch(clearUserDetails);
    sessionStorage.clear();
    localStorage.clear();//SOP-85 Included By PCS [removing JWT from local storage]
    Success("User Logout Successfully")
    navigate("/");
  };

  const handleLogout = () => {
    deleteAlert({title:"Logout",message:"Are you sure?",confirmButtonText:"Yes",onConfirmed:logout})
  
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };


  // Included by sanjana SOP-18 to add user profile image

  const [ProfileImg, setProfileImg]= useState<any>(null);


  const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        let UserEmail = GlobalData.GlobalUserEmail

      // To get company user details API as per the email 
      const ToGetCompanyUserDetails = async ()=>{

        const  CompanyUserDetailsPayload = {
            UserName:UserEmail,
            Active:"Y"
        }
        //console.log("CompanyUserDetailsPayload",CompanyUserDetailsPayload)
        try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/company/getCompanyUserDetails`,CompanyUserDetailsPayload);
        //console.log(res.data.data);
        const UserData = res.data.data[0]
        setProfileImg(UserData.photo_url)
        } catch (err) {
        console.log( "co",err); 
        } 
    
        }

  // End of SOP-18


  const prevOpen = useRef(open);
  useEffect(() => {

    // Included by sanjana SOP-18 to call ToGetCompanyDetails API
    ToGetCompanyUserDetails();
    // End of SOP-18


    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <>
    <ClickAwayListener onClickAway={handleClose}>
      <Box>
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "0px",
          transition: "all .2s ease-in-out",
          borderColor: "transparent",
          backgroundColor: "transparent",
          color: theme.palette.primary?.tableHead,
          "&.MuiChip-clickable": {
            ":hover": {
              borderColor: "transparent",
              background: "transparent",
            },
            ":active": {
              boxShadow: "none",
            },
          },
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: "transparent",
            background: "transparent",
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}     
        icon={
          <Stack direction="row" alignItems="center" color= {theme.palette.primary.buttonColor} fontSize={"20px"}>
       
          {/* <AccountCircleOutlinedIcon  sx={{fontSize:"40px",marginRight:"5px", color:'#25425f'}} /> */}
          {/* Included by sanjana SOP-18 to set user avatar */}
            <Avatar alt="Profile" 
            // Included by sanjana SOP-56 to include backgroung color and border
            sx={{
              bgcolor:'white',
              border:'1px solid grey'
            }}
            // End of SOP-56
             src={ProfileImg || EmptyProfileImg} />
          {/* End of SOP-18 */}
         <Typography color= {theme.palette.primary.buttonColor} 
        //  Included by sanjana SOP-18 to give space between User Profile and User Name
         ml={1} 
        // End of SOP-18 
         fontSize={"20px"}>
          {singedPerson.GlobalUserName}
         </Typography>  
          
        </Stack>
        
        }   
        label={
          <Stack direction="row" alignItems="center" color= {theme.palette.primary.buttonColor} fontSize={"24px"}>
            <KeyboardArrowDownIcon />
          </Stack>
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <MainCard
                border={false}
                elevation={16}
                content={false}
                boxShadow
                shadow={theme.shadows[16]}
              >
                <Box>
                  <List
                    component="nav"
                    sx={{
                      width: "100%",
                      maxWidth: 300,
                      minWidth: 250,
                      backgroundColor: theme.palette.background.paper,
                      borderRadius: "10px",
                      px: 1,
                      [theme.breakpoints.down("md")]: {
                        minWidth: "100%",
                      },
                      "& .MuiListItemButton-root": {
                        mt: 0.5,
                        "& .MuiListItemIcon-root": {
                          color: theme.palette.primary?.tableHead,
                        },
                      },
                      "& .MuiListItemText-root": {
                        "& .MuiTypography-root": {
                          color: theme.palette.text.dark,
                          fontSize: "18px",
                        },
                      },
                    }}
                  >

                    <ListItemButton
                      sx={{ borderRadius: `${customization.borderRadius}px` }}
                      selected={selectedIndex === 0}
                      onClick={()=>{
                        navigate("/profile")
                        handleClose();
                      }}
                    >
                      <ListItemIcon>
                        {/* Modified by sanjana SOP-56 to set user image instead of icon */}
                        {/* <HiMiniUserCircle  size="1.5rem" /> */}
                        <Avatar alt="Profile" 
                        sx={{
                          bgcolor:'white',
                          border:'1px solid grey',
                          height:'1.5rem',
                          width:'1.5rem'
                        }}
                        src={ProfileImg || EmptyProfileImg} />
                        {/* End of SOP-56 */}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2" ml={1}>Profile</Typography>
                        }
                      />
                    </ListItemButton>

                    <ListItemButton
                      sx={{ borderRadius: `${customization.borderRadius}px` }}
                      selected={selectedIndex === 1}
                      onClick={()=>{
                        navigate('/change_password')
                        handleClose();
                      }}
                      >
                      <ListItemIcon>
                        <LockIcon style={{fontSize:'1.5rem'}}/>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2" ml={1}>
                            Change Password
                            </Typography>
                        }
                      />
                    </ListItemButton>
                    <ListItemButton
                      sx={{ borderRadius: `${customization.borderRadius}px` }}
                      selected={selectedIndex === 4}
                      onClick={handleLogout}
                    >
                      <ListItemIcon>
                        <IconLogout stroke={1.5} size="1.5rem" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2" ml={1}>Logout</Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                </Box>
              </MainCard>
            </Paper>
          </Transitions>
        )}
      </Popper>
      </Box>
    </ClickAwayListener>
      
    </>
  );
};

export default ProfileSection;

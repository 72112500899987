import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import Layout from "../atic-common-helpers/layout/MainLayout";
import LoginPage from "../pages/Login/Login";
import HomePage from "../pages/Home/HomePage";
import ChangePasswordPage from "../pages/ChangePassword/ChangePassword";
import ProfilePage from "../pages/Profile/Profile";
import DashBoardPage from "../pages/DashBoard/Dashboard";
import BackOfficePage from "../pages/BackOffice/BackOffice";
// Included by sanjana SOP-15
import Categories from "../pages/Categories/Categories";
// End of SOP-15
// Included by sanjana SOP-16
import SubCategoriesPage from "../pages/SubCategories/SubCategories";
// End of SOP-16
// Included by sanjana SOP-17
import EmployeePage from "../pages/Employee/Employee";
import AddEmployeePage from "../pages/Employee/AddEmployee";
import EditEmployeePage from "../pages/Employee/EditEmployee";
// End of SOP-17

// Included by sanjana SOP-19
import AssetPage from "../pages/Assets/Assets";
import AddAssetPage from "../pages/Assets/AddAsset";
import EditAssetPage from "../pages/Assets/EditAsset";
// End of SOP-19

//SOP-20 Included By PCS
import Department from "../pages/Department/Department";
// EOF SOP-20 Included By PCS

//SOP-21 Included By PCS
import Designation from "../pages/Designation/Designation";
// EOF SOP-21 Included By PCs

// Included by sanjana SOP-25
import RequetAnAssetPage from "../pages/RequestAnAsset/RequestAnAsset";
// End of SOP-25

// Included by sanjana SOP-26
import MyTicketsPage from "../pages/Ticketing/MyTickets";
// End of SOP-26

// Included by sanjana SOP-29
import MyApprovalsPage from "../pages/MyApprovals/MyApprovals";
// End of SOP-29

// Included by sanjana SOP-30
import AllocationPage from "../pages/Allocations/Allocation";
// End of SOP-30

//SOP-22 Included By PCS
import EmailConfigurationCompany from "../pages/EmailConfigurationCompany/EmailConfigurationCompany";
// EOF SOP-22 Included By PCS

//SOP-23 Included By PCS
import EmailConfigurationIntopz from "../pages/EmailConfigurationIntopz/EmailConfigurationIntopz";
// EOF SOP-23 Included By PCS

//Included By Sanjana SOP-34 
import TicketListPage from "../pages/TicketList/ticketList";
// EOF SOP-34

//Included By Sanjana SOP-37 
import TicketingServicesPage from "../pages/TicketingServices/TicketingServices";
// EOF SOP-37

//Included By Sanjana SOP-40 
import ImportAssetCSVPage from "../pages/ImportCSV/ImportAssetCSV";
// EOF SOP-40


//Included By Sanjana SOP-44 
import  ForgotPasswordPage from "../pages/ForgotPassword/ForgotPasswordPage";
// EOF SOP-44

//Included By Sanjana SOP-46 
import UploadCompanyLogoPage from "../pages/UploadCompanyLogo/UploadCompanyLogo";
// EOF SOP-46

// include by TS SOP-49 - start (RaiseATicket Page)
// Asset Management-Ticketing-Create Page
import RaiseATicketPage from "../pages/Ticketing/RaiseATicket";
// include by TS SOP-49 - end

// include by TS SOP-55 - start (RaiseATicket Page)
// Asset Management-Ticketing-View and Edit Page
import EditRaiseATicketPage from "../pages/Ticketing/EditRaiseATicket"; //EditRaiseATicketPage
import ViewRaiseATicketPage from "../pages/Ticketing/ViewRaiseATicket"; //ViewRaiseATicketPage
// include by TS SOP-55 - end

// include by SANJANA SOP-56
import AddHierarchyChartPageStep1 from "../pages/Hierarchy/AddHierarchyChartStep1";
import AddHierarchyChartPageStep2 from "../pages/Hierarchy/AddHierarchyChartStep2";
import ViewHierarchyChart from "../pages/Hierarchy/ViewHierarchyChart";
// End of SOP-56

// Included by sanjana SOP-58
import HRHierarchyPage from "../pages/Hr_Hierarchy/HrHierarchy";
// End of SOP-58

//  (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - start
import AddHRNewHireRequest from "../pages/HR_New_Hire_Request/HR_New_Hire_Request";
//  (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - end

//  (SOP-66) HR - Hire Request - Mapping with Employee Enrollment (HRNewHireRequest Route)
import HRHireRequestPendingListPage from "../pages/HR_Hire_Pending_Request/HRHirePendingRequest";
import HRDashBoard from "../pages/HRDashBoard/HRDashBoard";
//  (SOP-66) HR - Hire Request - Mapping with Employee Enrollment  (HRNewHireRequest Route)

// (SOP-67) HR - Employee termination - Process - start
import EmployeeTerminationPage from "../pages/EmployeeTermination/EmployeeTermination"
import EditEmployeeTerminationPage from "../pages/EmployeeTermination/EditEmployeeTermination"
// (SOP-67) HR - Employee termination - Process - end

//SOP-61 Included By PCS [DocPoint]
import CreateDraft from "../pages/DocPoint/CreateDraft";
import DocPoint from "../pages/DocPoint/DocPoint";
import EditDocument from "../pages/DocPoint/EditDocument";
import ViewDocument from "../pages/DocPoint/ViewDocument";
// EOF SOP-61 Included By PCS


//SOP-69 Included By PCS [Knowledge Base]
import KnowledgeBase from "../pages/KnowledgeBase/KnowledgeBase";
import ViewKnowledgeBase from "../pages/KnowledgeBase/ViewKnowledgeBase";
//EOF SOP-69 Included By PCS

// SOP-86 Included By PCS [to view Hire request By id]
import ViewHRHireRequest from "../pages/HR_New_Hire_Request/HR_View_Hire_Request";
// EOF SOP-86 Included By PCS  [to view Hire request By id]

//SOP-88 Included By PCS [View User page rights]
import ViewUserPageRights from "../pages/ViewUserPageRights/ViewUserPageRights";
import ViewByUser from "../pages/ViewUserPageRights/ViewByUser";
import ViewByPage from "../pages/ViewUserPageRights/ViewByPage";
import ViewUserRightsByEmployee from "../pages/ViewUserPageRights/ViewUserRightsByEmployee";
import ViewUserRightsByPage from "../pages/ViewUserPageRights/ViewUserRightsByPage";
//EOF SOP-88 Included By PCS [View User page rights]


// // const { admin } = roles;
// const ProtectedAdminRoute = (props: any) => {
//   // const { auth }= useAuth();
//   const auth = !!localStorage.getItem("avt-auth-user")
//     ? JSON.parse(localStorage.getItem("avt-auth-user") || "")
//     : null;
//    const authRole= auth.user_role.toLowerCase()
//   let location = useLocation();
//   return props?.roles?.includes(authRole) ? (
//     <Outlet />
//   ) : (
//     <Navigate to="/" state={{ from: location }} replace />
//   );
// };

//dynamic route
  // auth?.user ? (
  //   <Navigate
  //     to={`/${auth?.role}/dashboard`}
  //     state={{ from: location }}
  //     replace
  //   />
  // ) : 


const RoutePath = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route element={<Layout />}>
      <Route path="home" element={<HomePage />} />
      {/* Included by sanjana SOP-13 */}
      <Route path="change_password" element={<ChangePasswordPage />} /> 
       {/*End of SOP-13  */}
       {/* Included by sanjana SOP-14 */}
      <Route path="profile" element={<ProfilePage />} /> 
      <Route path="dashboard" element={<DashBoardPage />} /> 
       {/*End of SOP-14  */}
       {/* Included by sanjana SOP-15 */}
      <Route path="backoffice" element={<BackOfficePage />} /> 
      <Route path="categories" element={<Categories />} />
       {/*End of SOP-15  */}

       {/* Included by sanjana SOP-16 */}
      <Route path="sub_categories" element={<SubCategoriesPage />} />
       {/*End of SOP-16  */}

       {/* Included by sanjana SOP-17 */}
       <Route path="employee" element={<EmployeePage />} />
       <Route path="add_employee" element={<AddEmployeePage />} />
       <Route path="edit_employee/:id" element={<EditEmployeePage />} />
       {/* End of SOP-17 */}

       {/* Included by sanjana SOP-19 */}
       <Route path="assets" element={<AssetPage />} />
       <Route path="add_asset" element={<AddAssetPage />} />
       <Route path="edit_asset/:id" element={<EditAssetPage />} />
       {/* End of SOP-19 */}


      {/* SOP-20 Included By PCs  */}
       <Route path="department" element={<Department />} />
      {/*SOP-20 Included By PCS */}

       {/* SOP-21 Included By PCs  */}
       <Route path="designation" element={<Designation />} />
       {/*SOP-21 Included By PCS */}

       {/* Included by sanjana SOP-25 */}
       <Route path="request_an_asset" element={<RequetAnAssetPage />} />
       {/* End of SOP-25 */}

       {/* Included by sanjana SOP-26 */}
       <Route path="raise_a_ticket" element={<MyTicketsPage />} />
       {/* End of SOP-26 */}

      {/* Included by sanjana SOP-29 */}
      <Route path="myapprovals" element={<MyApprovalsPage />} />
       {/* End of SOP-29 */}

      {/* Included by sanjana SOP-30 */}
      <Route path="allocations" element={<AllocationPage />} />
       {/* End of SOP-30 */}


        {/* SOP-22 Included By PCs  */}
        <Route path="email_configuration_company" element={<EmailConfigurationCompany />} />
        {/*SOP-22 Included By PCS */}

        {/* SOP-23 Included By PCS  */}
        <Route path="email_configuration_intopz" element={<EmailConfigurationIntopz />} />
        {/*SOP-23 Included By PCS */}

        {/* Included by sanjana SOP-34 */}
        <Route path="ticketlist" element={<TicketListPage />} />
        {/* End of SOP-34 */}

        {/* Included by sanjana SOP-37 */}
        <Route path="ticketing_services" element={<TicketingServicesPage />} />
        {/* End of SOP-37 */}

        {/* Included by sanjana SOP-40 */}
        <Route path="import_asset_csv" element={<ImportAssetCSVPage />} />
        {/* End of SOP-40 */}

         {/* Included by sanjana SOP-46 */}
         <Route path="upload_company_logo" element={<UploadCompanyLogoPage />} />
        {/* End of SOP-46 */}

        {/* SOP-49 include by TS - start (RaiseATicket Page)*/}
        {/* Asset Management-Ticketing-Create Page */}
        <Route path="raise_a_ticket_page" element={<RaiseATicketPage />} />
        {/* SOP-49 - end*/}

        {/* // include by TS SOP-55 - start (View and Edit Page)
       // Asset Management-Ticketing-View and Edit Page */}
        <Route path="edit_raise_a_ticket_page/:id" element={<EditRaiseATicketPage />} /> {/* // EditRaiseATicketPage */}
        <Route path="View_raise_a_ticket_page/:id" element={<ViewRaiseATicketPage/>}/>    {/* // ViewRaiseATicketPage */}
        {/* SOP-55 - end*/}

        {/* Included by sanjana SOP-56  hierarchy path(s) */}
        <Route path="add_hierarchy_page_step1" element={<AddHierarchyChartPageStep1 />} />
        <Route path="add_hierarchy_page_step2" element={<AddHierarchyChartPageStep2 />} />
        <Route path="view_hierarchy" element={<ViewHierarchyChart />} />

        {/* End of SOP-56 */}

        {/* Included by sanjana SOP-58 */}
        <Route path="hr_hierarchy" element={<HRHierarchyPage />} />
        {/* End of SOP-58 */}

        {/* (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - start (HRNewHireRequest Route) */}
        <Route path="hr_new_hire_request" element={<AddHRNewHireRequest />} />
        {/* (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - end */}

        {/* (SOP-66)  HR - Hire Request - Mapping with Employee Enrollment - start (HRNewHireRequest Route) */}
        <Route path="HRHireRequestPendingListPage" element={<HRHireRequestPendingListPage />} />
            <Route path="HRDashBoard" element={<HRDashBoard />} />
        {/* (SOP-66)  HR - Hire Request - Mapping with Employee Enrollment- end */}

        {/* (SOP-67) HR - Employee termination - Process */}
        <Route path="EmployeeTerminationPage/:id" element={<EmployeeTerminationPage />} />  { /*create */}
        <Route path="EditEmployeeTerminationPage/:id" element={<EditEmployeeTerminationPage />} />   { /*create */}
        {/*  (SOP-67) HR - Employee termination - Process  */}

        {/* SOP-61 Included By PCS [DocPoint]*/}         
          <Route path="create_draft/:title" element={<CreateDraft />} />
          <Route path="docpoint" element={<DocPoint/>}/> 
          <Route path="edit_document" element={<EditDocument/>}/> 
          <Route path="view_document" element={<ViewDocument/>}/>        
        {/* EOF SOP-61 */}

        {/* SOP-69 Included By PCS [Knowledge Base]*/}
          <Route path="knowledge_base" element={<KnowledgeBase />} />
          <Route path="view_knowledgebase" element={<ViewKnowledgeBase />} />
        {/* EOF SOP-69 */}

        {/* SOP-86 Included By PCS [View Hire Request page]*/}
          <Route path="hr_view_hire_request/:id" element={<ViewHRHireRequest />} />
        {/* EOF SOP-86 Included By PCS [View Hire Request page]*/}

        {/* SOP-88 Included By PCS [View User page Rights]*/}
          <Route path="view_user_pagerights" element={<ViewUserPageRights />} />
          <Route path="view_by_user" element={<ViewByUser />} />
          <Route path="view_by_page" element={<ViewByPage />} />
          <Route path="view_pagerights_by_employee/:id" element={<ViewUserRightsByEmployee />} />
          <Route path="view_pagerights_by_page/:id" element={<ViewUserRightsByPage />} />
        {/* EOF SOP-88 Included By PCS [View User Page Rights]*/}
      
        {/* //SOP-89 Included by PCS ['requestanasset' from employee grid]*/}
        <Route path="request_an_asset/:id" element={<RequetAnAssetPage />} />
        {/* //EOF SOP-89 Included by PCS */}


      </Route>

        {/* Included by sanjana SOP-44 to set forget password path  */}
        <Route path="forgot_password/:id" element={<ForgotPasswordPage />} />
        {/* End of SOP-44 */}

    </Routes>
  );
};
export default RoutePath;

// Included by sanjana SOP-37 to create Edit ticketing Services page
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

const EditTicketingServicePage = // To get handleAddClose & getPendingTicketingServicesList from catgories page
({
    handleEditClose,
    getPendingTicketingServicesList,
    getAllocatedTicketingServicesList,
    TicketId
}: {
    handleEditClose: (event: React.MouseEvent) => void,
    getPendingTicketingServicesList: (event: React.MouseEvent) => void,
    getAllocatedTicketingServicesList: (event: React.MouseEvent) => void,
    TicketId:any
})=>{

    // use state
    const [ServiceRemarksError, setServiceRemarksError]=useState(false)
    const [ServiceRemarksValue, setServiceRemarksValue]=useState('')
    const [ServiceRemarksErrorMessage, setServiceRemarksErrorMessage]=useState('')
    const [CompletionsStsOptions, setCompletionsStsOptions] = useState<OptionType[]>([]);
    const [selectedCompletionsStsId, setSelectedCompletionsStsId] = useState<any | null>('');
    const [CompletionsStsError, setCompletionsStsError]=useState(false)
    const [CompletionsStsErrorMessage, setCompletionsStsErrorMessage]=useState('')
    const [AssetOptions, setAssetOptions] = useState<OptionType[]>([]);
    const [selectedAssetId, setSelectedAssetId] = useState<any | null>('');
    const [CompletionsSts, setCompletionsSts] = useState('')

    // SOP-70 Included By PCS [Vendor Ticket Id]  
        const [VendorTicketIdError, setVendorTicketIdError]=useState(false)
        const [VendorTicketIdValue, setVendorTicketIdValue]=useState('')
        const [VendorTicketIdErrorMessage, setVendorTicketIdErrorMessage]=useState('')
    // SOP-70 Included By PCS Vendor Ticket Id

     // SOP-80 Included By PCS [ServiceCompletionstatus]  
        const [ServiceCompletionstatus, setServiceCompletionstatus]=useState('')
    //EOF SOP-80 Included By PCS [ServiceCompletionstatus]  



    //SOP-71 Included By PCS
    const [TicketOPenDateTime, setTicketOPenDateTime] = useState('')
    //EOF SOP-71 
        
    //SOP-72 Included By PCS [closure Information]
    const [ClosureInformationOptions, setClosureInformationOptions] =useState<OptionType[]>([]);
    const [selectedClosureInformationId, setselectedClosureInformationId] = useState<string | null>('');
    const [ClosureInformationError, setClosureInformationError]=useState(false)
    const [ClosureInformationErrorMessage, setClosureInformationErrorMessage]=useState('')
   //EOF SOP-72 Included By PCS

    type OptionType = {
        name: string;
        value: string;
    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

    //  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    //console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId
    const UserId = GlobalData.GlobalUserId;

    //  Handle Allocations while typing Allocations and validations
    const handleServiceRemarksChange = (event:any) => {
        setServiceRemarksValue(event.target.value);
        const trimmedServiceRemarksValue =  event.target.value
        if( trimmedServiceRemarksValue.length !== 255 ){
            setServiceRemarksError(false);
            setServiceRemarksErrorMessage("");
        }
    };


    
    //SOP-70 Included by PCS
        //  Handle Vendor Ticket Id while typing   
        const handleVendorTicketIdChange = (event:any) => {
            setVendorTicketIdValue(event.target.value);
            const trimmedVendorTicketValue =  event.target.value
            if( trimmedVendorTicketValue.length > 255 ){
                setVendorTicketIdError(false);
                setVendorTicketIdErrorMessage("");
            }
        };
    //SOP-70 Included by PCS

    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleEditClose && typeof handleEditClose === 'function') {
            handleEditClose(event);
        }
    };

    // to render getPendingTicketingServicesList function after approving request
    const handlegetPendingTicketingServicesList= (event: React.MouseEvent) => {
        if (getPendingTicketingServicesList && typeof getPendingTicketingServicesList === 'function') {
            getPendingTicketingServicesList(event);
        }
    };

    // to render getAllocatedTicketingServicesList function after approving request
    const handlegetAllocatedTicketingServicesList= (event: React.MouseEvent) => {
        if (getAllocatedTicketingServicesList && typeof getAllocatedTicketingServicesList === 'function') {
            getAllocatedTicketingServicesList(event);
        }
    };

    // CompletionsSts Dropdown API
    const GetCompletionsStsList = async () => {
        const CompletionsStsListPayload ={
            CompanyId:CompanyId,
            active:"Y"
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_completion_status_list`,CompletionsStsListPayload);
            console.log("CompletionsSts List" ,res.data.data);
            let formattedCompletionsStsList = res.data.data.map((item:any) => {
            

             //SOP-80 Modified By PCS   
             //const { sys_completion_status_id, sys_completion_status_name } = item;
             const { short_name, sys_completion_status_name } = item;
             //EOF  SOP-80 Modified By PCS 
            return {
                name: sys_completion_status_name,
                //SOP-80 Modified By PCS  
                 //value: sys_completion_status_id,
                 value: short_name,
                //EOF  SOP-80 Modified By PCS 
            };
            });
            setCompletionsStsOptions(formattedCompletionsStsList)
        } catch (err) {
            //console.log( "co",err);
        }
        };


    // SOP-72 Included By PCS[ CompletionsSts Dropdown API ]
            const GetClosureInformationList = async () => {
                const payload ={
                }
            try {
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_closure_information_list`,payload);
                // console.log("Closure Info List" ,res.data.data);
                let formattedClosureInformationList = res.data.data.map((item:any) => {
                const { sys_ticket_closure_information_id, sys_ticket_closure_information_name } = item;
                return {
                    name: sys_ticket_closure_information_name,
                    value: sys_ticket_closure_information_id,
                };
                });
                setClosureInformationOptions(formattedClosureInformationList)
            } catch (err) {
                console.log( "co",err);
            }
            };
    //EOF SOP-72 Included by PCS

    // Asset Dropdown API
    const GetAssetList = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/getAssetList`,{
                CompanyId:CompanyId,
            IsActive:"Y"
            });
            console.log("Asset List" ,res.data.data);
            let formattedAssetList = res.data.data.map((item:any) => {
            const { assetid, assetname } = item;
            return {
                name: assetname,
                value: assetid,
            };
            });
            setAssetOptions(formattedAssetList)
        } catch (err) {
            //console.log( "co",err);
        }
        };

        // To get Allocationsd Approval details as per the id 
        const GetAllocatedTicketServiceListById =()=>{

            commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_allocated_ticket_service_list_by_id`, {
                    CompanyId: CompanyId,
                    TicketId: TicketId,
                })
                .then((res: any) => 
                { 
                //console.log("res ABC",res)
                
                const AllocatedTicketServiceData = res.data.data[0]
                // console.log("AllocatedTicketServiceData12",AllocatedTicketServiceData)

                // console.log("AllocatedTicketServiceData",AllocatedTicketServiceData)
                setServiceRemarksValue(AllocatedTicketServiceData.service_remarks)
                setCompletionsSts(AllocatedTicketServiceData.ticket_completionstatus)
                setVendorTicketIdValue(AllocatedTicketServiceData.vendor_ticket_id)//SOP-70 Included By PCS
                setTicketOPenDateTime(AllocatedTicketServiceData.ticketopendatetime)//SOP-71 Included By PCS

                  //SOP-80 Included By PCS
                  setServiceCompletionstatus(AllocatedTicketServiceData.service_completionstatus)
                  //EOF SOP-80 Included By PCS


        //SOP-80 Commented by PCS
                // let formattedEmployee = res.data.data.map((item:any) => {
                //     const { service_completionstatus, service_completionstatus_name } = item;
                //     let servicecompletionstatus;
                //     if(service_completionstatus === 'C' ){
                //         servicecompletionstatus = 2
                //     }
                //     else{
                //         servicecompletionstatus = 3
                //     }
                //     return {
                //         name: service_completionstatus_name,
                //         value: servicecompletionstatus,
                //     };
                //     });

                //     setSelectedCompletionsStsId(formattedEmployee[0].value) 
        //EOF SOP-80 Commented by PCS



            //SOP-72 Inlcuded By PCS [closure information]
                         
                        let formattedClosureInformation = res.data.data.map((item:any) => {
                            const { closure_information, closure_information_name } = item;
                        return {
                            name: closure_information_name,
                            value: closure_information,
                                };
                                });

                     setselectedClosureInformationId(formattedClosureInformation[0].value) 
            //EOF SOP-72 Inlcuded By PCS

                    let formattedAsset = res.data.data.map((item:any) => {
                        const { service_relatetowhichassetid, service_relatetowhichassetname } = item;
                        return {
                            name: service_relatetowhichassetname,
                            value: service_relatetowhichassetid,
                        };
                        });
                        setSelectedAssetId(formattedAsset[0].value)         

                }).catch((error) =>  
                { 
                Failed(error.response.message);
                });
        }

        // To Allocations approval

    // Define a type for errors
    type Errors = {
        AllocationsSts?:boolean;
        apprveRemarks?:boolean;
        VendorTicketId?:boolean;//SOP-70 Included By PCS
        ClosureInformation?:boolean;//SOP-72 Included By PCS
        };
    
        const EditTicketServiceAPI =(event:any)=>{
    
            event.preventDefault()
            let errors: Errors = {};
    
            if (selectedCompletionsStsId === '' || selectedCompletionsStsId === null || selectedCompletionsStsId === undefined) {
                errors.AllocationsSts = true;
                setCompletionsStsErrorMessage("Completions Status required")
                setCompletionsStsError(true)
            }

        //SOP-72 Included By PCS
            if (selectedClosureInformationId === '' || selectedClosureInformationId === null || selectedClosureInformationId === undefined) {
                errors.ClosureInformation = true;
                setClosureInformationErrorMessage("Closure Information is required")
                setClosureInformationError(true)
            }
        //EOF SOP-72 Included By PCS

            //SOP-70 Included By PCS- VendorTicketID
               const trimmedVendorTicketIdValue = VendorTicketIdValue.trim()
                if( trimmedVendorTicketIdValue.length >255 ){
                    errors.VendorTicketId = true
                    setVendorTicketIdError(true);
                    setVendorTicketIdErrorMessage("Max 255 characters");
                }
               else {
                   setVendorTicketIdError(false);
                   setVendorTicketIdErrorMessage("");
                    }
            //EOF SOP-70 Included By PCS- VendorTicketID
            
            const trimmedServiceRemarksValue = ServiceRemarksValue.trim()
            if( trimmedServiceRemarksValue.length >255 ){
                errors.apprveRemarks = true
                setServiceRemarksError(true);
                setServiceRemarksErrorMessage("Max 255 characters");
            }
            else {
                setServiceRemarksError(false);
                setServiceRemarksErrorMessage("");
            }
    
    
            if (Object.keys(errors).length > 0) {
                // If there are errors, update the state with all the errors
                setCompletionsStsError(errors.AllocationsSts || false);
                setServiceRemarksError(errors.apprveRemarks || false);
                setVendorTicketIdError(errors.VendorTicketId || false); //SOP-70 Included By PCS
                setClosureInformationError(errors.ClosureInformation || false); //SOP-72 Included By PCS


                return;
            }
    
            let TicketCompletionSts;

     


           //SOP-80 Modified By PCS
            // if(selectedCompletionsStsId === 2){
            if(selectedCompletionsStsId == 'C'){
            //EOF SOP-80 Modified By PCS   
                TicketCompletionSts ='Y'
            }
            else{
                TicketCompletionSts = 'N'
            }
    
           //SOP-80 Commented By PCS   
            // let CompletionsSts;
    
            // if(selectedCompletionsStsId === 2){
            //     CompletionsSts ='C'
            // }
            // else{
            //     CompletionsSts = 'H'
            // }
           //EOF SOP-80 Commented By PCS
            
    
    
            const AddTicketServiceAPIPayload={
                SaveUpdateKey: "update",
                CompanyId: CompanyId,
                TicketId: TicketId,
                ServiceRemarks:ServiceRemarksValue,
                //SOP-80 Modified By PCS
                // ServiceCompletionSts:CompletionsSts,
                ServiceCompletionSts:selectedCompletionsStsId,
                //EOF SOP-80 Modified By PCS
                ServiceReportedToWhichId:selectedAssetId || null,
                TicketCompletionSts:TicketCompletionSts,
                //SOP-70 Included By PCS
                VendorTicketId:VendorTicketIdValue,
                //EOF SOP-70 Included By PCS
                //SOP-71 Included By PCS [open date]
                TicketOpenDateTime:TicketOPenDateTime,
                //SOP-72 Included By PCS [closure information]
                ClosureInformation:selectedClosureInformationId,
    
            }
    
            // console.log("EditTicketServiceAPIPayload",AddTicketServiceAPIPayload)
    
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/ticket_service_update`, 
            AddTicketServiceAPIPayload
            )
            .then((res) => {
              if (res.status === 200) {
                  Success("Ticket Services Updated Successfully");
                  handleClose(event);
                  handlegetPendingTicketingServicesList(event);
                  handlegetAllocatedTicketingServicesList(event);
              }
              }).catch((error) => {
                  console.error("Error:", error);
              });
        }



    useEffect(() => {
        GetCompletionsStsList();
        GetAssetList();
        GetAllocatedTicketServiceListById();
        GetClosureInformationList(); //SOP-72 Included By PCS
    }, []); 

    return(
        <Box>
            <Box>
            <Box bgcolor={'white'} borderRadius={3}
            sx={{
                width:{
                    lg:600,
                    md:600,
                    xs:350
            }}} >
                <Box>
                <Box p={3}>
                        <Typography variant='h1'
                        sx={{
                            fontSize:{
                                lg:'2rem',
                                md:'2rem',
                                xs:'1.5rem'
                            }
                        }}
                        >
                        Service Details
                        </Typography>
                    </Box>
                    
                    <form noValidate>
                        <Box p={3}>
                            <Grid container >
                                <Grid item lg={12} md={12} xs={12} >
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={CompletionsStsOptions as OptionType[]}
                                        filterOptions={filterOptions}
                                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                                         //SOP-80 Modified By PCS
                                        // value={CompletionsStsOptions.find((option: OptionType) => option.value === selectedCompletionsStsId) || null}
                                           value={CompletionsStsOptions.find((option: OptionType) => option.value === ServiceCompletionstatus) || null}
                                         //EOF SOP-80 Modified By PCS
                                        
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setSelectedCompletionsStsId(newValue.value);
                                                setCompletionsStsError(false);
                                                setCompletionsStsErrorMessage("");
                                            } else {
                                                setSelectedCompletionsStsId(null);
                                                setCompletionsStsError(true);
                                                setCompletionsStsErrorMessage("Completions Status is required");
                                            }
                                        }}
                                        // sx={{ width: 235, ml: 1 }}
                                        renderInput={(params) => 
                                        <TextField {...params}
                                        error={CompletionsStsError}
                                        autoFocus
                                        helperText={CompletionsStsErrorMessage}
                                        required label="Completion Status" 
                                        variant="standard" />}
                                    />
                                </Grid>


                            {/* //SOP-70 Included By PCS [to inlcude Vendor Ticket ID] */}
                            <Grid item lg={12} md={12} xs={12} >
                                            <Box>
                                                <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Vendor Ticket ID'
                                                    name='Vendor Ticket ID'
                                                    fullWidth
                                                    error={VendorTicketIdError}
                                                    onChange={handleVendorTicketIdChange}
                                                    value={VendorTicketIdValue}
                                                    helperText={VendorTicketIdErrorMessage}  
                                                /> 
                                            </Box>
                                        </Grid>
                             {/* //EOF SOP-70 Included By PCS [to inlcude Vendor Ticket ID] */}

                                <Grid item mt={3} lg={12} md={12} xs={12} >
                                            <TextField 
                                            id="outlined-basic" 
                                            label="Service Remarks" 
                                            variant="outlined" 
                                            name='ServiceRemarks'
                                            fullWidth
                                            multiline
                                            rows={4}
                                            error={ServiceRemarksError}
                                            onChange={handleServiceRemarksChange}
                                            value={ServiceRemarksValue}
                                            helperText={ServiceRemarksErrorMessage}                                           
                                            />
                                        </Grid>

                                        <Grid item lg={12} md={12} xs={12} >
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={AssetOptions as OptionType[]}
                                        filterOptions={filterOptions}
                                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                                        value={AssetOptions.find((option: OptionType) => option.value === selectedAssetId) || null}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setSelectedAssetId(newValue.value);
                                            } else {
                                                setSelectedAssetId(null);
                                            }
                                        }}
                                        renderInput={(params) => 
                                        <TextField {...params}
                                        label="Asset" 
                                        variant="standard" />}
                                    />
                                </Grid>

                            {/* //SOP-72 Included By PCS [to include closure information]*/}
                            <Grid item mt={3} lg={12} md={12} xs={12} >
                                <Autocomplete
                                        id="combo-box-demo"
                                        options={ClosureInformationOptions as OptionType[]}
                                        filterOptions={filterOptions}
                                        
                                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                                        value={ClosureInformationOptions.find((option: OptionType) => option.value === selectedClosureInformationId) || null}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setselectedClosureInformationId(newValue.value);
                                                setClosureInformationError(false);
                                                setClosureInformationErrorMessage("");
                                            } else {
                                                setselectedClosureInformationId('');
                                                setClosureInformationError(true);
                                                setClosureInformationErrorMessage("Closure information is required");
                                            }
                                        }}
                                        
                                        renderInput={(params) => 
                                        <TextField {...params}
                                        error={ClosureInformationError}
                                        
                                        helperText={ClosureInformationErrorMessage}
                                        required label="Closure Information " 
                                        variant="standard" />}
                                    />
                                        </Grid>
                           {/* //SOP-72 Included By PCS */}



                            </Grid>
                        </Box>

                    <Box mt={5} ml={2} mr={2} mb={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Button
                                    sx={{
                                        background: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        // color: {accent},
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}

                                    onClick={handleClose} 
                                >
                                    Back
                                </Button>

                                <Button
                                    sx={{
                                        background: '#25425F',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        "&:hover":{
                                            backgroundColor:'#25425F',
                                            color:'white',
                                        },
                                        color: 'white',
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                    onClick={(event)=>{
                                        EditTicketServiceAPI(event);
                                    }}
                                >
                                    {/* Edit */}
                                    {/* //SOP-70 Inlcuded By PCS */}
                                    Update
                                </Button>
                                </Box>
                    </form>


                </Box>
            </Box>
            </Box>
        
        </Box>
    )

}

export default EditTicketingServicePage;

// End of SOP-37
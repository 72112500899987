// Included by sanjana SOP-17
// Imports
import { useState,useRef,useEffect,useCallback } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Container,
    Stack,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    FormHelperText,
    useTheme
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import CryptoJS from 'crypto-js';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FcInfo } from "react-icons/fc";
import { FaUserGear } from "react-icons/fa6";
import { BsBuildingsFill } from "react-icons/bs";
import { IoMdPhotos } from "react-icons/io";
import Swal from "sweetalert2";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { PiBriefcaseBold } from "react-icons/pi"; 
//SOP-39 - TS -  Asset Management - User Rights- list MenuList -start
// --- Drag and Drop imports--- 
import { MdContactPage } from "react-icons/md";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
//SOP-39 - TS -  Asset Management - User Rights- list MenuList - end  

//SOP-82 Included By PCS [to resize image]
import Resizer from 'react-image-file-resizer';
//EOF SOP-82 Included By PCS [to resize image]

//SOP-91 Included By PCS [to include requestanasset in add employee]
import { TiShoppingCart } from "react-icons/ti";

const AddEmployeePage =()=>{

    // Use navigate
    const navigate = useNavigate();
    const theme = useTheme();

    // usestate declaration
    const [EmployeeNameError, setEmployeeNameError]=useState(false)
    const [EmployeeNameValue, setEmployeeNameValue]=useState('')
    const [EmployeeNameErrorMessage, setEmployeeNameErrorMessage]=useState('')
    
    const [EmployeeEmailError, setEmployeeEmailError] = useState(false)
    const [EmployeeEmailValue, setEmployeeEmailValue]=useState('')
    const [EmployeeEmailErrorMessage, setEmployeeEmailErrorMessage]=useState('')
   
    const [EmployeeMobileError, setEmployeeMobileError] = useState(false)
    const [EmployeeMobileValue, setEmployeeMobileValue]=useState('')
    const [EmployeeMobileErrorMessage, setEmployeeMobileErrorMessage]=useState('')
   
    const [EmployeePasswordError, setEmployeePasswordError] = useState(false)
    const [EmployeePasswordValue, setEmployeePasswordValue]=useState('')
    const [EmployeePasswordErrorMessage, setEmployeePasswordErrorMessage]=useState('')
    const [EmployeeRoleOptions, setEmployeeRoleOptions] = useState<OptionType[]>([]);
    const [selectedEmployeeRoleId, setSelectedEmployeeRoleId] = useState<string | null>('');
    const [EmployeeRoleError, setEmployeeRoleError]=useState(false)
    const [EmployeeRoleErrorMessage, setEmployeeRoleErrorMessage]=useState('')
    
    const [EmployeeReportToWhomListOptions, setEmployeeReportToWhomListOptions] = useState<OptionType[]>([]);
    const [selectedEmployeeReportToWhomId, setSelectedEmployeeReportToWhomId] = useState<string | null>('');
    const [EmployeeReportToWhomError, setEmployeeReportToWhomError]=useState(false)
    const [EmployeeReportToWhomErrorMessage, setEmployeeReportToWhomErrorMessage]=useState('')
    
    const [EmployeeDateOfJoiningError, setEmployeeDateOfJoiningError]=useState(false)
    const [EmployeeDateOfJoiningValue, setEmployeeDateOfJoiningValue] = useState<dayjs.Dayjs | null>(null);
    const [EmployeeDateOfJoiningErrorMessage, setEmployeeDateOfJoiningErrorMessage]=useState('')

   // SOP-31 Modified By PCS [Designation changed from Text field to drop down] 
    // --Designation --
    // const [EmployeeDesignationError, setEmployeeDesignationError]=useState(false)
    // const [EmployeeDesignationValue, setEmployeeDesignationValue]=useState('')
    // const [EmployeeDesignationErrorMessage, setEmployeeDesignationErrorMessage]=useState('')
     const [EmployeeDesignationOptions, setEmployeeDesignationOptions] = useState<OptionType[]>([]);
     const [selectedEmployeeDesignationId, setSelectedEmployeeDesignationId] = useState<string | null>('');
     const [EmployeeDesignationError, setEmployeeDesignationError]=useState(false)
     const [EmployeeDesignationErrorMessage, setEmployeeDesignationErrorMessage]=useState('')
    // EOF SOP-31 Modified By PCS [Designation] 

   // SOP-31 Included By PCS [Department Drop down included] 
    const [EmployeeDepartmentOptions, setEmployeeDepartmentOptions] = useState<OptionType[]>([]);
    const [selectedEmployeeDepartmentId, setSelectedEmployeeDepartmentId] = useState<string | null>('');
    const [EmployeeDepartmentError, setEmployeeDepartmentError]=useState(false)
    const [EmployeeDepartmentErrorMessage, setEmployeeDepartmentErrorMessage]=useState('')
  //EOF SOP-31 
 
    const [EmployeeWorkLocationError, setEmployeeWorkLocationError]=useState(false)
    const [EmployeeWorkLocationValue, setEmployeeWorkLocationValue]=useState('')
    const [EmployeeWorkLocationErrorMessage, setEmployeeWorkLocationErrorMessage]=useState('')
  
    const [EmployeeNoError, setEmployeeNoError] = useState(false)
    const [EmployeeNoValue, setEmployeeNoValue]=useState('')
    const [EmployeeNoErrorMessage, setEmployeeNoErrorMessage]=useState('')
    const [imageSrclink, setImageSrclink] = useState<string>('');
    const [imageUrl, setImageUrl] = useState<any>(null);
    const [key, setKey] = useState(0);
    const [EnableClearBtn, setEnableClearBtn] = useState(false);

    // SOP-39 TS  Asset Management - User Rights-  start
    const [menus, setMenus] = useState<MenuType[]>([]);
    const [selectedMenus, setSelectedMenus] = useState<MenuType[]>([]);
    const selectedMenuIds = selectedMenus.map(menu => menu.id);
    const [searchQuery, setSearchQuery] = useState('');
   // SOP-39 - end

    //(SOP-66) HR - Hire Request - Mapping with Employee Enrollment - TS (useState) - start
    // EmploymentTypeList usestate   
    const [EmploymentTypeListOptions, setEmploymentTypeListOptions] = useState<OptionType[]>([]);
    const [selectedEmploymentId, setselectedEmploymentId] = useState<string | null>('');

    // JobCodeAndTitle usestate
    const [JobCodeAndTitleError, setJobCodeAndTitleError] = useState(false)
    const [JobCodeAndTitleValue, setJobCodeAndTitleValue] = useState('')
    const [JobCodeAndTitleErrorMessage, setJobCodeAndTitleErrorMessage] = useState('')

     // DivisionAndFunction usestate
     const [DivisionAndFunctionNameError, setDivisionAndFunctionNameError] = useState(false)
     const [DivisionAndFunctionNameValue, setDivisionAndFunctionNameValue]=useState('')
     const [DivisionAndFunctionNameErrorMessage, setDivisionAndFunctionNameErrorMessage] = useState('')
    
    // OtherRemarks usestate
    const [OtherRemarksError, setOtherRemarksError] = useState(false)
    const [OtherRemarksValue, setOtherRemarksValue]=useState('')
    const [OtherRemarksErrorMessage, setOtherRemarksErrorMessage] = useState('')

    // Business usestate
    const [BusinessUnitError, setBusinessUnitError] = useState(false)
    const [BusinessUnitValue, setBusinessUnitValue]=useState('')
    const [BusinessUnitErrorMessage, setBusinessUnitErrorMessage] = useState('')

    // OfficeBuilding usestate
    const [OfficeBuildingError, setOfficeBuildingError] = useState(false)
    const [OfficeBuildingValue, setOfficeBuildingValue]=useState('')
    const [OfficeBuildingErrorMessage, setOfficeBuildingErrorMessage] = useState('')

    // OfficeAddress usestate
    const [OfficeAddressError, setOfficeAddressError] = useState(false)
    const [OfficeAddressValue, setOfficeAddressValue]=useState('')
    const [OfficeAddressErrorMessage, setOfficeAddressErrorMessage] = useState('')

  // [EmployeeSiteLocationList usestate
    const [EmployeeSiteLocationListOptions, setEmployeeSiteLocationListOptions] = useState<OptionType[]>([]);
    const [selectedEmployeeSiteLocation , setselectedEmployeeSiteLocation] = useState<string | null>('');

  // HireRequestId usestate
    const [HireRequestIdErrorIdError, setHireRequestIdErrorIdError] = useState(false)
    const [HireRequestIdErrorIdValue, setHireRequestIdErrorIdValue]=useState('')
    const [HireRequestIdErrorIdErrorMessage, setHireRequestIdErrorIdErrorMessage] = useState('')
    
    // Local storage  useState data
    const [formData, setFormData] = useState({
       
        hirerequest_hiring_jobcode_title: '',
        hirerequest_total_open_position: '',
        hirerequest_total_filled_position: '',
        hirerequest_total_pendig_position: '',
        hirerequestid: '',
        hirerequest_employment_type_id: '',
        hirerequest_hiring_division_function:'',
        hirerequest_hiring_others:'',//SOP-66 Testing feedback corrections Included By PCS [to bind "Other Remarks" value]
    });

    const [formDatajobcode_title, setFormDatajobcode_title] = useState({ hirerequest_hiring_jobcode_title: '', });
    const [formDatahirerequestid, setFormDatahirerequestid] = useState({hirerequestid: '',});
    const [formDataemployment_type_id, setFormDataemployment_type_id] = useState({ hirerequest_employment_type_id: '',});
    const [formDatahiring_division_function, setFormDatahiring_division_function] = useState({ hirerequest_hiring_division_function:'',});
    const [formDatahiring_departmentname, setFormDatahiring_departmentname] = useState({ departmentname:'',});
    const [formDatahiring_start_date, setFormDatahiring_start_date] = useState({ hirerequest_start_date:'',});
 
    //SOP-66 Testing feedback corrections Included by PCS [to bind "Other Remarks" value]
    const [formDatahiring_otherRemarks, setFormDatahiring_otherRemarks] = useState({ hirerequestOtherRemarks:'',});
    //EOF SOP-66 Testing feedback corrections Included by PCS [to bind "Other Remarks" value]

    // departmentname: rowData.departmentname,
    //         hirerequest_start_date: rowData.hirerequest_start_date,
    // console.log("formData", formData)
    //  //(SOP-66) HR - Hire Request - Mapping with Employee Enrollment - TS (useState) - start

    //SOP-80 Included By PCS
    const [CompanyEmailValue, setCompanyEmailValue]=useState('') 

    //SOP-91 Included By PCS [Request an asset from Employee grid]
        //Sub category for Assets
        const [CategoryandSubCategoryListOptions, setCategoryandSubCategoryListOptions] = useState<OptionType[]>([]);
        const [SelectedSubCategoryId, setSelectedSubCategoryId] = useState<string | null>('');
        const [SubCategoryError, setSubCategoryError]=useState(false)
        const [SubCategoryErrorMessage, setSubCategoryErrorMessage]=useState('')
        //Comments box for Assets
        const [ AssetRequestCommentValue, setAssetRequestCommentValue] = useState('');
        const [AssetRequestCommentError, setAssetRequestCommentError] = useState(false)
        const [AssetRequestCommentErrorMessage, setAssetRequestCommentErrorMessage] = useState('')
        //To Get Category Id on sleecting Sub category Id
        const [SelectedCategoryId, setSelectedCategoryId] = useState('')
    //EOF SOP-91 Included By PCS [Request an asset from Employee grid]


    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    // console.log("GlobalData",GlobalData)
    const UserId = GlobalData.GlobalUserId;
    const CompanyId = GlobalData.GlobalCompanyId;
    const CompanyName = GlobalData.GlobalCompanyNAme; //SOP-80 Included By PCS [to get Company name from Global data]

    type OptionType = {
        name: string;
        value: string;
    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });


    // (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - TS - start
     // handle JobCodeAndTitle and validations
        const handleJobCodeAndTitleChange = (event: any) => {
        
            const trimmedJobCodeAndTitleValue = event.target.value.trim();
            setJobCodeAndTitleValue(event.target.value);
            // setFormData(event.target.value);
            setFormDatajobcode_title(event.target.value);
    
        if( trimmedJobCodeAndTitleValue.length >225 ){
                setJobCodeAndTitleError(true);
                setJobCodeAndTitleErrorMessage("Max 255 characters");
            }
            else {
                setJobCodeAndTitleError(false);
                setJobCodeAndTitleErrorMessage("");
            }
        }

      //handle DivisionAndFunction and validations
       const handleDivisionAndFunctionChange =( event:any)=>{
    const trimmedDivisionAndFunctionValue = event.target.value.trim();
       setDivisionAndFunctionNameValue(event.target.value);
       //    setFormData(event.target.value);
       setFormDatahiring_division_function(event.target.value);
    if( trimmedDivisionAndFunctionValue.length >225 ){
        setDivisionAndFunctionNameError(true);
        setDivisionAndFunctionNameErrorMessage("Max 255 characters");
    }
    else {
        setDivisionAndFunctionNameError(false);
        setDivisionAndFunctionNameErrorMessage("");
    }
       }
    
      // handle OtherRemarks and validations
       const handleOtherRemarksChange =( event:any)=>{
        const trimmedOtherRemarksValue = event.target.value.trim();

        setOtherRemarksValue(event.target.value);
        setFormDatahiring_otherRemarks(event.target.value) //SOP-66 Testing feedback corrections Included By PCS [to bind "Other Remarks" value]

        if( trimmedOtherRemarksValue.length >255 ){
            setOtherRemarksError(true);
           setOtherRemarksErrorMessage("Max 255 characters");
        }
        else {
           setOtherRemarksError(false);
           setOtherRemarksErrorMessage("");
        }

       }

       //handle BusinessChange and validations
        const handleBusinessChange =( event:any)=>{
            const trimmedBusinessValue = event.target.value.trim();
            setBusinessUnitValue(event.target.value);
            if( trimmedBusinessValue.length >225 ){
                setBusinessUnitError(true);
                setBusinessUnitErrorMessage("Max 255 characters");
            }
            else {
                setBusinessUnitError(false);
                setBusinessUnitErrorMessage("");
            }
        }

       //handle OfficeBuilding Change and validations
        const handleOfficeBuildingChange =( event:any)=>{
            const trimmedOfficeBuildingValue = event.target.value.trim();
            setOfficeBuildingValue(event.target.value);
            if( trimmedOfficeBuildingValue.length >225 ){
                setOfficeBuildingError(true);
                setOfficeBuildingErrorMessage("Max 255 characters");
            }
            else {
                setOfficeBuildingError(false);
                setOfficeBuildingErrorMessage("");
            }
        }

       //handle OfficeAddress Change and validations
        const handleOfficeAddressChange =( event:any)=>{
            const trimmedOfficeAddressValue = event.target.value.trim();
            setOfficeAddressValue(event.target.value);
            if( trimmedOfficeAddressValue.length >225 ){
                setOfficeAddressError(true);
                setOfficeAddressErrorMessage("Max 255 characters");
            }
            else {
                setOfficeAddressError(false);
                setOfficeAddressErrorMessage("");
            }
        }
       // (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - TS - end

        const handleEmployeeNameChange =(event:any)=>{
            const trimmedEmployeeNameValue = event.target.value.trim();
            setEmployeeNameValue(event.target.value);
            if (trimmedEmployeeNameValue === '') {
                setEmployeeNameError(true);
                setEmployeeNameErrorMessage("Employee name is required");
            } 
            else if( trimmedEmployeeNameValue.length >225 ){
                setEmployeeNameError(true);
                setEmployeeNameErrorMessage("Max 255 characters");
            }
            else {
                setEmployeeNameError(false);
                setEmployeeNameErrorMessage("");
            }

        }

        const handleEmployeeEmailChange =(event:any)=>{
            const trimmedEmployeeEmailValue = event.target.value.trim();
            setEmployeeEmailValue(event.target.value);
            if (trimmedEmployeeEmailValue === '') {
                setEmployeeEmailError(true);
                setEmployeeEmailErrorMessage("Email is required");
            } 
            else if( trimmedEmployeeEmailValue.length >255 ){
                setEmployeeEmailError(true);
                setEmployeeEmailErrorMessage("Max 255 characters");
            }
            else if( !(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(trimmedEmployeeEmailValue) ){
                setEmployeeEmailError(true);
                setEmployeeEmailErrorMessage("Invalid Email");
            }
            else {
                setEmployeeEmailError(false);
                setEmployeeEmailErrorMessage("");
            }

        }

        const handleEmployeeMobileChange =(event:any)=>{
            const trimmedEmployeeMobileValue = event.target.value.trim();
            setEmployeeMobileValue(event.target.value);
            if (trimmedEmployeeMobileValue === '') {
                setEmployeeMobileError(true);
                setEmployeeMobileErrorMessage("Mobile number is required");
            } 
            else if( trimmedEmployeeMobileValue.length >15 ){
                setEmployeeMobileError(true);
                setEmployeeMobileErrorMessage("Invalid mobile number");
            }
            //SOP-17 Modified By PCS[Testing feedback corrections-to include '+' symbol]
            //else if( !/^[0-9]+$/.test(trimmedEmployeeMobileValue) ){
            else if( !/^[0-9 +]+$/.test(trimmedEmployeeMobileValue) ){
            //EOF SOP-17 Modified By PCS[Testing feedback corrections]

                setEmployeeMobileError(true);
                setEmployeeMobileErrorMessage("Numbers only");
            }
            else {
                setEmployeeMobileError(false);
                setEmployeeMobileErrorMessage("");
            }
        }

        const handleEmployeePasswordChange =(event:any)=>{
            const trimmedEmployeePasswordValue = event.target.value.trim();
            setEmployeePasswordValue(event.target.value);

            //SOP-17 Included By PCS[to set strong password]
            const hasUpperCase = /[A-Z]/.test(trimmedEmployeePasswordValue);
            const hasDigit = /\d/.test(trimmedEmployeePasswordValue);
            const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(trimmedEmployeePasswordValue);
            //EOF SOP 17 Inlcuded By PCS [to set strong password]

            if (trimmedEmployeePasswordValue === '') {
                setEmployeePasswordError(true);
                setEmployeePasswordErrorMessage("Password is required");
            } 

            //SOP-17 Included By PCS[Testing feedback corrections-to avoid space]
            else if (event.target.value.includes(' ')) {
                setEmployeePasswordError(true);
                setEmployeePasswordErrorMessage("Spaces are not allowed");
            }
            //EOF SOP-17 Included By PCS[Testing feedback corrections-to avoid space]

            else if( trimmedEmployeePasswordValue.length >12 ){
                setEmployeePasswordError(true);
                setEmployeePasswordErrorMessage("Max 12 characters");
            }

            // SOP-17 Included By PCS[to set strong password]   
            else if (!hasUpperCase || !hasDigit || !hasSpecialChar ) {
                setEmployeePasswordError(true);
                setEmployeePasswordErrorMessage("Password must contain at least one uppercase letter, one digit, and one special character");
            }
        //EOF SOP-17 Included By PCS[to set strong password]   
            

            else {
                setEmployeePasswordError(false);
                setEmployeePasswordErrorMessage("");
            }

        }

        const handleDateOfJoiningChange = (date:any) => {
            setEmployeeDateOfJoiningValue(date)
            
            if (!date) {
                setEmployeeDateOfJoiningError(true);
                //SOP-17 Modified By PCS[Testing feedback Corrections]
                //setEmployeeDateOfJoiningErrorMessage("Date Of Joining is required");
                setEmployeeDateOfJoiningErrorMessage("Date of Joining is required");

            }
            else {
                setEmployeeDateOfJoiningError(false);
                setEmployeeDateOfJoiningErrorMessage("");
            }
        };

    //SOP-31 Commented By PCS [Designation changed from text field to drop down]

            // const handleEmployeeDesignationChange =(event:any)=>{
            //     const trimmedEmployeeDesignationValue = event.target.value.trim();
            //     //setEmployeeDesignationValue(event.target.value);
            //      if( trimmedEmployeeDesignationValue.length >255 ){
            //         setEmployeeDesignationError(true);
            //         setEmployeeDesignationErrorMessage("Max 255 characters");
            //     }
            //     else {
            //         setEmployeeDesignationError(false);
            //         setEmployeeDesignationErrorMessage("");
            //     }

            // }
    //SOP-31 Commented By PCS


        const handleEmployeeWorkLocationChange =(event:any)=>{
            const trimmedEmployeeWorkLocationValue = event.target.value.trim();
            //console.log("trimmedEmployeeWorkLocationValue",trimmedEmployeeWorkLocationValue)
            setEmployeeWorkLocationValue(event.target.value);
            if(trimmedEmployeeWorkLocationValue === '') {
                setEmployeeWorkLocationError(true);
                setEmployeeWorkLocationErrorMessage("Work Location is required");
            }
            else if( trimmedEmployeeWorkLocationValue.length >255 ){
                setEmployeeWorkLocationError(true);
                setEmployeeWorkLocationErrorMessage("Max 255 characters");
            }
            else {
                setEmployeeWorkLocationError(false);
                setEmployeeWorkLocationErrorMessage("");
            }

        }

        const handleEmployeeNoChange =(event:any)=>{
            const trimmedEmployeeNoValue = event.target.value.trim();
            setEmployeeNoValue(event.target.value);
            if( trimmedEmployeeNoValue.length >255 ){
                setEmployeeNoError(true);
                setEmployeeNoErrorMessage("Max 255 characters");
            }
            else {
                setEmployeeNoError(false);
                setEmployeeNoErrorMessage("");
            }

        }

     // const handleEmployeeSiteLocationChange =(event:any)=>{
    //     const trimmedEmployeeSiteLocationValue = event.target.value.trim();
    //     setEmployeeSiteLocationValue(event.target.value);
    //      if( trimmedEmployeeSiteLocationValue.length >255 ){
    //         setEmployeeSiteLocationError(true);
    //         setEmployeeSiteLocationErrorMessage("Max 255 characters");
    //     }
    //     else {
    //         setEmployeeSiteLocationError(false);
    //         setEmployeeSiteLocationErrorMessage("");
    //     }
    // }

    // (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - TS - start
    // handleChange for HireRequestId
        const handleHireRequestIdChange = (event: any) => {
            // setFormData(event.target.value);
            setFormDatahirerequestid(event.target.value);
            const trimmedHireRequestIdValue = event.target.value.trim();
        setHireRequestIdErrorIdValue(event.target.value);
            
            if( trimmedHireRequestIdValue.length >255 ){
            setHireRequestIdErrorIdError(true);
            setHireRequestIdErrorIdErrorMessage("Max 255 characters");
            }
                else if( !/^[0-9 +]+$/.test(trimmedHireRequestIdValue) ){

                    setHireRequestIdErrorIdError(true);
                    setHireRequestIdErrorIdErrorMessage("Numbers only");
            }
            else {
            setHireRequestIdErrorIdError(false);
                setHireRequestIdErrorIdErrorMessage("");
            }
        }
    // (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - TS - end


       //SOP-91 Included By PCS 
         // handle Comments from Request an asset and validations
            const handleAssetRequestCommentChange =( event:any)=>{
                const trimmedCommentValue = event.target.value.trim();
                   setAssetRequestCommentValue(event.target.value);
                
                if( trimmedCommentValue.length >255 ){
                    setAssetRequestCommentError(true);
                    setAssetRequestCommentErrorMessage("Max 255 characters");
                }
                else {
                    setAssetRequestCommentError(false);
                    setAssetRequestCommentErrorMessage("");
                     }
            }
        //EOF SOP-91 Included By PCS


        const clearImage = useCallback(() => {
            Swal.fire({
            title: 'Delete Image',
            text: 'Are you sure you want to clear the image?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#25425F',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            }).then((result) => {
            if (result.isConfirmed) {
                //clear the image
                setImageUrl(null);
                setImageSrclink('');
                setEnableClearBtn(false);
                //console.log('Image cleared');
            }
            });
        }, [setImageUrl, setImageSrclink]);


    //SOP-82 Commented By PCS [resizing image]
// const handleFileUpload = (event: any) => 
//     {
//         const file = event.target?.files[0];
       
//         if (file)
//         {
//                 var fileSize = file.size;
//                 var maxSize = 150 * 1024;
//                 if (fileSize > maxSize)
//                 {
                   
//                     event.preventDefault(); // Prevent form submission
//                     Swal.fire({
//                         icon: 'warning',
//                         text: 'Image size must be less than 150KB.',
//                     })
        
//                 }
//                 else 
//                 {
//                     const reader = new FileReader();
//                     reader.onloadend = () => 
//                     {
//                     // 'reader.result' contains the Base64 data URL of the image
//                     const imageDataUrl = reader.result;
//                     setImageSrclink(String(imageDataUrl))
//                     setImageUrl(imageDataUrl); // Set the state with the Base64 data URL if needed
//                     setEnableClearBtn(true)
//                     };
//                     reader.readAsDataURL(file);
//                 }
//         };
//             // Reset the input element by changing the key
//             // by incrementing the `key` variable when clearing the image, you force React to re - render the input element, 
//             // allowing you to select the same image again
//             setKey(key + 1);
//     }
//EOF SOP-82 Commented by PCS [resizing image]

//SOP-82 Included By PCS  method  starts
        const handleFileUpload = async (event: any) => 
            {
            const file = event.target?.files[0];
            //If there is a file...
            if (file)
                {
                        const fileSize = file.size;
                    // const maxLimitSize = 1536*1024 // 1.5mb
                        const maxLimitSize = 1572864 ; // 1.5mb
                    
        
                    //  console.log('fileSize',fileSize)
                    //  console.log('maxLimitSize',maxLimitSize)
        
                    //If max file reaches 1.5mb...
                    if(fileSize > maxLimitSize)
                    {
                        //  console.log('too large to rezize')
        
                            event.preventDefault(); // Prevent form submission
                            Swal.fire({
                                icon: 'warning',
                                text: 'Image size must be less than 1.5Mb.',
                            })
        
                    }
                    //If max file is less than 1.5mb...
                    else
                    {
                        //  console.log('inside rezize') 
                            const maxSizeforResizing = 150 * 1024; // 150 KB
                            //  console.log("maxSize", maxSizeforResizing);
                            //  console.log("fileSize", fileSize);
                            //If file size exceeds 150kb limit..[resizing happens]
        
                                if (fileSize > maxSizeforResizing)
                                {
                                //    console.log("large image shud be resized");
                                        try {
                                                const resizedImage = await resizeImage(file);
                                                setImageSrclink(resizedImage);
                                                setImageUrl(resizedImage); // Set the state with the Base64 data URL if needed
                                                setEnableClearBtn(true);
                                            } catch (error) {
                                                console.error("Error resizing image:", error);
                                            }
                                }
                            //If file size does not exceeds 150kb limit ...[original image is uploaded ]
                                else 
                                {
                                    // console.log("no need to resize");
                                    const reader = new FileReader();
                                        reader.onloadend = () => 
                                        {
                                            const imageDataUrl = reader.result;
                                            setImageSrclink(String(imageDataUrl));
                                            setImageUrl(imageDataUrl); // Set the state with the Base64 data URL if needed
                                            setEnableClearBtn(true);
                                        };
                                        reader.readAsDataURL(file);
                                }
                                // Reset the input element by changing the key
                                setKey(prevKey => prevKey + 1);
        
                    }
                }
            };
 
        const resizeImage = (file: File) =>
            {
                return new Promise<string>((resolve, reject) =>
                    {
                        Resizer.imageFileResizer(
                            file,
                            400, // max width
                            400, // max height
                            'JPEG', // format
                            100, // quality
                            0, // rotation
                            (uri:any) => {
                                resolve(uri);
                                //  console.log("Resized image size:", (uri.length * (3 / 4))); // Approximate size in bytes
        
                            },
                            'base64',
                            300 // target size in KB
                        );
                    });
            };
    //EOF SOP-82 Included By PCS  method ends


    // User role Dropdown API
        const GetUserRoleList = async () =>
        {
        try{
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/getUserRoleList`,);
                //console.log("User Role" ,res.data.data);
                let formattedUserRole = res.data.data.map((item:any) => {
                const { user_role_id, user_role_name } = item;
                return {
                    name: user_role_name,
                    value: user_role_id,
                };
                });
                setEmployeeRoleOptions(formattedUserRole)
            } 
        catch (err) {
            console.log( "co",err);
                    }
        };

    // User role Dropdown API
        const GetEmployeeReportToWhomList = async () =>
        {

            const EmployeeLisPayload ={
                CompanyId:CompanyId,
                active:"Y"
            }
            //console.log("EmployeeLisPayload",EmployeeLisPayload)
            try {
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/employee/getEmployeeList`,EmployeeLisPayload);
                let formattedEmployeeList = res.data.data.map((item:any) => {
                const { employee_id, employee_name } = item;
                return {
                    name: employee_name,
                    value: employee_id,
                };
                });
                setEmployeeReportToWhomListOptions(formattedEmployeeList)
            } 
            catch (err) {
                console.log( "co",err);
                }
        };

    //SOP-31--Included By PCS[To get designation drop down values]   
        const GetDesignationList = async () => 
        {
            const DesignationListPayload ={
                CompanyId:CompanyId,
                                          }
          //console.log("DesignationListPayload",DesignationListPayload)

         try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/getDesignationList`,DesignationListPayload);
           // console.log("Designation List" ,res.data.data);
            let formattedDesignationList = res.data.data.map((item:any) => {
            const { designationid, designationname } = item;
            return {
                name: designationname,
                value: designationid,
                    };
            });
            setEmployeeDesignationOptions(formattedDesignationList)
            } 
         catch (err) {
            console.log( "co",err);
          }
        };

    //EOF SOP-31

    //SOP-31--Included By PCS[To get department drop down values]   
        const GetDepartmentList = async () =>
        {
                const DepartmentListPayload ={
                    CompanyId:CompanyId,
                                            }
                //console.log("DepartmentListPayload",DepartmentListPayload)

        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/getDepartmentList`,DepartmentListPayload);
            //console.log("Department List" ,res.data.data);
            let formattedDepartmentList = res.data.data.map((item:any) => {
            const { departmentid, departmentname } = item;
            return {
                name: departmentname,
                value: departmentid,
            };
            });
            setEmployeeDepartmentOptions(formattedDepartmentList)
        } 
        catch (err) {
            //console.log( "co",err);
                    }
        };
    //EOF SOP-31--Included By PCS
    
    
   // SOP-66
        const GetHRHireRequestEmploymentTypeList = async () => { 
            try {
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/getHRHireRequestEmploymentTypeList`);
                // console.log("GetHRHireRequestEmploymentTypeList" ,res.data.data);
                let formattedPriorityType = res.data.data.map((item:any) => {
                const { sys_hr_employment_type_id, sys_hr_employment_type_name } = item;
                return {
                    name: sys_hr_employment_type_name,
                    value: sys_hr_employment_type_id,
                };
                });
                setEmploymentTypeListOptions(formattedPriorityType)
            } catch (err) {
                console.log( "co",err);
            }
        };

        const GetHRHireRequestEmploymentSiteLocationList = async () => { 
            try {
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/employee/get_hr_hire_request_employee_site_location_list`);
                // console.log("GetHRHireRequestEmploymentSiteLocationList" ,res.data.data);
                let formattedPriorityType = res.data.data.map((item:any) => {
                const { sys_hr_employment_site_location_id, sys_hr_employment_site_location_name } = item;
                return {
                    name: sys_hr_employment_site_location_name,
                    value: sys_hr_employment_site_location_id,
                };
                });
                setEmployeeSiteLocationListOptions(formattedPriorityType)
            } catch (err) {
                console.log( "co",err);
            }
        };

//   // API for Appointment list data to auto populate to respective field 
//   const handleAppointmentListValues = (hrHireRequestListrowDataId:any) => {
//     commonService
//       .postService(`${process.env.REACT_APP_BACKEND_URL}/employee/get_hr_hire_request_details_by_id`, {
//           // AppointmentId: HRHireRequestPendingListRowDataId ,
//           CompanyId:CompanyId,
//           HRHireRequestId:10,
//           CreatedBy:UserId
//       })
//       .then((response) => {
//         console.log("Datares",response)
//         const data = response.data.data[0];

       

      
//         // console.log("arrSexData",arrSexData)
//         // setUpdateValue("patientName", data.FirstName);
//         // setUpdateValue("patientSurName", data.LastName);
       
//         setToGetHireRequest(data.HRHireRequestId[0]) // to set appointment id
//         // setUpdateValue("MobileNo", data.MobileNo);
//         // setUpdateValue("age", data.Age);
//         // setUpdateValue("DOB", data.DOB);
//         // setUpdateValue("email", data.Email);
//         // age_recalculate(data.DOB);
//         // dob_recalculate(data.Age);
//       })
//         .catch((error) => {
//           console.log("errconsole",error)
//       });
//   };
  
//   //getting clicked row data here using localstorage 
//   const rowDataString = localStorage.getItem('rowClickedData');
  
//   if (rowDataString !== null) {
//     const HRHireRequestPendingListRowData = JSON.parse(rowDataString);
//     //  to pass AppointmentId id for handleAppointmentListValues function
//     handleAppointmentListValues(HRHireRequestPendingListRowData.AppointmentId)

      
//     localStorage.removeItem('rowClickedData');
//     localStorage.removeItem('selectedRowId');
//     // setUpdateValue('search', HRHireRequestPendingListRowData.AppointmentId)
//   }

    
    // SOP-66
    
        // Exist  Email API
        const GetCompanyExistEmail = async () => {   
        
            const  ExistEmailPayload = {
                ExistEmail:EmployeeEmailValue
            }
            try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/company/getCompanyEmailExists`,ExistEmailPayload);
            //console.log(res.data.data);
            return res.data.data || [];
            } catch (err) {
            console.log( "co",err); 
            }
        };

    // To create an employee

    // Define a type for errors
    type Errors = {
    employeeName?:boolean;
    employeeEmail?:boolean;
    employeeMobile?:boolean;
    employeePassword?:boolean;
    employeeRole?:boolean;
    employeeReportToWhom?:boolean;
    employeeDateOfJoining?:boolean;
    employeeDesignation?:boolean;
    employeeDepartment?:boolean; //SOP-31 Included By PCS [Included Department field ]
    employeeWorkLocation?:boolean;
    employeeNo?: boolean;

    // // (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - TS - start - Define a type for errors
        EmploymentType?: boolean;
        JobCodeAndTitle?: boolean;
        DivisionAndFunction?: boolean;
        OtherRemarks?: boolean;
        Business?: boolean;
        OfficeBuilding?: boolean;
        OfficeAddress?: boolean;
        EmployeeSiteLocation?: boolean;
        HireRequestId?: boolean;
         // (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - TS - end
    };

    // Encrypt of password
    const secretPass = "XkhZG4fW2t2W";

        const encryptData = async () => {
            try {
            const data = CryptoJS.AES.encrypt(JSON.stringify(EmployeePasswordValue),secretPass).toString();
                return data
            } catch (error) {
            console.error("Error encrypting data:", error);
            }
        };

    //SOP-80 Included By PCS  [to send email to employee after adding employee]
     //To get Company's Email from mailconfiguration
       const getCompanyEmailConfiguration =()=>
       {
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/emailconfigurationCompany/get_emailconfiguration_company_list_by_id`, {
            CompanyId:CompanyId
            })
            .then((res: any) => 
            { 
            const EmailConfigurationCompanyData = res.data.data[0]
            setCompanyEmailValue(EmailConfigurationCompanyData.company_mailusername)
            }).catch((error) =>  
            { 
            //Failed(error.response.message);
            });
        }

   //To send Email to employee 

      const sendEmailToEmployee =(CreateEmployeePayload:any)=>
        {
            const EmployeeInformation = {
                EmployeeName:CreateEmployeePayload.EmployeeName,
                EmployeeEmail:CreateEmployeePayload.EmployeeMail,
                EmployeePassword:CreateEmployeePayload.EmployeePassword,
                CompanyName:CompanyName,
                CompanyEmail:CompanyEmailValue,
                CompanyId:CompanyId

             };
                commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/employee/toSendEmailtoEmployee`, EmployeeInformation)
                .then((res) => {
                    
                    if (res.status === 200) {}
                                })
                .catch((error) => {
                        console.error("Error while sending email to employee:", error);
                                });

        }
   //EOF SOP-80 Inlcuded By PCS [to send email to employee after adding employee]





    const CreateEmployee =async (event:any)=>{
       
        event.preventDefault();
        let errors: Errors = {};
        const ExistEmployeeEmailList = await GetCompanyExistEmail();
       
        if (ExistEmployeeEmailList.length > 0) {
            setEmployeeEmailError(true);

            //SOP-17 Modified By PCS[Testing feedback corrections]
            //setEmployeeEmailErrorMessage("Eamil is already exists");
             setEmployeeEmailErrorMessage("Email  already exists");
            //EOF SOP-17 Modified By PCS[Testing feedback corrections]

            errors.employeeEmail = true;
            return;
        }        

        // Encrypt the data
        const GetEncryptedData = await encryptData()
        //console.log("GetEncryptedData",GetEncryptedData)


        // // (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - TS - start
        const trimmedWithJobCodeAndTitleValue = JobCodeAndTitleValue.trim();
        if (trimmedWithJobCodeAndTitleValue.length >225 ) {
            errors.JobCodeAndTitle=true
            setJobCodeAndTitleErrorMessage("Max 255 characters");
        } 
     
        const trimmedDivisionAndFunctionNameValue = DivisionAndFunctionNameValue.trim();
        if( trimmedDivisionAndFunctionNameValue.length >225 ){
           errors.DivisionAndFunction=true
           setDivisionAndFunctionNameErrorMessage("Max 255 characters");
        }

        const trimmedOtherRemarksValue = OtherRemarksValue.trim();
        if( trimmedOtherRemarksValue.length >225 ){
           errors.OtherRemarks=true
           setOtherRemarksErrorMessage("Max 255 characters");
        }

        const trimmedBussinessValue = BusinessUnitValue.trim();
        if( trimmedBussinessValue.length >225 ){
           errors.Business=true
           setBusinessUnitErrorMessage("Max 255 characters");
        }
        const trimmedOfficeBuildingValue = OfficeBuildingValue.trim();
        if( trimmedOfficeBuildingValue.length >225 ){
           errors.OfficeBuilding=true
           setOfficeBuildingErrorMessage("Max 255 characters");
        }

         const trimmedOfficeAddressValue = OfficeAddressValue.trim();
        if( trimmedOfficeAddressValue.length >225 ){
           errors.OfficeAddress=true
           setOfficeAddressErrorMessage("Max 255 characters");
        }
        //  const trimmedEmployeeSiteLocationValue = EmployeeSiteLocationValue.trim();
        // if( trimmedEmployeeSiteLocationValue.length >225 ){
        //    errors.EmployeeSiteLocation  =true
        //    setEmployeeSiteLocationErrorMessage("Max 255 characters");
        // }

          const trimmedHireRequestIdValue = HireRequestIdErrorIdValue.trim();
        if(trimmedHireRequestIdValue.length >225 ){
           errors.HireRequestId  =true
           setHireRequestIdErrorIdErrorMessage("Max 255 characters");
        }
        // // (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - TS - end

        
        const trimmedEmployeeNameValue = EmployeeNameValue.trim();
        if (trimmedEmployeeNameValue === '') {
            errors.employeeName=true
            setEmployeeNameError(true);
            setEmployeeNameErrorMessage("Employee name is required");
        } 
        else if( trimmedEmployeeNameValue.length >225 ){
            errors.employeeName=true
            setEmployeeNameError(true);
            setEmployeeNameErrorMessage("Max 255 characters");
        }


        const trimmedEmployeeEmailValue = EmployeeEmailValue.trim();
        if (trimmedEmployeeEmailValue === '') {
            errors.employeeEmail=true
            setEmployeeEmailError(true);
            setEmployeeEmailErrorMessage("Email is required");
        } 
        else if( trimmedEmployeeEmailValue.length >255 ){
            errors.employeeEmail=true
            setEmployeeEmailError(true);
            setEmployeeEmailErrorMessage("Max 255 characters");
        }
        else if( !(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(trimmedEmployeeEmailValue) ){
            setEmployeeEmailError(true);
            setEmployeeEmailErrorMessage("Invalid Email");
        }

        const trimmedEmployeeMobileValue = EmployeeMobileValue.trim();
        if (trimmedEmployeeMobileValue === '') {
            errors.employeeMobile=true
            setEmployeeMobileError(true);
            setEmployeeMobileErrorMessage("Mobile number is required");
        } 
        else if( trimmedEmployeeMobileValue.length >15 ){
            errors.employeeMobile=true
            setEmployeeMobileError(true);
            setEmployeeMobileErrorMessage("Invalid mobile number");
        }
        else if( !/^[0-9]+$/.test(trimmedEmployeeMobileValue) ){
            errors.employeeMobile=true
            setEmployeeMobileError(true);
            setEmployeeMobileErrorMessage("Numbers only");
        }

        const trimmedEmployeePasswordValue = EmployeePasswordValue.trim();
        if (trimmedEmployeePasswordValue === '') {
            errors.employeePassword=true
            setEmployeePasswordError(true);
            setEmployeePasswordErrorMessage("Password is required");
        } 
        else if( trimmedEmployeePasswordValue.length >12 ){
            errors.employeePassword=true
            setEmployeePasswordError(true);
            setEmployeePasswordErrorMessage("Max 12 characters");
        }

        if (selectedEmployeeRoleId === '' || selectedEmployeeRoleId === null || selectedEmployeeRoleId === undefined) {
            errors.employeeRole = true;
            setEmployeeRoleErrorMessage("Role is required")
        }

        if (selectedEmployeeReportToWhomId === '' || selectedEmployeeReportToWhomId === null || selectedEmployeeReportToWhomId === undefined) {
            errors.employeeReportToWhom = true;
            //SOP-17 Modified By PCS[Testing feedback Corrections]
            //setEmployeeReportToWhomErrorMessage("Report To Whom is required")
              setEmployeeReportToWhomErrorMessage("Reporting To Whom is required")
        }

        let trimmedEmployeeDateOfJoiningValue;
        if (EmployeeDateOfJoiningValue === null || EmployeeDateOfJoiningValue === undefined) {
            // Handle the case when EmployeeDateOfJoiningValue is null or undefined
            errors.employeeDateOfJoining = true;

             //SOP-17 Modified By PCS[Testing feedback Corrections]
             //setEmployeeDateOfJoiningErrorMessage("Date Of Joining is required");
              setEmployeeDateOfJoiningErrorMessage("Date of Joining is required");

        } else {
            const dateString = EmployeeDateOfJoiningValue.format(); // Assuming your `Dayjs` object has a `format()` method
             trimmedEmployeeDateOfJoiningValue = dateString.trim();
             //console.log("trimmedEmployeeDateOfJoiningValue.l",trimmedEmployeeDateOfJoiningValue.length)
        
            if (trimmedEmployeeDateOfJoiningValue === "") {
                errors.employeeDateOfJoining = true;

                //SOP-17 Modified By PCS[Testing feedback Corrections]
                //setEmployeeDateOfJoiningErrorMessage("Date Of Joining is required");
                  setEmployeeDateOfJoiningErrorMessage("Date of Joining is required");
            } 
            else if(trimmedEmployeeDateOfJoiningValue.length < 25){
                errors.employeeDateOfJoining = true;
                setEmployeeDateOfJoiningErrorMessage("Please check Date Of Joining");
            }
            
            else {
                setEmployeeDateOfJoiningErrorMessage("");
            }
        }

        let FormatedJoiningDate;
        if (trimmedEmployeeDateOfJoiningValue) {
            const trimmedDate = new Date(trimmedEmployeeDateOfJoiningValue);
            const year = trimmedDate.getFullYear();
            const month = String(trimmedDate.getMonth() + 1).padStart(2, '0');
            const date = String(trimmedDate.getDate()).padStart(2, '0');
            FormatedJoiningDate = `${year}-${month}-${date}`;
            // Now you can use the formattedDate variable where needed
          } else {
            // nothing
          }


    //SOP-31 Modified By PCS--[Designation changed from Text field to Drop down]

        // const trimmedEmployeeDesignationValue = EmployeeDesignationValue.trim();
        //  if( trimmedEmployeeDesignationValue.length >255 ){
        //     errors.employeeDesignation=true;
        //     setEmployeeDesignationErrorMessage("Max 255 characters");
        // }
        // else {
        //     setEmployeeDesignationError(false);
        //     setEmployeeDesignationErrorMessage("");
        // }

        if (selectedEmployeeDesignationId === '' || selectedEmployeeDesignationId === null || selectedEmployeeDesignationId === undefined) {
            errors.employeeDesignation = true;
            setEmployeeDesignationErrorMessage("Designation is required")
         }

    //EOF SOP-31 Modified By PCS
    //SOP-31 Included By PCS[Department field is included ]

            if (selectedEmployeeDepartmentId === '' || selectedEmployeeDepartmentId === null || selectedEmployeeDepartmentId === undefined) {
                errors.employeeDepartment = true;
                setEmployeeDepartmentErrorMessage("Department is required")
            }

        //EOF SOP-31 Modified By PCS--
        
        const trimmedEmployeeWorkLocationValue = EmployeeWorkLocationValue.trim();
        if(trimmedEmployeeWorkLocationValue === '') {
            errors.employeeWorkLocation=true;
            setEmployeeWorkLocationErrorMessage("Work Location is required");
        }
        else if( trimmedEmployeeWorkLocationValue.length >255 ){
            errors.employeeWorkLocation=true;
            setEmployeeWorkLocationErrorMessage("Max 255 characters");
        }
        
        else {
            setEmployeeWorkLocationError(false);
            setEmployeeWorkLocationErrorMessage("");
           }
       

        const trimmedEmployeeNoValue = EmployeeNoValue.trim();
        if( trimmedEmployeeNoValue.length >255 ){
           errors.employeeNo=true;
           setEmployeeNoErrorMessage("Max 255 characters");
       }
       else {
           setEmployeeNoError(false);
           setEmployeeNoErrorMessage("");
       }

        
    
        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setEmployeeNameError(errors.employeeName || false);
            setEmployeeEmailError(errors.employeeEmail || false);
            setEmployeePasswordError(errors.employeePassword || false);
            setEmployeeMobileError(errors.employeeMobile || false);
            setEmployeeRoleError(errors.employeeRole || false);
            setEmployeeReportToWhomError(errors.employeeReportToWhom || false);
            setEmployeeDateOfJoiningError(errors.employeeDateOfJoining || false)
            setEmployeeDesignationError(errors.employeeDesignation || false)
            setEmployeeDepartmentError(errors.employeeDepartment || false) //SOP-31 Included By PCS[Included Department]
            setEmployeeWorkLocationError(errors.employeeWorkLocation || false)
            setEmployeeNoError(errors.employeeNo || false)
 
           // (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - TS - start (update the state with all the errors)
            setJobCodeAndTitleError(errors.JobCodeAndTitle || false);
            setDivisionAndFunctionNameError(errors.DivisionAndFunction || false);
            setOtherRemarksError(errors.OtherRemarks || false);
            setBusinessUnitError(errors.Business || false);
            setOfficeBuildingError(errors.OfficeBuilding || false);
            setOfficeAddressError(errors.OfficeAddress || false);
            // setEmployeeSiteLocationError(errors.EmployeeSiteLocation || false);
            setHireRequestIdErrorIdError(errors.HireRequestId || false);
            // (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - TS - end
            return;
        }

        const CreateEmployeePayload = {
            SaveUpdateKey: 'create',
            // EmployeeId:0,
            EmployeeName:EmployeeNameValue,
            EmployeeMail:EmployeeEmailValue,
            EmployeeMobile:EmployeeMobileValue,
            EmployeePassword:GetEncryptedData,
            EmployeeRole:selectedEmployeeRoleId,
            CompanyId: CompanyId,
            Active: null,
            CreatedBy: UserId,
            LastModifiedBy: null,
            IsRemove: null,
            ReportToWhom:selectedEmployeeReportToWhomId,
            DateOfJoining:FormatedJoiningDate,
            DateOfRelieving:null,
            //SOP-31 Modified By PCS[Text field designation removed ,Drop down designation & department included]
            // Designation:EmployeeDesignationValue,
            DesignationId:selectedEmployeeDesignationId,
            DepartmentId:selectedEmployeeDepartmentId,
            //EOF SOP-31 Modified By PCS
            PhotoUrl:imageSrclink,
            WorkLocation:EmployeeWorkLocationValue,
            HandOveredSts:null,
            EmployeeNo:EmployeeNoValue,
            ReasonOfRelieving:null,
            // SOP-39 - TS  - Asset Management - User Rights - MenuId
            MenuId: selectedMenuIds,

            // (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - TS - start (Payload data)
            BusinessUnit:BusinessUnitValue,
            OfficeBuilding:OfficeBuildingValue,
            OfficeAddress:OfficeAddressValue,
            // EmployeeTypeId:formData.hirerequest_employment_type_id || selectedEmploymentId || null,
             EmployeeTypeId:formDataemployment_type_id.hirerequest_employment_type_id || selectedEmploymentId || null,
            EmployeeSiteLocation:selectedEmployeeSiteLocation || null,
            DivisionAndFunction:formDatahiring_division_function.hirerequest_hiring_division_function || DivisionAndFunctionNameValue,
            JobCodeAndTitle:formDatajobcode_title.hirerequest_hiring_jobcode_title || JobCodeAndTitleValue,
            //SOP-66 Testing feedback corrections Modified By PCS [to bind "Other Remarks" value]
            // Others:OtherRemarksValue,
            Others:formDatahiring_otherRemarks.hirerequestOtherRemarks ||OtherRemarksValue,
            //EOF SOP-66 Testing feedback corrections Modified By PCS
            // Hirerequestid:formData.hirerequestid || null
            Hirerequestid:formDatahirerequestid.hirerequestid || null
            // Hirerequestid:parseInt(HireRequestIdErrorIdValue,10)
            // (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - TS - end
       
        }

        //  console.log("CreateEmployeePayload",CreateEmployeePayload)

        //SOP-17 Included By PCS[Testing feedack corrections- to avoid multiple toaster msg]

        Swal.fire({
            html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            cancelButtonColor: '#d33',
            confirmButtonColor:"#25425f",
            allowOutsideClick: false,
            })
        .then((result) =>
        {
        if (result.isConfirmed)
        {

        //EOF SOP-17 Included By PCS[Testing feedack corrections - to avoid multiple toaster msg]  


        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/employee/employee_create_update_delete`, CreateEmployeePayload)
        .then((res) => {
            console.log('res',res)
          if (res.status === 200) {

            //SOP-91 Inlcuded By PCS[to 'Request an asset' while adding employee]
                const employeeId = res.data.employeeId; //On successof employee creation,get the employee ID from the response
                // console.log("New Employee ID:", employeeId); 
                createRequestanAsset(employeeId);//passign the employeeid to requestan asset payload
            //EOF SOP-91 Inlcuded By PCS[to 'Request an asset' while adding employee]
              Success("Employee created successfully");
              sendEmailToEmployee(CreateEmployeePayload);//SOP-80 Included by PCS
              navigate('/employee')
            }
        })
            
            .catch((error) => {
                // (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - TS - start (if otal filled positions  exceed tost msg)
                if (error.response.data.message === "The total filled positions cannot exceed the total open positions.") {
                    Failed("The total filled positions cannot exceed the total open positions.");
                }
                // (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - TS - end
                else {
                    Failed("Something is wrong");
                }
              console.error("Error:", error.response.data.message);
              
          });

        //SOP-17 Included By PCS[Testing feedack corrections- to avoid multiple toaster msg]

        } 
        else  
        {
        return
        }
        });
   //EOF SOP-17 Included By PCS[Testing feedack corrections-to avoid multiple toaster msg]  

   }

    // const update_hirerequest_totalsPayload = {
    //     Action:'create',
    //     EmployeeId:EmployeeId,
    //     CompanyId:CompanyId,
    //     HireRequestId:formData.hirerequestid || HireRequestIdErrorIdValue
    // }


    // SOP-39 - TS  Asset Management - User Rights- list MenuList - start
       const listGetMenuList=()=>
    {
          commonService.postService(`${process.env.REACT_APP_BACKEND_URL}/employee/getMenuList`,{
          }).then((res)=>{
            // console.log('Menu res 81',res.data.data)
         let formattedMenuList = res.data.data.map((item: any) => {
            return {
                id: item.menuid.toString(),
                name: item.menuname,
                defaultmenu: item.defaultmenu 
            };
         });
          setMenus(formattedMenuList);
           }).catch((err)=>{
          })
        }
      // SOP-39 - TS  Asset Management - User Rights- list MenuList - end

    // (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - TS - start
       // Function to load data from localStorage
       const loadDataFromLocalStorage = () => {
        const rowClickedData = localStorage.getItem('rowClickedData');
        if (rowClickedData) {
            const parsedData = JSON.parse(rowClickedData);
            setFormData(parsedData);
            setFormDatajobcode_title(parsedData);
            setFormDatahirerequestid(parsedData);
            setFormDataemployment_type_id(parsedData);
            setFormDatahiring_division_function(parsedData);
            setFormDatahiring_start_date(parsedData);
            setFormDatahiring_departmentname(parsedData);
            // console.log("parsedData", parsedData)
            // dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY")

            //SOP-66 Testing feedback corrections Included By PCS [to bind "Other Remarks" value]
             setFormDatahiring_otherRemarks(parsedData);
            
            localStorage.removeItem('rowClickedData'); // Clear localStorage after loading data
        }
    };


    //SOP-91 Included By PCS [to request an asset while adding an employee]
     const createRequestanAsset=(employeeId:any)=>
        {
            const RequestAnAssetPayload ={
                SaveUpdateKey : "create",
                CompanyId : CompanyId,
                RequestId: 0,
                RequestBy:UserId,
                RequestForWhichEmployeeId: employeeId,
                RequestForWhichAssetId:null,
                RequestRemarks:AssetRequestCommentValue,
                IsCancelled:null,
                CreatedBy:UserId,
                LastModifiedBy:null,
                RequestForWhichSubcategoryId:SelectedSubCategoryId,
                RequestForWhichCategoryId:SelectedCategoryId,
            }
           console.log("RequestAnAssetPayload",RequestAnAssetPayload)
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/requestanasset/asset_request_create_update_cancel`, RequestAnAssetPayload)
            .then((res) => {
              if (res.status === 200) {
                  Success("Asset Requested Successfully");
              }
              }).catch((error) => {
                  console.error("Error:", error);
              });
          
            }
     //EOF SOP-91 Included By PCS [to request an asset while adding an employee]

    //SOP-91 Included By PCS [to get all sub category and category list to fill in drop down]
         const GetSubCategoryandCategoryList = async () => {
            const Payload ={
                CompanyId:CompanyId
                     }
           try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/getSubCategoryandCategoryList`,Payload);
            let formattedSubCategoryandCategory = res.data.data.map((item:any) => {
            // console.log('both...',res.data.data)
            const { subcategoryid, SubcategoryandCategory } = item;
            return {
                name: SubcategoryandCategory,
                value: subcategoryid,
            };
            });
            setCategoryandSubCategoryListOptions(formattedSubCategoryandCategory)
        } catch (err) {
        console.log( "co",err);
        }
        };
    //EOF SOP-91 Included By PCS [to get all sub category and category list to fill in drop down]

    
    //SOP-91 Included By PCS [to get category for the selected sub category]
    const GetCategoryIdBySubCategoryId = async (subcategoryid:any) => {

           const SubcategoryPayload ={
            CompanyId:CompanyId,
            SubCategoryId:subcategoryid
                 }
            try {
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/getCategoryIdbySubcategoryId`,SubcategoryPayload);
                let CategoryId = res.data.data[0].categoryid
                // console.log('CategoryId',CategoryId)
                setSelectedCategoryId(CategoryId)
                } 
            catch (err) {
            console.log( "co",err);
                }
       };
    //EOF SOP-91 Included By PCS [to get category for the selected sub category]

        useEffect(() => {
           GetSubCategoryandCategoryList();//SOP-91 Included By PCS [to get subcategory,category dropdown]
           loadDataFromLocalStorage();
        }, []);

    // (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - TS - end


    useEffect(() => {
        GetUserRoleList();
        GetEmployeeReportToWhomList();
        //SOP-31 Included By PCS [Designation & Department drop down]
        GetDesignationList();
        GetDepartmentList();
        //EOF SOP-31 

        // SOP-39 TS-  Asset Management - User Rights- list MenuList
        listGetMenuList();     //list MenuList

        // (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - TS
        GetHRHireRequestEmploymentTypeList(); //EmploymentTypeList API
        GetHRHireRequestEmploymentSiteLocationList(); //EmploymentSiteLocationList API

        getCompanyEmailConfiguration();//SOP-80 Included By PCS

    }, []); 

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        // setFormData(prevState => ({
        //     ...prevState,
        //     [name]: value
        // }));
    };


    // SOP-39 TS  Asset Management - User Rights- list MenuList - start
    //  --Drag and Drop starts--

    // Define item type
    type MenuType = {
        id: string;
        name: string;
    };

        // Item component
        const MenuItem = ({ menu, index }: { menu: MenuType; index: number }) =>
        {
                return (
                    <Draggable draggableId={menu.id} index={index}>
                    {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                        userSelect: 'none',
                        padding: 6,
                        margin: '0 0 8px 0',
                        backgroundColor: 'lightgrey',
                        // width: '100px', // Set width to 20px
                        ...provided.draggableProps.style,
                        }}
                    >
                        {menu.name}
                    </div>
                    )}
                </Draggable>
                    );
            };

        // DragGrid component
        const DragGrid = ({ menus }: { menus: MenuType[] }) =>
            {
            return (
                <Droppable droppableId="dragGrid">
                {(provided, snapshot) => (
                    <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                        minHeight: '200px', // Set a minimum height to enable scrolling
                        overflowY: 'auto',  // Enable vertical scrolling
                    }}
                    >
                    {menus.map((menu, index) => (
                        <MenuItem key={menu.id} menu={menu} index={index} />
                    ))}
                    {provided.placeholder}
                    </div>
                )}
                </Droppable>
            );
            };

        // DropGrid component
        const DropGrid = ({ selectedMenus }: { selectedMenus: MenuType[] }) => 
            {
                return (
                <Droppable droppableId="dropGrid">
                {(provided, snapshot) => (
                    <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                    minHeight: '200px', // Set a minimum height to enable scrolling
                    overflowY: 'auto',  // Enable vertical scrolling
                    }}
                >
                    {selectedMenus.map((menu, index) => (
                    <MenuItem key={menu.id} menu={menu} index={index} />
                    ))}
                    {provided.placeholder}
                </div>
                )}
            </Droppable>
                );
            };

    //To split selected menus with comma and send it to payload as a string
        let selectionModeresult = '';
        for (let i = 0; i < selectedMenuIds.length; i++)
        {
    selectionModeresult += selectedMenuIds[i];
        if (i < selectedMenuIds.length - 1) {
        selectionModeresult += ',';
        }
        }

    // console.log("selectionModeresult",selectionModeresult)
    //To filter menus
    const filteredMenus = menus.filter((menu) =>
        menu.name.toLowerCase().includes(searchQuery.toLowerCase())
    ); 
    //   console.log('filtered result',filteredMenus)

    // --Drag and drop starts here--
    const onDragEnd = (result: any) => 
        {
        const { source, destination,draggableId } = result;
        //If dropping is placed out of the grid
        if (!destination) return;
        //   console.log('source is',source.droppableId)
        //   console.log('destination is',destination.droppableId)
    
        // If there's no destination or if the source and destination are the same, do nothing
        if (!destination || (source.droppableId === destination.droppableId && 
            source.index === destination.index)) {
            return;
            }
    
        if (!destination || (source.droppableId === destination.droppableId)) {
            //console.log('Same grid',source.droppableId)
            return;
            }
        
        const draggedMenu = source.droppableId === 'dragGrid' ? 
        (filteredMenus.length > 0 ? filteredMenus[source.index] : menus[source.index]) : 
        selectedMenus[source.index];
        //console.log('dragged doc',draggedMenu)
    
        // Remove the dragged menu from the source list
        let newSourceMenus: MenuType[];
        if (source.droppableId === 'dragGrid')
        {
            newSourceMenus = [...menus]  ;
            newSourceMenus.splice(source.index, 1);
            setMenus(newSourceMenus);
        
            // If filteredMenus is not empty, remove the dragged menu from it 
            if (filteredMenus.length > 0)
            {
            const updatedfilteredMenus = filteredMenus.filter(
                (menu) => menu.id!== draggedMenu.id
            );
            //console.log('draggedMenu.id,,',draggedMenu.id)
            //   console.log('updatedfilteredMenus,,',updatedfilteredMenus)
    
            // Update newSourceMenus to remove the dragged menu from the original list
                const newSourceMenus = menus.filter(
                (menu) => menu.id !== draggedMenu.id
                );
                setMenus(newSourceMenus);
            //console.log('newSourceMenus',newSourceMenus)
            }
        }
        else 
        {
            newSourceMenus = [...selectedMenus];
            newSourceMenus.splice(source.index, 1);
            setSelectedMenus(newSourceMenus);
        }
    
    
        // Add the dragged menu to the destination list
        let newDestinationMenus: MenuType[];
        if (destination.droppableId === 'dragGrid') {
            newDestinationMenus = [...menus];
            newDestinationMenus.splice(destination.index, 0, draggedMenu);
            setMenus(newDestinationMenus);
        } else {
            newDestinationMenus = [...selectedMenus];
            newDestinationMenus.splice(destination.index, 0, draggedMenu);
            setSelectedMenus(newDestinationMenus);
        }
    
        }
    // --Drag and drop ends here--
  
     // Handle checkbox change
     const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        // Filter out menus that are already present in SelectedMenus
        const newmenus = menus.filter((menu) => !selectedMenus.some((selectedMenu) => selectedMenu.id === menu.id));
        // Move newmenus to selectedMenus
       setSelectedMenus((prevSelectedMenus) => [...prevSelectedMenus, ...newmenus]);
        // Clear menus list
        setMenus([]);
      } else {
        listGetMenuList();
        // Clear setSelectedMenus list
        setSelectedMenus([]);
      }
      };
    // --Drag and Drop--
    //SOP-39 - TS Asset Management - User Rights- list MenuList  - end

    // SOP-66
    const handleHireClick=()=>{
            navigate('/view_hierarchy')
     }
    
    const handleHRHireRequestPendingListPageClick=()=> {
        navigate('/HRHireRequestPendingListPage')
    }

    return(
        <>
            <MainContainerWrapper headingText='Add Employee'
                // (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - commented & included by TS ( button )
                    // Included by sanjana SOP-56 to add View hierarchy btn 
                    // handleClick={() =>{
                    //     navigate('/view_hierarchy')
                    // }}
                    // buttonText="View Hierarchy"
                    // isSearch={false}
                    // isCreateButton={true}
                    // buttonIcon={<span>&#43;</span>}
                    // End of SOP-56
                // SOP-66 - commented by TS
            >
                <Grid         
                    mb={2} 
                    container
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 4, sm: 8, md: 8, lg: 12 }}
                    rowSpacing={{ xs: 2, md: 1 }}
                    columnSpacing={{ xs: 2, md: 3 }}
                >
                    <Grid item xs={8} sm={8} md={8} lg={2.5}></Grid>
                        <Grid item xs={5} sm={5} md={5} lg={9} mt={-6.5}>
                            <div className="information">
                                <Button
                                    variant="contained"
                                    sx={{backgroundColor:"#25425F",color:"white", width:'800px'}}
                                    onClick={handleHRHireRequestPendingListPageClick}
                                >
                                    if the employee is appointed against HR Request, Please select from the reference List
                                </Button>
                                <>
                                {" "}
                                <Button variant="contained"
                                    onClick={handleHireClick}
                                    sx={{ backgroundColor: "#25425F", color: "white" }}
                                >
                                    View Hierarchy
                                </Button>
                                </>
                            </div>
                        </Grid>
                    </Grid>     
        {/*  // (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - commented & included by TS*/}
                

            <Box>
            <form noValidate>
                <Box>
                    <Box>
                        {/* Employee Information Accordian */}
                       {/* //SOP-89 Modified By PCS [Accordion to be opened by default] */}
                        {/* <Accordion sx={{ borderRadius:'30px'}}> */}
                            <Accordion defaultExpanded sx={{borderRadius:'30px'}}  > 
                        {/* //EOF SOP-89 Modified By PCS [Accordion to be opened by default] */}
                            <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                            
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <FcInfo height={'1rem'} width={'1rem'} fontSize={'1.5rem'} /> 
                                <Typography fontSize={'1rem'} ml={2}> Employee Information</Typography> 
                                </Box>
                            
                                
                            </AccordionSummary>
                            <AccordionDetails sx={{marginBottom:5}}>
                                <Box>
                                    <Box>
                                    <Box>
                                        <Grid container spacing={3} display={'flex'} justifyContent={'center'}>
                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Employee Name'
                                                    name='Employee'
                                                    style={{ margin: '0 10px' }}
                                                    fullWidth
                                                    required
                                                    error={EmployeeNameError}
                                                    onChange={handleEmployeeNameChange}
                                                    value={EmployeeNameValue}
                                                    helperText={EmployeeNameErrorMessage}
                                                    />
                                                </Box> 
                                            </Grid>

                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <TextField
                                                        type='text'
                                                        variant="standard"
                                                        label='Email'
                                                        name='email'
                                                        style={{ margin: '0 10px' }}
                                                        fullWidth
                                                        required
                                                        error={EmployeeEmailError}
                                                        onChange={handleEmployeeEmailChange}
                                                        value={EmployeeEmailValue}
                                                        helperText={EmployeeEmailErrorMessage}
                                                    /> 
                                                </Box>
                                                
                                            </Grid>

                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <TextField
                                                        type='text'
                                                        variant="standard"
                                                        label='Mobile Number'
                                                        name='Employee'
                                                        style={{ margin: '0 10px' }}
                                                        fullWidth
                                                        required
                                                        error={EmployeeMobileError}
                                                        onChange={handleEmployeeMobileChange}
                                                        value={EmployeeMobileValue}
                                                        helperText={EmployeeMobileErrorMessage}
                                                        //SOP-17 Inlcuded By PCS[Testing feedback corrections-to restrict only 15 numbers]
                                                        inputProps={{
                                                            maxLength: 15
                                                        }}
                                                        //EOF SOP-17 Inlcuded By PCS[Testing  feedback corrections]
                                                    /> 
                                                </Box>
                                            </Grid>

                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <TextField
                                                        type='password'
                                                        variant="standard"
                                                        label='Password'
                                                        name='Employee'
                                                        style={{ margin: '0 10px' }}
                                                        fullWidth
                                                        required
                                                        error={EmployeePasswordError}
                                                        onChange={handleEmployeePasswordChange}
                                                        value={EmployeePasswordValue}
                                                        helperText={EmployeePasswordErrorMessage}
                                                        //SOP-17 Inlcuded By PCS[Testing feedback corrections-to restrict only 12 characters]
                                                        inputProps={{
                                                            maxLength: 12
                                                        }}
                                                        //EOF SOP-17 Inlcuded By PCS[Testing  feedback corrections]
                                                    /> 
                                                </Box>                                            
                                            </Grid>

                                {/* //SOP-81 Included By PCS [combining employee information & employee role]*/}
                                {/* //SOP-81 Included By PCS [tooltip*/}
                                <Tooltip title='For User role based Dashboard' placement='top-start'>
                                        <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <Autocomplete
                                                    id="combo-box-demo"
                                                    options={EmployeeRoleOptions as OptionType[]}
                                                    filterOptions={filterOptions}
                                                    getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                    value={EmployeeRoleOptions.find((option: OptionType) => option.value === selectedEmployeeRoleId) || null}
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            setSelectedEmployeeRoleId(newValue.value);
                                                            setEmployeeRoleError(false);
                                                            setEmployeeRoleErrorMessage("");
                                                        } else {
                                                            setSelectedEmployeeRoleId('');
                                                            setEmployeeRoleError(true);
                                                            setEmployeeRoleErrorMessage("Role is required");
                                                        }
                                                    }}
                                                    sx={{ml: 1.5 }}
                                                    renderInput={(params) => 
                                                    <TextField {...params}
                                                    error={EmployeeRoleError}
                                                    helperText={EmployeeRoleErrorMessage}
                                                    required label="Role" 
                                                    variant="standard" />
                                                    }
                                                />
                                                </Box> 
                                        </Grid>
                                </Tooltip>
                                {/* //EOF SOP-81 Included By PCS [tool tip]*/}
                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={EmployeeReportToWhomListOptions as OptionType[]}
                                                        filterOptions={filterOptions}
                                                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                        value={EmployeeReportToWhomListOptions.find((option: OptionType) => option.value === selectedEmployeeReportToWhomId) || null}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                setSelectedEmployeeReportToWhomId(newValue.value);
                                                                setEmployeeReportToWhomError(false);
                                                                setEmployeeReportToWhomErrorMessage("");
                                                            } else {
                                                                setSelectedEmployeeReportToWhomId('');
                                                                setEmployeeReportToWhomError(true);
                                                                setEmployeeReportToWhomErrorMessage("Reported to whom is required");
                                                            }
                                                        }}
                                                        sx={{ml: 1.5 }}
                                                        renderInput={(params) => 
                                                        <TextField {...params}
                                                        error={EmployeeReportToWhomError}
                                                        helperText={EmployeeReportToWhomErrorMessage}
                                                        required label="Reporting to whom" 
                                                        variant="standard" />}
                                                    />
                                                </Box>
                                                
                                            </Grid>
                                {/* //EOF SOP-81 Included By PCS [combining employee information & employee role]*/}



                                        </Grid>
                                    </Box>
                                    </Box>
                                </Box>

                            </AccordionDetails>

                           </Accordion>
                        {/* End of Employee Information Accordian */}
                    </Box>

                    <Box mt={1}>
                        {/* Employee Role Accordian */}
                        {/* //SOP-81 Commented By PCS [combining employee information & employee role]*/}
                        {/* <Accordion>
                            <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                            <Typography fontSize={'1rem'}>
                                
                                </Typography> 
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <FaUserGear height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                                <Typography fontSize={'1rem'} ml={2}>Employee Role</Typography> 
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{marginBottom:5}}>
                                <Box>
                                    <Box>
                                    <Box>
                                        <Grid container spacing={3} display={'flex'} justifyContent={'center'}>
                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <Autocomplete
                                                    id="combo-box-demo"
                                                    options={EmployeeRoleOptions as OptionType[]}
                                                    filterOptions={filterOptions}
                                                    getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                    value={EmployeeRoleOptions.find((option: OptionType) => option.value === selectedEmployeeRoleId) || null}
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            setSelectedEmployeeRoleId(newValue.value);
                                                            setEmployeeRoleError(false);
                                                            setEmployeeRoleErrorMessage("");
                                                        } else {
                                                            setSelectedEmployeeRoleId('');
                                                            setEmployeeRoleError(true);
                                                            setEmployeeRoleErrorMessage("Role is required");
                                                        }
                                                    }}
                                                    sx={{ml: 1.5 }}
                                                    renderInput={(params) => 
                                                    <TextField {...params}
                                                    error={EmployeeRoleError}
                                                    helperText={EmployeeRoleErrorMessage}
                                                    required label="Role" 
                                                    variant="standard" />
                                                    }
                                                />
                                                </Box> 
                                            </Grid>

                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={EmployeeReportToWhomListOptions as OptionType[]}
                                                        filterOptions={filterOptions}
                                                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                        value={EmployeeReportToWhomListOptions.find((option: OptionType) => option.value === selectedEmployeeReportToWhomId) || null}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                setSelectedEmployeeReportToWhomId(newValue.value);
                                                                setEmployeeReportToWhomError(false);
                                                                setEmployeeReportToWhomErrorMessage("");
                                                            } else {
                                                                setSelectedEmployeeReportToWhomId('');
                                                                setEmployeeReportToWhomError(true);
                                                                setEmployeeReportToWhomErrorMessage("Reported to whom is required");
                                                            }
                                                        }}
                                                        sx={{ml: 1.5 }}
                                                        renderInput={(params) => 
                                                        <TextField {...params}
                                                        error={EmployeeReportToWhomError}
                                                        helperText={EmployeeReportToWhomErrorMessage}
                                                        required label="Reporting to whom" 
                                                        variant="standard" />}
                                                    />
                                                </Box>
                                                
                                            </Grid>

                                        </Grid>
                                    </Box>
                                    </Box>
                                </Box>

                            </AccordionDetails>

                        </Accordion> */}
                        {/* //EOF SOP-81 Commented By PCS [combining employee information & employee role] */}
                        {/* End of Employee Role Accordian */}

                    </Box>
                    <Box mt={1}>
                        {/* Joining Details Accordian */}
                       
                        {/* //SOP-89 Modified By PCS [Accordion to be opened by default] */}
                        {/*  <Accordion> */}
                             <Accordion defaultExpanded sx={{borderRadius:'30px'}}  > 
                        {/* //EOF SOP-89 Modified By PCS [Accordion to be opened by default] */}
                            <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <BsBuildingsFill height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                                <Typography fontSize={'1rem'} ml={2}> Joining Details</Typography> 
                                </Box> 
                            </AccordionSummary>
                            <AccordionDetails sx={{marginBottom:5}}>
                                <Box>
                                    <Box>
                                    <Box>
                                        <Grid container spacing={3} display={'flex'} justifyContent={'space-evenly'}>
                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <FormControl
                                                      fullWidth
                                                     >
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        label="Date of Joining"
                                                                        value={EmployeeDateOfJoiningValue ? dayjs(EmployeeDateOfJoiningValue) : null}
                                                                        onChange={handleDateOfJoiningChange}
                                                                        format="DD-MM-YYYY"
                                                                        slotProps={{
                                                                            textField: {
                                                                                error: Boolean(EmployeeDateOfJoiningError),
                                                                                //SOP-17 Inlcuded By PCS[Testing feedback Corrections- to make mandatory]
                                                                                required: true
                                                                                //EOF SOP-17 Inlcuded By PCS[Testing feedback Corrections- to make mandatory]
                                                                            }
                                                                        }}
                                                                    />                                                        

                                                                    {EmployeeDateOfJoiningError && (
                                                                        <FormHelperText
                                                                            error
                                                                            id="standard-weight-helper-text-dob-login"
                                                                        >
                                                                            {EmployeeDateOfJoiningErrorMessage}
                                                                        </FormHelperText>
                                                                    )}
                                                                </LocalizationProvider>
                                                    </FormControl>                                                    

                                                </Box> 
                                            </Grid>

                                {/* SOP-31 Commented By PCS [Designation changed from text to drop down]*/}
                                        {/* <Grid item lg={5} md={12} xs={12} > */}
                                            {/* <Box>
                                                    <TextField
                                                        type='text'
                                                        variant="standard"
                                                        label='Designation'
                                                        name='Designation'
                                                        style={{ margin: '0 10px' }}
                                                        fullWidth
                                                        required={false}
                                                        error={EmployeeDesignationError}
                                                        onChange={handleEmployeeDesignationChange}
                                                        value={EmployeeDesignationValue}
                                                        helperText={EmployeeDesignationErrorMessage}
                                                    /> 
                                            </Box> */}
                                        {/* </Grid> */}
                                    {/* EOF SOP-31 */}


                                            
                                     {/* SOP-31 Included By PCS Designation included*/}
                                                <Grid item lg={2.3} md={12} xs={12} >
                                                <Box  >
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={EmployeeDesignationOptions as OptionType[]}
                                                        filterOptions={filterOptions}
                                                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                        value={EmployeeDesignationOptions.find((option: OptionType) => option.value === selectedEmployeeDesignationId) || null}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                
                                                                setSelectedEmployeeDesignationId(newValue.value);
                                                                setEmployeeDesignationError(false);
                                                                setEmployeeDesignationErrorMessage("");
                                                            } else {
                                                                setSelectedEmployeeDesignationId('');
                                                                setEmployeeDesignationError(true);
                                                                setEmployeeDesignationErrorMessage("Designation is required");
                                                            }
                                                        }}
                                                       
                                                        renderInput={(params) => 
                                                        <TextField {...params}
                                                        error={EmployeeDesignationError}
                                                        helperText={EmployeeDesignationErrorMessage}
                                                            required label="Designation" 
                                                            variant="standard" />}
                                                    />
                                                </Box>
                                                </Grid>
                                    {/* EOF SOP-31 designation ends here*/}
                                    {/* SOP-31 Included By PCS Department included*/}
                                            <Grid item lg={2.1} md={12} xs={12} >
                                            <Box>
                                            <Autocomplete
                                                        id="combo-box-demo"
                                                        options={EmployeeDepartmentOptions as OptionType[]}
                                                        filterOptions={filterOptions}
                                                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                        value={EmployeeDepartmentOptions.find((option: OptionType) => option.value === selectedEmployeeDepartmentId) || null}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                setSelectedEmployeeDepartmentId(newValue.value);
                                                                setEmployeeDepartmentError(false);
                                                                setEmployeeDepartmentErrorMessage("");
                                                            } else {
                                                                setSelectedEmployeeDepartmentId('');
                                                                setEmployeeDepartmentError(true);
                                                                setEmployeeDepartmentErrorMessage("Department is required");
                                                            }
                                                        }}
                                                       
                                                        renderInput={(params) => 
                                                        <TextField {...params}
                                                        error={EmployeeDepartmentError}
                                                        helperText={EmployeeDepartmentErrorMessage}
                                                        required label="Department" 
                                                        variant="standard" />}
                                                    />
                                            </Box>
                                            </Grid> 
                                            
                                     {/* EOF SOP-31  Department ends here*/}       

                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <TextField
                                                        type='text'
                                                        variant="standard"
                                                        label='Work Location'
                                                        name='workLocation'
                                                        // style={{ margin: '0 10px' }}
                                                        fullWidth
                                                        required
                                                        error={EmployeeWorkLocationError}
                                                        onChange={handleEmployeeWorkLocationChange}
                                                        value={EmployeeWorkLocationValue || ''}
                                                        helperText={EmployeeWorkLocationErrorMessage}
                                                    /> 
                                                </Box>
                                            </Grid>

                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <TextField
                                                        type='text'
                                                        variant="standard"
                                                        label='Employee Number'
                                                        name='EmployeeNo'
                                                        // style={{ margin: '0 10px' }}
                                                        fullWidth
                                                        required={false}
                                                        error={EmployeeNoError}
                                                        onChange={handleEmployeeNoChange}
                                                        value={EmployeeNoValue}
                                                        helperText={EmployeeNoErrorMessage}
                                                    /> 
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    </Box>
                                </Box>
                            </AccordionDetails>

                        </Accordion>
                        {/* End of Joining Details Accordian */}                        
                    </Box>

                    {/*(SOP-66) HR - Hire Request - Mapping with Employee Enrollment - Job Information - start */}     
                    <Box mt={1}>
                        {/* Job Information */}
                        {/* //SOP-89 Modified By PCS [Accordion to be opened by default] */}
                        {/*  <Accordion> */}
                             <Accordion defaultExpanded sx={{borderRadius:'30px'}}  > 
                        {/* //EOF SOP-89 Modified By PCS [Accordion to be opened by default] */}
                            <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <PiBriefcaseBold height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                                            <Typography fontSize={'1rem'} ml={2}> Job Information
                                            
                                            {formDatahirerequestid.hirerequestid && formDatahiring_start_date.hirerequest_start_date && formDatahiring_departmentname.departmentname ? (

                                                    <b>(Hire Request No : {formDatahirerequestid.hirerequestid}  Dated :  {formDatahiring_start_date.hirerequest_start_date}  Department : {formDatahiring_departmentname.departmentname})</b>
                                            
                                            ) : null}

                                            </Typography> 
                                            
                                        </Box> 
                            </AccordionSummary>
                            <AccordionDetails sx={{marginBottom:5}}>
                                <Box>
                                    <Box>
                                    <Box>
                                        <Grid container spacing={3} display={'flex'} justifyContent={'space-evenly'} mb={3}>
                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <TextField
                                                        type='text'
                                                        variant="standard"
                                                        label='Business Unit'
                                                        name='Business Unit'
                                                        // style={{ margin: '0 10px' }}
                                                        fullWidth
                                                        required={false}
                                                        error={BusinessUnitError}
                                                        onChange={handleBusinessChange}
                                                        value={BusinessUnitValue}
                                                        helperText={BusinessUnitErrorMessage}
                                                    /> 
                                                </Box>
                                            </Grid>

                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <TextField
                                                        type='text'
                                                        variant="standard"
                                                        label='Office Building'
                                                        name='Office Building'
                                                        // style={{ margin: '0 10px' }}
                                                        fullWidth
                                                        required={false}
                                                        error={OfficeBuildingError}
                                                        onChange={handleOfficeBuildingChange}
                                                        value={OfficeBuildingValue}
                                                        helperText={OfficeBuildingErrorMessage}
                                                    /> 
                                                </Box>
                                            </Grid>
                                            </Grid>
                                            <Grid container spacing={3} display={'flex'} justifyContent={'space-evenly'} mb={1.5}>

                                           <Grid item lg={10.8} md={12} xs={12}  >
                                            <TextField
                                                type='text'
                                                variant="filled"
                                                label='Office Address'
                                                name='Office Address'
                                                fullWidth
                                                multiline
                                                maxRows={10}
                                                error={OfficeAddressError}
                                                // sx={{height:'50px'}}
                                                onChange={handleOfficeAddressChange}
                                                value={OfficeAddressValue}
                                                helperText={OfficeAddressErrorMessage}
                                            /> 
                                        </Grid>
                                        </Grid>
                                        <Grid container spacing={3} display={'flex'} justifyContent={'space-evenly'} mb={3}>
                                            <Grid item lg={5} md={12} xs={12} >
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    fullWidth
                                                    options={ EmploymentTypeListOptions as OptionType[]}
                                                    filterOptions={filterOptions}
                                                    getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                                // value={EmploymentTypeListOptions.find((option: OptionType) => option.value === formData.hirerequest_employment_type_id || selectedEmploymentId) || null}
                                                                value={EmploymentTypeListOptions.find((option: OptionType) => option.value === formDataemployment_type_id.hirerequest_employment_type_id || selectedEmploymentId) || null}
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            setselectedEmploymentId(newValue.value);
                                                            // setFormData(newValue.value);
                                                        } else {
                                                            setselectedEmploymentId('');
                                                        }
                                                    }}
                                                    renderInput={(params) => 
                                                    <TextField {...params}
                                                        label="Type of Employment Required" 
                                                        variant="standard"
                                                    />}
                                                />
                                            </Grid>

                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>                                                               
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    fullWidth
                                                    options={EmployeeSiteLocationListOptions as OptionType[]}
                                                    filterOptions={filterOptions}
                                                    getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                    value={EmployeeSiteLocationListOptions.find((option: OptionType) => option.value ===  selectedEmployeeSiteLocation) || null}
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            setselectedEmployeeSiteLocation(newValue.value);
                                                        } else {
                                                            setselectedEmployeeSiteLocation('');
                                                        }
                                                    }}
                                                    renderInput={(params) => 
                                                    <TextField {...params}
                                                        label="Employee Site Location" 
                                                        variant="standard"
                                                    />}
                                                />
                                                </Box>
                                            </Grid>
                                            </Grid>
                                            <Grid container spacing={3} display={'flex'} justifyContent={'space-evenly'}mb={3}>
                                            <Grid item lg={5} md={12} xs={12} >
                                                <Box>
                                                    <TextField
                                                        type='text'
                                                        variant="standard"
                                                        label='Division/Function'
                                                        name='Price'
                                                        fullWidth
                                                        error={DivisionAndFunctionNameError}
                                                        onChange={handleDivisionAndFunctionChange}
                                                        // value={formData.hirerequest_hiring_division_function || DivisionAndFunctionNameValue}
                                                        value={formDatahiring_division_function.hirerequest_hiring_division_function || DivisionAndFunctionNameValue}
                                                        helperText={DivisionAndFunctionNameErrorMessage}
                                                    /> 
                                                </Box>
                                            </Grid>
                                            <Grid item lg={5} md={12} xs={12} >
                                                <TextField
                                                    type='text'
                                                    variant="standard"
                                                    label='Job Code/ Title & Job'
                                                    name='Job Code/ Title & Job'
                                                    fullWidth
                                                    error={JobCodeAndTitleError}
                                                    onChange={handleJobCodeAndTitleChange}
                                                    // value={formData.hirerequest_hiring_jobcode_title || JobCodeAndTitleValue}
                                                    value={formDatajobcode_title.hirerequest_hiring_jobcode_title || JobCodeAndTitleValue}
                                                    helperText={JobCodeAndTitleErrorMessage}
                                                /> 
                                            </Grid>                   
                                            </Grid>
                                           
                                            <Grid container spacing={3} display={'flex'} justifyContent={'space-evenly'} >
                                            {/* //SOP-66 Testing feedback corrections [to change grid space of 'Other remarks' field ] */}
                                                {/*  <Grid item lg={5} md={12} xs={12} > */}
                                                        <Grid item lg={10.7} md={12} xs={12} >
                                            {/* //EOF SOP-66 Testing feedback corrections [to change grid space of 'Other remarks' field ] */}
                                                    <TextField
                                                        type='text'
                                                        variant="standard"
                                                        label='Other Remarks'
                                                        name='Other Remarks'
                                                        fullWidth
                                                        // multiline
                                                        onChange={handleOtherRemarksChange}
                                                        //SOP-66 Testing feedback corrections Modified By PCS
                                                        // value={OtherRemarksValue}
                                                        value={ formDatahiring_otherRemarks.hirerequestOtherRemarks || OtherRemarksValue}
                                                        helperText={OtherRemarksErrorMessage}
                                                        error={OtherRemarksError}
                                                    /> 
                                                </Grid> 
                                            
                                            {/* //SOP-66 Testing feedback corrections Commented by PCS [to hide hr.reqid] */}
                                                {/* <Grid item lg={5} md={12} xs={12} >
                                                    <TextField
                                                        type=''
                                                        variant="standard"
                                                        label='Hire Request Id'
                                                        name='Hire Request Id'
                                                        fullWidth
                                                        // multiline
                                                        onChange={handleHireRequestIdChange}
                                                        // value={formData.hirerequestid || HireRequestIdErrorIdValue}
                                                        value={formDatahirerequestid.hirerequestid || HireRequestIdErrorIdValue}
                                                        helperText={HireRequestIdErrorIdErrorMessage}
                                                        error={HireRequestIdErrorIdError}
                                                    /> 
                                                </Grid>  */}
                                            {/* //EOF SOP-66 Testing feedback corrections Commented by PCS [to hide hr.reqid]  */}
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        {/* End of Joining Details Accordian */}                        
                    </Box>
                    {/* (SOP-66) HR - Hire Request - Mapping with Employee Enrollment - Job Information -end*/}
                            

                    <Box mt={1}>
                        {/* Photo Upload */}

                        {/* //SOP-89 Modified By PCS [Accordion to be opened by default] */}
                        {/* <Accordion> */}
                            <Accordion defaultExpanded sx={{borderRadius:'30px'}}  > 
                        {/* //EOF SOP-89 Modified By PCS [Accordion to be opened by default] */}
                           <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <IoMdPhotos height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                                <Typography fontSize={'1rem'} ml={2}> Upload Employee Photo </Typography> 
                                </Box> 
                            </AccordionSummary>
                            <AccordionDetails sx={{marginBottom:5}}>
                            <Grid item xs={12} sm={12} md={12} display={'flex'} justifyContent={'center'}>
                                <Typography
                                letterSpacing="0.6px"
                                marginBottom={5}
                                // marginLeft={8}
                                fontSize="10px"
                                fontWeight={200}
                                color="#25425F"
                                // py={1.5}
                                >
                                {/* //SOP-82 Modified By PCS */}
                                {/* Image size must be less than 150KB */}
                                Image size must be less than 1.5Mb
                                {/* //EOF SOP-82 Modified By PCS */}
                                </Typography>
                                </Grid>
                                <Grid
                                    container
                                    // spacing={{ xs: 2, md: 2 }}
                                    // columns={{ xs: 4, sm: 8, md: 8, lg: 12 }}
                                    // rowSpacing={{ xs: 2, md: 1 }}
                                    // columnSpacing={{ xs: 2, md: 3 }}
                                >
                                    <Container  sx={{ mt: 1,}}>
                                        <Stack>
                                            <Box display={'flex'} justifyContent={'center'}  >
                                            <Box bgcolor={'whitesmoke'}
                                            //SOP-82 Modified By PCS
                                            //  width="110px" height="110px"
                                                width="200px" height="200px"
                                            
                                            >
                                                <label htmlFor="image-upload" >
                                                <img
                                                //SOP-82 Modified By PCS
                                                    // style={{marginTop: 0 }}
                                                       style={{marginTop: 0,
                                                        objectFit: 'contain',
                                                        width: '100%',
                                                        height:'100%' 
                                                         }}
                                                //EOF SOP-82 Modified By PCS
                                                    src={imageUrl}//binding image
                                                //SOP-82 Commented By PCS
                                                    // width="110px" 
                                                //EOF SOP-82 Commented By PCS   
                                                    alt=''
                                                />
                                                </label>
                                            </Box>
                                            </Box>

                                            <Box >
                                            <Box mt={5} display={'flex'} justifyContent={'flex-end'} >
                                                <Box className="information" style={{ display: 'flex', justifyContent: 'flex-end'}}>
                                                    <Box>
                                                    <label htmlFor="upload_image" style={{
                                                        color: "#ffffff",
                                                        width: '120px', 
                                                        height: '2.5rem', 
                                                        backgroundColor: '#25425F',
                                                        border: '1px solid transparent',
                                                        // margin:'5px',
                                                        borderRadius: '4px',
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        textAlign: 'center',
                                                        // lineHeight: '30px',
                                                        // marginLeft: '300px',
                                                        // marginTop:'10px' ,
                                                    }}>
                                                        Browse Image
                                                    </label>
                                                    <input
                                                        key={key}
                                                        id="upload_image"
                                                        accept="image/*"
                                                        hidden
                                                        type="file"
                                                        onChange={handleFileUpload}
                                                    />
                                                    </Box>
                                                </Box> 

                                                {EnableClearBtn? 
                                                    <Box>
                                                    <Button
                                                        onClick={clearImage}
                                                        sx={{
                                                        color: '#ffffff',
                                                        fontWeight: "595",
                                                        marginLeft: '20%',
                                                        // marginBottom: 1,
                                                        // marginRight: 50,
                                                        width: '120px',
                                                        height: '2.5rem',
                                                        backgroundColor: '#25425F',
                                                        '&:hover': {
                                                        backgroundColor: '#25425F',
                                                        },
                                                        }}
                                                    >
                                                        Clear Image
                                                    </Button>
                                                    </Box>                                                
                                                :null  }
                                            </Box>
                                            </Box>
                                        </Stack>
                                    </Container>
                            </Grid>
                            </AccordionDetails>
                    </Accordion>        
                    </Box>


                    {/* SOP-39 -TS - start 
                    Asset Management - User Rights- list MenuList  - drag and drop block - start*/} 
                            
                        <Box mt={1}>
                    {/* //SOP-89 Modified By PCS [Accordion to be opened by default] */}
                    {/* <Accordion> */}
                        <Accordion defaultExpanded sx={{borderRadius:'30px'}}  > 
                    {/* //EOF SOP-89 Modified By PCS [Accordion to be opened by default] */}
                        <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        >
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <MdContactPage height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                            <Typography fontSize={'1rem'} ml={2}> User Page Rights </Typography> 
                        </Box> 
                        </AccordionSummary>
                        <AccordionDetails sx={{marginBottom:5}}>
                        {/* --- Drag and Drop starts--- */}  
                        {/* <Grid
                        container
                        spacing={{ xs: 2, md: 2 }}
                        columns={{ xs: 4, sm: 8, md: 8, lg: 12 }}
                        rowSpacing={{ xs: 2, md: 1 }}
                        columnSpacing={{ xs: 2, md: 3 }}
                        >                                     
                        <Grid  item xs={12} sm={12} md={12}> */}
                        <Box>
                        <DragDropContext onDragEnd={onDragEnd}>
                        <Box style={{
                            display: 'flex', alignItems: "center",
                            // marginLeft: "300px"
                        }}>

                        <Box mt={1} mb={2} ml={1}
                            width={'16rem'} 
                            marginLeft={4}
                            boxShadow={'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px'} 
                            borderRadius={3}
                            border={'1px solid #757ce8'} // Border color
                            >

                            <Box style={{ marginRight: 20, height: '300px',overflow: 'auto',width:'250px' ,marginBottom:'10px'}}>
                                    <h2 style={{textAlign:'center'}}>Menus</h2>
                                <label ><input
                                    style={{fontSize:'6px'}}
                                    name='Select All'
                                    type="checkbox"
                                    onChange={handleSelectAll}
                                    /> Select All</label>
                                    <TextField
                                    variant="outlined"
                                    
                                    sx={{marginTop:'4px',marginBottom:'2px',width:'100%',padding:'1px'}}
                                    type="text"
                                    value={searchQuery}
                                    onChange={(e:any) => setSearchQuery(e.target.value)}
                                    placeholder="Search..."
                                    />
                                <DragGrid menus={filteredMenus} />
                                </Box>
                                </Box>
                                
                                <Box mt={1} mb={2} ml={1}
                                width={'16rem'} 
                                boxShadow={'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px'} 
                                borderRadius={3}
                                border={'1px solid #757ce8'} // Border color
                                >
                                <Box style={{ marginRight: 20, 
                                    height: '300px',width:'250px' ,overflow: 'auto',marginBottom:'10px' }}>
                                    <h2 style={{textAlign:'center'}}>Selected Menus</h2>
                                    <DropGrid selectedMenus={selectedMenus} />
                                </Box>
                               </Box>
                                </Box>
                            </DragDropContext>
                            </Box>
                        {/*                         
                        </Grid>
                        </Grid> */}
                        </AccordionDetails>
                    </Accordion>        
                    </Box>
                    {/* SOP-39 -TS - end */}


                   {/* SOP-91 Included By PCS [new accordion for including 'request an asset' in add employee grid] */}
                    <Box mt={1}>
                      <Accordion defaultExpanded sx={{borderRadius:'30px'}}  > 
                        <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        >
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <TiShoppingCart height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
                            <Typography fontSize={'1rem'} ml={2}> Request an Asset</Typography> 
                        </Box> 
                        </AccordionSummary>
                        <AccordionDetails sx={{marginBottom:5}}>
                         <Box>
                        <Grid container spacing={3} ml={4} display={'flex'} justifyContent={'left'}>
                            <Grid item lg={4} md={12} xs={12} >
                                <Box>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={CategoryandSubCategoryListOptions as OptionType[]}
                                        filterOptions={filterOptions}
                                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                                        value={CategoryandSubCategoryListOptions.find((option: OptionType) => option.value === SelectedSubCategoryId) || null}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setSelectedSubCategoryId(newValue.value);
                                                GetCategoryIdBySubCategoryId(newValue.value)
                                                setSubCategoryError(false);
                                                setSubCategoryErrorMessage("");
                                            } else {
                                                setSelectedSubCategoryId('');
                                            }
                                        }}
                                        sx={{ml: 1.5 }}
                                        renderInput={(params) => 
                                        <TextField {...params}
                                        error={SubCategoryError}
                                        helperText={SubCategoryErrorMessage}
                                        label="Asset" 
                                        variant="standard" />}
                                    />
                                </Box>
                            </Grid>
                            </Grid>
                            <Grid container spacing={3} display={'flex'} ml={5} mt={1.5} justifyContent={'left'} >
                            <Grid item lg={4} md={12} xs={12} >
                                <TextField
                                type='text'
                                id="outlined-basic" 
                                variant="outlined"
                                label='Additional Request or Comments'
                                name='assetrequest_comment'
                                fullWidth
                                multiline
                                rows={5}
                                error={AssetRequestCommentError}
                                onChange={handleAssetRequestCommentChange}
                                value={AssetRequestCommentValue}
                                helperText={AssetRequestCommentErrorMessage}
                                /> 
                            </Grid> 
                          </Grid>
                        </Box>
                        </AccordionDetails>
                    </Accordion>        
                    </Box>
                  {/* EOF SOP-91 Included By PCS [new accordion for including 'request an asset' in add employee grid] */}

                    </Box>
                <Box>                    
                    <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Button
                                        sx={{
                                            background: 'white',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding:{
                                                lg: "0 70px",
                                                md: "0 70",
                                                xs:'0 50px'
                    
                                            },
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                        }}

                                        onClick={()=>{
                                            navigate('/employee')
                                        }} 
                                    >
                                        Back
                                    </Button>

                                    <Button
                                        sx={{
                                            background: '#25425F',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding:{
                                                lg: "0 70px",
                                                md: "0 70",
                                                xs:'0 50px'
                                            },
                                            "&:hover":{
                                                backgroundColor:'#25425F',
                                                color:'white',
                                            },
                                            color: 'white',
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                        }}
                                        type='submit'
                                        onClick={(event)=>{
                                            CreateEmployee(event);
                                        }}
                                    >
                                        Save
                                    </Button>
                    </Box>
                </Box>
            </form>
            </Box>
        </MainContainerWrapper>
        
        </>
    )


}
export default AddEmployeePage;
// End of SOP-17
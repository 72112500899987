//(SOP-66) HR - Hire Request - Mapping with Employee Enrollment
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import { format } from 'date-fns'; 

const HRHireRequestPendingListPage =()=>{

    // Use navigate
    const navigate = useNavigate();

     // useState
     let  [order, setOrder] = React.useState<"desc" | "asc">("desc");
     let  [orderBy, setOrderBy] = React.useState("");
     let  [tableData, setTableData] = React.useState([]);
     const [page, setPage] = React.useState<number>(0);
     const [count, setCount] = useState<number>(0);
     const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
    
    // Head cell of Employee
    const EmployeeHeadCell = [
        
      //   {
      //    id: "hirerequestid",
      //    numeric: false,
      //    disablePadding: false,
      //    label: "hirerequestid",
      //    isSort: true,
      //    isBold: true,
      //  },
      {
        id: "departmentname",
        numeric: false,
        disablePadding: false,
        label: "Department",
        isSort: true,
        isBold: true,
      },
      {
        id: "employee_name",
        numeric: false,
        disablePadding: false,
        label: "Hiring Manager",
        isSort: true,
        isBold: true,
      },
      {
        id: "sys_hr_employment_type_name",
        numeric: false,
        disablePadding: false,
        label: "Employment Type",
        isSort: true,
        isBold: true,
      },





      //  {
      //   id: "hirerequest_hiring_jobcode_title",
      //   numeric: false,
      //   disablePadding: false,
      //   label: "Hiring Jobcode Title",
      //   isSort: true,
      //   isBold: true,
      // },
      {
        id: "hirerequest_total_open_position",
        numeric: false,
        disablePadding: false,
        label: "Open Position",
        isSort: true,
        isBold: true,
      },{
        id: "hirerequest_total_filled_position",
        numeric: false,
        disablePadding: false,
        label: "Filled Position",
        isSort: true,
        isBold: true,
      }
      ,{
        id: "hirerequest_total_pendig_position",
        numeric: false,
        disablePadding: false,
        label: "Pending Position",
        isSort: true,
        isBold: true,
      },
      {
        id: "sys_hr_hirerequest_status_name",
        numeric: false,
        disablePadding: false,
        label: "Status",
        isSort: true,
        isBold: true,
      },
       ];

    // onclick on HRHireRequestPendingListData it returning to Add patient
    const HandleRowClick = (rowData:any) => {
        // console.log("rowData",rowData)
        if (rowData && rowData.hirerequestid != null) {
          // Data to be passed to the previous page
          
        
          const HRHireRequestPendingListData = {
           
 
            hirerequest_hiring_jobcode_title:rowData.hirerequest_hiring_jobcode_title,
            hirerequest_total_open_position: rowData.hirerequest_total_open_position,
            hirerequest_total_filled_position: rowData.hirerequest_total_filled_position,
            hirerequest_total_pendig_position: rowData.hirerequest_total_pendig_position,
            hirerequestid: rowData.hirerequestid,
            hirerequest_employment_type_id: rowData.hirerequest_employment_type_id,
            hirerequest_hiring_division_function: rowData.hirerequest_hiring_division_function,
            departmentname: rowData.departmentname,
            // hirerequest_start_date: rowData.hirerequest_start_date,
            hirerequest_start_date: format(new Date(rowData.hirerequest_start_date), 'dd-MM-yyyy'), // Formatting the date
            
            //SOP-66 Testing feedback corrections Included By PCS [including 'OtherRemarks' ]
            hirerequestOtherRemarks:rowData.hirerequest_hiring_others,
            //EOF SOP-66 Testing feedback correction Included By PCS

          };
          // Store the data in localStorage
          localStorage.setItem('rowClickedData', JSON.stringify(HRHireRequestPendingListData));
          window.history.back(); // Navigate back to the previous page
        }
      };

      const [cells, setCells] = useState<any>(EmployeeHeadCell);


      //  Global data from session storage
      const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
      const GlobalData = JSON.parse(GlobalDataFromSession);
      //console.log("GlobalData",GlobalData)
      let CompanyId = GlobalData.GlobalCompanyId
      //console.log("CompanyId",CompanyId)


      //  To get categories list search API  
       const getHireRequestPendingSearchList = () => 
       {
     
     // Initializing Variable 
         let hirerequestid = null;
         let hirerequest_total_open_position = null;
         let hirerequest_total_filled_position = null;
         let hirerequest_total_pendig_position =null;
         let hirerequest_hiring_jobcode_title = null;
         let hirerequest_department_name = null;
         let hirerequest_hiring_manager_name = null;
         let hirerequest_employment_type_name = null;
         let hirerequest_status_name = null;

       for (const item of cells)
       {
          if (item.id === "hirerequestid") {
           hirerequestid = item.value;
         }
         if (item.id === "hirerequest_total_open_position") {
            hirerequest_total_open_position = item.value;
          }
          if (item.id === "hirerequest_total_filled_position") {
            hirerequest_total_filled_position = item.value;
          }
      
          if (item.id === "hirerequest_total_pendig_position") {
            hirerequest_total_pendig_position = item.value;
          }
          if (item.id === "hirerequest_hiring_jobcode_title") {
            hirerequest_hiring_jobcode_title = item.value;
          }
     
          if (item.id === "departmentname") {
            hirerequest_department_name = item.value;
         }
         if (item.id === "employee_name") {
          hirerequest_hiring_manager_name = item.value;
         }
         if (item.id === "sys_hr_employment_type_name") {
          hirerequest_employment_type_name = item.value;
         }
         if (item.id === "sys_hr_hirerequest_status_name") {
          hirerequest_status_name = item.value;
        }
       }

         commonService
         .postService(`${process.env.REACT_APP_BACKEND_URL}/employee/get_hr_pending_request_list`, {
          Searchhire_requestid:hirerequestid,
          Searchhirerequest_total_open_position:hirerequest_total_open_position,
          Searchhirerequest_total_filled_position:hirerequest_total_filled_position,
          Searchhirerequest_total_pendig_position:hirerequest_total_pendig_position,
          Searchhirerequest_hiring_jobcode_title: hirerequest_hiring_jobcode_title,
          Searchhirerequest_department_name: hirerequest_department_name,
          Searchhirerequest_hiring_manager_name: hirerequest_hiring_manager_name,
          Searchhirerequest_employment_type_name:hirerequest_employment_type_name,
          Searchhirerequest_status_name:hirerequest_status_name,
          SortColumnName: orderBy,
          SortColumnDirection: order,
          StartIndex: page + 1,
          PageSize: rowsPerPage,
          CompanyId:CompanyId
         })
         .then((res: any) => 
         {
            //console.log("res",res)
         const formattedTableData= res.data.data.map((item:any)=>{
         return {...item,
            id:item.hirerequestid
        }
           }) 
           //console.log("formattedTableData",formattedTableData)
           
         setTableData(formattedTableData);
         if(res.data.data[0]===undefined)
           {
             setCount(0);
           }
           else
           {
             setCount(res.data.data[0].total_count);
           }
         })
       .catch((error) => 
         {
           if (error.response.status === 404)
           {
             setTableData([]);
           }
          Failed(error.response.message);
         });
       };


        // Sort Handler
        const handleRequestSort = (event: any, property: any) =>
        {
        const isAsc = orderBy === property && order === "asc";
        order = isAsc ? "desc" : "asc";
        setOrder(order);
        orderBy = property;
        setOrderBy(orderBy);
        };

          const handleChangeList = () => {
            if (page === 0) getHireRequestPendingSearchList();
            else setPage(0);
          };

       

        // Action function of table icons
        const actionFunction = (id: string, actionText: string) => 
        {
            // if (actionText === "edit"){
            //     navigate(`/edit_employee/${id}`);
            // };
            // if (actionText === "delete") handleDeleteFleet(id)
             
        };
      
    
        // Search Handler of table
        const handleSearch = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const search = [...cells];
            const index = cells.findIndex((c: any) => c.id === e.target.name);
            // console.log("e.target.name",e.target.name)
            if (index !== -1) {
                search[index].value = e.target.value;
            }
            //console.log("search",search)
            setCells(search);
            handleChangePage(setPage,0);
            getHireRequestPendingSearchList()
        };
    
    
        // Pagination Handler of table
        const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        };
    
        // Rows Per page Handler
        const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
            ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        };
    
    
        useEffect(() => {
            getHireRequestPendingSearchList();

          }, [rowsPerPage, page, orderBy, order]); 
         


    return(
        <>
        <MainContainerWrapper
          headingText='HR Hire Pending Request'
        handleClick={() =>{
        }}
        
        >
            <Box>
                <Box bgcolor={'white'}>
                    <Box>
                    <EnhancedTable
                    headCells={EmployeeHeadCell}
                    rows={tableData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleRequestSort={handleRequestSort}
                    handleSearch={handleSearch}
                    order={order}
                    orderBy={orderBy}
                    actionFunction={actionFunction}
                    isView={false}
                    isDelete={true}
                    isEdit={true}
                    total={count}
                    isSearch={true}
                    HandleRowClick={HandleRowClick} // Pass the function here
                    title = {"Click to select the HR Hire Pending Request"}
                    />
                    </Box>
                </Box>
                <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Button
                                        sx={{
                                            background: 'white',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding:{
                                                lg: "0 70px",
                                                md: "0 70",
                                                xs:'0 50px'
                    
                                            },
                                            // color: {accent},
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                        }}

                                        onClick={()=>{
                                            // navigate('/home')
                                            window.history.back();
                                        }} 
                                    >
                                        Back
                                    </Button>
                    </Box>
            </Box>
        </MainContainerWrapper>
        </>
    )
}
export default  HRHireRequestPendingListPage;

// (SOP-66) HR - Hire Request - Mapping with Employee Enrollment by TS
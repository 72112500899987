
// commented by TS SOP-59 - TS HR - Employee hierarchy View - Review meeting feedback change  - start

// // Included by sanjana SOP-58
// Imports
// import { useState,useRef,useEffect } from 'react'; // Import useState hook
// import React from 'react';
// import {
//     Box,
//     Button,
//     TextField,
//     InputAdornment,
//     Avatar,
//     useMediaQuery,
//     Menu,
//     MenuItem,
//     Grid,
//     Dialog,
//     Accordion,
//     AccordionSummary,
//     AccordionDetails,
//     Divider,
// } from '@mui/material';
// import Typography from '@mui/material/Typography';
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
//   import { Success ,Failed } from '../../atic-common-helpers/helpers/toast.helper';
// import MainContainerWrapper from '../../atic-common-helpers/layout/wrappers/MainContainerWrapper';
// import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
// import { commonService } from '../../atic-common-helpers/helpers/common.service';
// import Swal from 'sweetalert2';
// import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
// import Logo from "../../asset/images/UserProfile.jpg"
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


// const HRHierarchyPage = () =>{
    // const HRHierarchyPage: React.FC = () => {
//     // Use navigate
//     const navigate = useNavigate();


//     // Userstate
//     const [HierarchyEmployeeData,setHierarchyEmployeeData] = useState([]);
//     const [EmployeeProfile, setEmployeeProfile] = useState('');
//     const [EmployeeName, setEmployeeName] = useState('');
//     const [EmployeeDesignation, setEmployeeDesignation] = useState('');
//     const [EmployeeMobileNo, setEmployeeMobileNo] = useState('');
//     const [EmployeeEmail, setEmployeeEmail] = useState('');
//     const [EmployeeDepartMent, setEmployeeDepartMent] = useState('');
//     const [EmployeeWorkLocation, setEmployeeWorkLocation] = useState('');
//     const [ EmployeeData, setEmployeeData] = useState([]);
//     const [EmployeeDetailsEnable, setEmployeeDetailsEnable] = useState(false);
//     const [expandedAccordion, setExpandedAccordion] = useState<string | false>(false);


//     // Global data from session storage
//     const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
//     const GlobalData = JSON.parse(GlobalDataFromSession);
//     let CompanyId = GlobalData.GlobalCompanyId


//     // To get Hierarchy details as per the companyid
//     const GetEmployeeListForHierarchy = () => {
//         commonService
//             .postService(`${process.env.REACT_APP_BACKEND_URL}/hrhierarchy/get_hr_hierarchy_list_of_employee`, {
//                 CompanyId: CompanyId,
//             })
//             .then((res) => {
//                 // const hierarchyEmployeeData = res.data.data;
//                 const hierarchyEmployeeData = res.data.data;
//                 // const hierarchyEmployeeData = res.data.data[0];
//     console.log("hierarchyEmployeeData",hierarchyEmployeeData)



                
//                 // Filter out duplicate employee_id values
//                 const FormatedEmployees = hierarchyEmployeeData.filter((employee:any, index:any, self:any) =>
//                     index === self.findIndex((t:any) => (
//                         t.employee_id === employee.employee_id
//                     ))
//                 );
    
//                 setHierarchyEmployeeData(FormatedEmployees);
//                 console.log("Formatted Hierarchy Employee Data", FormatedEmployees);
//             })
//             .catch((error) => {
//                 // console.log("error1",error)
//                 Failed(error.response.message);
//             });
//     };
    

//         // To get Employee details as per the id
//         const GetEmployeeListById =(EmployeeId:any)=>{
//             console.log("EmployeeId",EmployeeId)
//             commonService
//                 .postService(`${process.env.REACT_APP_BACKEND_URL}/employee/getEmployeeById`, {
//                 EmployeeId:EmployeeId,
//                 CompanyId:CompanyId
//                 })
//                 .then((res: any) =>
//                 {
//                     // console.log("response edit..",res.data)
//                 const EmployeeData = res.data.data[0]
//                 console.log("EmployeeData",EmployeeData)
//                 setEmployeeProfile(EmployeeData.photo_url);
//                 setEmployeeName(EmployeeData.employee_name);
//                 setEmployeeDesignation(EmployeeData.designationname);
//                 setEmployeeMobileNo(EmployeeData.employee_mobile);
//                 setEmployeeEmail(EmployeeData.employee_email);
//                 setEmployeeDepartMent(EmployeeData.departmentname);
//                 setEmployeeWorkLocation(EmployeeData.work_location)

//                 }).catch((error) =>
//                 {
//                 console.log("error",error)
//                 Failed(error.response.message);
//                 });
//         }

//         // To get Employee details as per the id
//         const GetEmployeeListByReportedToWhomId = (EmployeeId: any) => {
//             commonService
//                 .postService(`${process.env.REACT_APP_BACKEND_URL}/hrhierarchy/get_employee_list_by_reported_to_whom_id`, {
//                     CompanyId: CompanyId,
//                     ReportedToWhomId: EmployeeId
//                 })
//                 .then((res: any) => {

//                     console.log("resToWhomId",res)
//                     const ResEmployeeData = res.data.data;
        
//                     // Filter out data where employee_id is the same as EmployeeId
//                     const FormatedEmployeeData = ResEmployeeData.filter((employee: any) => employee.employee_id !== EmployeeId);
        
//                     console.log("Unique Employee Data", FormatedEmployeeData);
//                     setEmployeeData(FormatedEmployeeData);
//                 })
//                 .catch((error) => {
//                     // console.log("Error", error);
//                     Failed(error.response.message);
//                 });
//         };
        

//     // UseEffect declaration
//     useEffect(() => {
//         GetEmployeeListForHierarchy();
//       }, []);


//     return(
//         <>
//         <MainContainerWrapper headingText='Hr Hierarchy'>
//             <Box bgcolor={'white'} borderRadius={5}  height={500}>
//                 <Grid container >
//                     <Grid item lg={4} md={12} xs={12} >
//                         <Box p={3} sx={{overflowY:'scroll'}} height={450} >
//                         {HierarchyEmployeeData.map((HierarchyEmployeeData) => {
//                             return (
//                                 <Box>
//                                     <Accordion
//                                         expanded={expandedAccordion === HierarchyEmployeeData["employee_id"]}
//                                         onChange={() => setExpandedAccordion(expandedAccordion === HierarchyEmployeeData["employee_id"] ? false : HierarchyEmployeeData["employee_id"])}
//                                     >
//                                         <AccordionSummary
//                                             expandIcon={<ExpandMoreIcon />}
//                                             onClick={() => {
//                                                 GetEmployeeListById(HierarchyEmployeeData["employee_id"]);
//                                                 GetEmployeeListByReportedToWhomId(HierarchyEmployeeData["employee_id"]);
//                                                 setEmployeeDetailsEnable(true);
//                                             }}
//                                             sx={{ cursor: 'pointer', borderRadius: 0 }}
//                                         >
//                                             <Box display={'flex'} justifyContent={'flex-start'}  >
//                                                 <img
//                                                     style={{
//                                                         borderRadius: "50%",
//                                                         height: "50px",
//                                                         width: "50px",
//                                                         textAlign: "center",
//                                                         marginTop: '1rem',
//                                                         marginRight: '1.5rem'
//                                                     }}
//                                                     src={HierarchyEmployeeData["photo_url"] || Logo}
//                                                     alt="Employee Profile"
//                                                 />
//                                                 <Box>
//                                                     <Typography sx={{ fontSize: '1rem', color: 'black', mt: 1, marginRight: '1.5rem' }}>
//                                                         {HierarchyEmployeeData["employee_name"]}
//                                                     </Typography>
//                                                     <Typography sx={{ fontSize: '1rem', mt: 1, marginRight: '1.5rem' }}>
//                                                         {HierarchyEmployeeData["designationname"]} ({HierarchyEmployeeData["employees_count"]})
//                                                     </Typography>
//                                                 </Box>
//                                             </Box>
//                                         </AccordionSummary>
//                                         <AccordionDetails>


//                                             {/* initally 0 position only load dont repeat same */}
//                                             {/*SOP-59 -TS */}
//                                             {/* <Box bgcolor={'#D8F3FA'} borderRadius={3} display={'flex'} justifyContent={'center'} > */}
//                                             <Box  borderRadius={3} display={'flex'} justifyContent={'Left'} >
   
//                                             <Box >
//                                                     {EmployeeData.map((EmployeeData) => {
//                                                         return (
//                                                             <Box
//                                                                 p={1}
//                                                                 display={'flex'}
//                                                                 justifyContent={'flex-start'}
//                                                                 alignItems={'center'}
//                                                                 onClick={() => GetEmployeeListById(EmployeeData["employee_id"])}
//                                                                 sx={{ cursor: 'pointer' }}
//                                                             >
//                                                                 <img
//                                                                     style={{
//                                                                         borderRadius: "50%",
//                                                                         height: "50px",
//                                                                         width: "50px",
//                                                                         textAlign: "center",
//                                                                         marginTop: '1rem',
//                                                                         marginRight: '1.5rem'
//                                                                     }}
//                                                                     src={EmployeeData["photo_url"] || Logo}
//                                                                     alt="Employee Profile"
//                                                                 />
//                                                                 <Box>
//                                                                     <Typography sx={{ fontSize: '1rem', color: 'black', mt: 1, marginRight: '1.5rem' }}>
//                                                                         {EmployeeData["employee_name"]}
//                                                                     </Typography>
//                                                                     <Typography sx={{ fontSize: '1rem', mt: 1, marginRight: '1.5rem' }}>
//                                                                         {EmployeeData["designationname"]}
//                                                                     </Typography>
//                                                                 </Box>
//                                                             </Box>
//                                                         )
//                                                     })}
//                                                 </Box>

//                                             </Box>
//                                         </AccordionDetails>
//                                     </Accordion>
//                                 </Box>


//                             );
//                         })}
//                         </Box>

//                     </Grid>

//                     <Grid item lg={8} md={12} xs={12}  sx={{
//                         borderTop:{lg:'0', md:'0',xs:'1px solid grey'}, borderLeft:{lg:'1px solid grey', md:'1px solid grey',xs:'0'}
//                     }} display={'flex'} justifyContent={'center'} >
//                         {EmployeeDetailsEnable ?
//                             <Box mb={2} >

//                                 <Grid container p={1} spacing={3} borderRadius={5} mt={3}  boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}>
//                                     <Grid item lg={5} mb={5} >
//                                         <img
//                                             style={{
//                                                 height: "200px",
//                                                 width: "200px",
//                                                 textAlign: "center",
//                                                 marginTop:'1rem',
//                                                 marginRight:'1.5rem',
//                                                 // border:'1px solid grey'
//                                             }}
//                                             src={EmployeeProfile || Logo}
//                                             alt="Employee Profile"
//                                             />
//                                     </Grid>

//                                     <Grid item lg={7} mb={5} >
//                                         <Box>
//                                         <Typography sx={{ fontSize: '2rem',color:'#4169e1',mt:1,
//                                         marginRight:'1.5rem', width:350}} >
//                                             <b> {EmployeeName}</b>
//                                         </Typography>

//                                         <Typography sx={{ fontSize: '1.5rem',mt:1,color:'black',
//                                         marginRight:'1.5rem', width:350}} >
//                                         {EmployeeDesignation}
//                                         </Typography>
//                                         </Box>

//                                         <Divider sx={{border:'1px solid grey'}} />

//                                         <Box mt={3} >

//                                         <Typography sx={{ fontSize: '1rem',mt:1,color:'black',
//                                         marginRight:'1.5rem', width:350}} >
//                                         <b style={{color:'#4169e1'}} >Mobile </b> {EmployeeMobileNo}
//                                         </Typography>

//                                         <Typography sx={{ fontSize: '1rem',mt:1,color:'black',
//                                         marginRight:'1.5rem', width:350}} >
//                                         <b style={{color:'#4169e1'}} >Email </b> {EmployeeEmail}
//                                         </Typography>

//                                         <Typography sx={{ fontSize: '1rem',mt:1,color:'black',
//                                         marginRight:'1.5rem', width:350}} >
//                                         <b style={{color:'#4169e1'}} >Department </b> {EmployeeDepartMent}
//                                         </Typography>

//                                         <Typography sx={{ fontSize: '1rem',mt:1,color:'black',
//                                         marginRight:'1.5rem', width:350}} >
//                                         <b style={{color:'#4169e1'}} >Work Location </b> {EmployeeWorkLocation}
//                                         </Typography>


//                                         </Box>

//                                     </Grid>

//                                 </Grid>
                                
//                             </Box>
//                         :null}

                        
//                     </Grid>
//                 </Grid>

//             </Box>

//             <Box mt={5} display={'flex'} justifyContent={'flex-start'}>
//           <Button
//               sx={{
//                   background: 'white',
//                   border: 'none',
//                   borderRadius: '5px',
//                   padding:{
//                       lg: "0 70px",
//                       md: "0 70",
//                       xs:'0 50px'

//                   },
//                   // color: {accent},
//                   height: "3rem",
//                   boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
//               }}

//               onClick={()=>{
//                 navigate('/home')
//               }}
//           >
//               Back
//           </Button>

//           </Box>

//         </MainContainerWrapper>
//         </>
//     )
// }

// export default HRHierarchyPage;

// // End of SOP-58

// commented by TS SOP-59 - TS HR - Employee hierarchy View - Review meeting feedback change  - end





// SOP-59 - TS HR - Employee hierarchy View - Review meeting feedback change  - start
// Imports
import React, { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    Typography,
    Paper,
    Divider,
    Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Failed } from '../../atic-common-helpers/helpers/toast.helper';
import MainContainerWrapper from '../../atic-common-helpers/layout/wrappers/MainContainerWrapper';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import Logo from '../../asset/images/UserProfile.jpg';

// defining type

type Employee = {
    employee_id: number;
    photo_url: string;
    employee_name: string;
    designationname: string;
    employees_count: number | null;
    employees: HierarchyEmployee[];
    departmentname: string;
    employee_email: string;
    employee_mobile: string;
    work_location: string;
};

type HierarchyEmployee = {
    employee_id: number;
    photo_url: string;
    employee_name: string;
    designationname: string;
    employees_count: number | null;
    employees: HierarchyEmployee[];
    departmentname: string;
    employee_email: string;
    employee_mobile: string;
    work_location: string;
};

const HRHierarchyPage: React.FC = () => {
    const navigate = useNavigate();
    const [hierarchyEmployeeData, setHierarchyEmployeeData] = useState<HierarchyEmployee[]>([]);
    const [selectedEmployees, setSelectedEmployees] = useState<HierarchyEmployee[]>([]);
    const [ReportingToWhomEmployee, setReportingToWhomEmployee] = useState<HierarchyEmployee[]>([]);
    const [countEmployee, setCountEmployee] = useState('0');
    const [selectedDetailEmployee, setSelectedDetailEmployee] = useState<HierarchyEmployee | null>(null);

    console.log("hierarchyEmployeeData", hierarchyEmployeeData);
    console.log("selectedEmployees", selectedEmployees);
    console.log("ReportingToWhomEmployee", ReportingToWhomEmployee);

    // GlobalData
    const GlobalDataFromSession: any = sessionStorage.getItem('GlobalVariable');
    const GlobalData = JSON.parse(GlobalDataFromSession);
    let companyId = GlobalData?.GlobalCompanyId;

    // Employee TopPosition
    const getEmployeeTopPosition = () => {
        commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/hrhierarchy/get_hr_hierarchy_employee_top_position`, {
                CompanyId: companyId,
            })
            .then((res: any) => {
                const employeeData = res.data.data;
                const hierarchyEmployeeData = employeeData.map((employee: Employee) => ({
                    ...employee,
                    employees: [],
                })) as HierarchyEmployee[];
                setHierarchyEmployeeData(hierarchyEmployeeData);
            })
            .catch((error) => {
                Failed(error.response?.message);
            });
    };

    // EmployeeListBy ReportedToWhomId
    const getEmployeeListByReportedToWhomId = (employeeId: number) => {
        commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/hrhierarchy/get_employee_list_by_reported_to_whom_id`, {
                CompanyId: companyId,
                ReportedToWhomId: employeeId,
            })
            .then((res: any) => {
                const resEmployeeData = res.data.data;
                const formattedEmployeeData = resEmployeeData.filter((employee: any) => employee.employee_id !== employeeId);
                const hierarchyEmployeeData = formattedEmployeeData.map((employee: Employee) => ({
                    ...employee,
                    employees: [],
                })) as HierarchyEmployee[];

                setReportingToWhomEmployee(hierarchyEmployeeData);
            })
            .catch((error) => {
                Failed(error.response?.message);
            });
    };

    // useEffect to load top position employees
    useEffect(() => {
        if (companyId) {
            getEmployeeTopPosition();
        }
    }, [companyId]);

    const handleEmployeeClick = (employee: HierarchyEmployee) => {
        console.log('Clicked Employee:', employee);
        console.log('First Record in hierarchyEmployeeData:', hierarchyEmployeeData[0]);

        const isSelected = selectedEmployees.some(e => e.employee_id === employee.employee_id);

        if (employee.employees_count === null) {
            console.log(' no reporting to whom, showing details only');
            setSelectedDetailEmployee(employee);
        } else {
            setSelectedDetailEmployee(null);
            if (!isSelected) {
                if (hierarchyEmployeeData[0] && hierarchyEmployeeData[0].employee_id === employee.employee_id) {
                    console.log('clear selectedEmployees and setting to clicked employee');
                    setSelectedEmployees([employee]);
                } else {
                    console.log('add clicked employee to selectedEmployees');
                    setSelectedEmployees([...selectedEmployees, employee]);
                }
                getEmployeeListByReportedToWhomId(employee.employee_id);
            }
            else {
                console.log('Reloading reportingtowhom data for selected employee');
               
                //  index of the clicked employee
                const index = selectedEmployees.findIndex(e => e.employee_id === employee.employee_id);
              
                //  new array  only the employeesthe clicked employee
                const updatedSelectedEmployees = selectedEmployees.slice(0, index + 1);
                setSelectedEmployees(updatedSelectedEmployees);
               
                // reload reportingtowhom data
                getEmployeeListByReportedToWhomId(employee.employee_id);
            }
        }
    };

    // rendering employee for reporting to whom employee
    const renderEmployeeList = (employees: HierarchyEmployee[]) => {
        return employees.map((employee) => (
            <Box key={employee.employee_id} onClick={() => handleEmployeeClick(employee)} sx={{ cursor: 'pointer', mb: 2 }}>
                <Paper sx={{ p: 2, marginLeft: '40px' }}>
                    <Box display="flex" alignItems="center">
                        <img
                            style={{
                                borderRadius: '50%',
                                height: '50px',
                                width: '50px',
                                textAlign: 'center',
                                marginRight: '1.5rem',
                            }}
                            src={employee.photo_url || Logo}
                            alt="Employee Profile"
                        />
                        <Box>
                            <Typography sx={{ fontSize: '1.25rem', color: 'black', fontWeight: '600' }}>
                                {employee.employee_name}
                            </Typography>
                            <Typography
                             //SOP-81 Modified By PCS [for designation alignment font size reduced]
                               sx={{ 
                                // fontSize: '1.10rem',
                                  fontSize: '0.9rem', 
                             //EOF SOP-81 Modified By PCS [for designation alignment font size reduced]
                                 color: '#0B76C3', fontWeight: '600' }}>
                                {/* //SOP-81 Modified By PCS */}
                                  {/* {employee.designationname} - ({employee.employees_count || countEmployee}) */}
                                  {employee.designationname}  ({employee.employees_count || countEmployee})
                                {/* //EOF SOP-81 Modified By PCS */}
                            </Typography>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        ));
    };


// gets or sets the length of the array
    const employeeToDisplay = selectedDetailEmployee || (selectedEmployees.length > 0 ? selectedEmployees[selectedEmployees.length - 1] : null);
    console.log("employeeToDisplay", employeeToDisplay)
    
    return (
        <MainContainerWrapper
        //SOP-81 Modified By PCS [renamed]
        //  headingText="HR Hierarchy"
         headingText="ORG CHAIN"
         //EOF SOP-81 Modified By PCS
         
         >
            <Box bgcolor={'white'} borderRadius={5} height={750}>
                <Grid container>
                    <Grid item lg={4} md={12} xs={12}>
                        <Box p={3} sx={{ overflowY: 'scroll' ,cursor: 'pointer',}} height={710}>
                            {hierarchyEmployeeData.length > 0 ? (
                                <>
                                    {/* --selected emp - start */}
                                    {selectedEmployees.length > 0 && selectedEmployees.map(employee => (
                                        <Paper key={employee.employee_id} sx={{ mb: 2, p: 2, backgroundColor: '#E7E6E6' }}
                                            onClick={() => handleEmployeeClick(employee)}>
                                            <Box display="flex" alignItems="center">
                                                <img
                                                    style={{
                                                        borderRadius: '50%',
                                                        height: '50px',
                                                        width: '50px',
                                                        textAlign: 'center',
                                                        marginRight: '1.5rem',
                                                    }}
                                                    src={employee.photo_url || Logo}
                                                    alt="Employee Profile"
                                            />
                                                <Box>
                                                    <Typography sx={{ fontSize: '1.25rem', color: 'black', fontWeight: '600' }}>
                                                        {employee.employee_name}
                                                    </Typography>
                                                    <Typography 
                                                    sx={{ 
                                                        //SOP-81 Modified By PCS [for designation alignment]
                                                        // fontSize: '1.10rem', 
                                                           fontSize: '0.9rem', 
                                                        //EOF SOP-81 Modified By PCS
                                                        color: '#0B76C3', fontWeight: '600' }}
                                                    >
                                                        {/* //SOP-81 Moifed By PCS  */}
                                                        {/* {employee.designationname} - ({employee.employees_count || countEmployee}) */}
                                                        {employee.designationname}  ({employee.employees_count || countEmployee})
                                                        {/* //EOF SOP-81 Moifed By PCS */}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Paper>
                                    ))}
                                     {/* --selected emp - end */}
                                    <Divider />
                                    {selectedEmployees.length > 0 && ReportingToWhomEmployee.length === 0 && (
                                        <Typography mt={2} sx={{ textAlign: 'center' }}>
                                            No employees found
                                        </Typography>
                                    )}
                                    {selectedEmployees.length > 0 && renderEmployeeList(ReportingToWhomEmployee)}
                                    {selectedEmployees.length === 0 && renderEmployeeList(hierarchyEmployeeData)}
                                </>
                            ) : (
                                <Typography>No data available</Typography>
                            )}
                        </Box>
                    </Grid>

                    {/* Card for selected employee details - start */}
                    <Grid
                        item
                        lg={8}
                        md={12}
                        xs={12}
                        sx={{
                            borderTop: { lg: '0', md: '0', xs: '1px solid grey' },
                            borderLeft: { lg: '1px solid grey', md: '1px solid grey', xs: '0' },
                        }}
                        display={'flex'}
                        justifyContent={'center'}
                    >
                        {employeeToDisplay ? (
                            <Box mb={4} width={750} height={700}>
                                <Grid
                                    container
                                    p={1}
                                    spacing={3}
                                    borderRadius={5}
                                    mt={13}
                                    boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
                                    width={800}
                                    height={550}
                                >
                                    <Grid item lg={5} mb={5}>
                                        <img
                                            style={{
                                                height: '200px',
                                                width: '200px',
                                                textAlign: 'center',
                                                marginTop: '1rem',
                                                marginRight: '1.5rem',
                                            }}
                                            src={employeeToDisplay.photo_url || Logo}
                                            alt="Employee Profile"
                                        />
                                    </Grid>

                                    <Grid item lg={7}>
                                        <Box p={1}>
                                            <Typography sx={{ fontSize: '1.10rem', color: 'black', fontWeight: '600' }}>
                                                Name
                                            </Typography>
                                            <Typography sx={{ fontSize: '1.25rem', fontWeight: '600', color: '#0B76C3' }}>
                                                {employeeToDisplay.employee_name}
                                            </Typography>
                                        </Box>
                                        <Box p={1}>
                                            <Typography sx={{ fontSize: '1.10rem', color: 'black', fontWeight: '600' }}>
                                                Designation
                                            </Typography>
                                            <Typography sx={{ fontSize: '1.25rem', fontWeight: '600', color: '#0B76C3' }}>
                                                {employeeToDisplay.designationname}
                                            </Typography>
                                        </Box>
                                        <Box p={1}>
                                            <Typography sx={{ fontSize: '1.10rem', color: 'black', fontWeight: '600' }}>
                                                Department
                                            </Typography>
                                            <Typography sx={{ fontSize: '1.25rem', fontWeight: '600', color: '#0B76C3' }}>
                                                {employeeToDisplay.departmentname}
                                            </Typography>
                                        </Box>
                                        <Box p={1}>
                                            <Typography sx={{ fontSize: '1.10rem', color: 'black', fontWeight: '600' }}>
                                                Email
                                            </Typography>
                                            <Typography sx={{ fontSize: '1.25rem', fontWeight: '600', color: '#0B76C3' }}>
                                                {employeeToDisplay.employee_email}
                                            </Typography>
                                        </Box>
                                        <Box p={1}>
                                            <Typography sx={{ fontSize: '1.10rem', color: 'black', fontWeight: '600' }}>
                                                Mobile
                                            </Typography>
                                            <Typography sx={{ fontSize: '1.25rem', fontWeight: '600', color: '#0B76C3' }}>
                                                {employeeToDisplay.employee_mobile}
                                            </Typography>
                                        </Box>
                                        <Box p={1}>
                                            <Typography sx={{ fontSize: '1.10rem', color: 'black', fontWeight: '600' }}>
                                                Work Location
                                            </Typography>
                                            <Typography sx={{ fontSize: '1.25rem', fontWeight: '600', color: '#0B76C3' }}>
                                                {employeeToDisplay.work_location}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        ) : (
                            <Grid container justifyContent="center" alignItems="center" height="100%">
                                <Typography variant="h5" sx={{ color: 'gray' }}>
                                    View details
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                    {/* Card for selected employee details - end */}
                </Grid>
            </Box>

            {/* (SOP-66) HR - Hire Request - Mapping with Employee Enrollment -(Back button) included by TS */}
            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Button
                    sx={{
                        background: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        padding:{
                            lg: "0 70px",
                            md: "0 70",
                            xs:'0 50px'
                        },
                        height: "3rem",
                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                    }}
                       onClick={() => {
                            //   window.history.back();
                            navigate('/HRDashBoard')
                    }} 
                >
                    Back
                </Button>
            </Box>
            {/*(SOP-66) HR - Hire Request - Mapping with Employee Enrollment - included by TS */}
        </MainContainerWrapper>
    );
};

export default HRHierarchyPage;


// SOP-59 - TS HR - Employee hierarchy View - Review meeting feedback change  - end
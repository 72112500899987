//  SOP-76 feedback points L2 (7) Included BY PCS edit page for Hire requests
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    Grid,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import dayjs from 'dayjs';

const EditHireRequestApprovalsPage=
({
    handleEditClose,
    getMyPendingApprovalsList,
    getMyApprovedList,
    RequestId
}: {
    handleEditClose: (event: React.MouseEvent) => void,
    getMyPendingApprovalsList: (event: React.MouseEvent) => void,
    getMyApprovedList: (event: React.MouseEvent) => void,
    RequestId:any
})=>{

    // useState
  
    const [HireRequestApproveRemarksError, setHireRequestApproveRemarksError]=useState(false)
    const [HireRequestApproveRemarksValue, setHireRequestApproveRemarksValue]=useState('')
    const [HireRequestApproveRemarksErrorMessage, setHireRequestApproveRemarksErrorMessage]=useState('')
    const [ApproveOptions, setApproveOptions] = useState<OptionType[]>([]);
    const [selectedApproveId, setSelectedApproveId] = useState<any | null>('');
    const [HireRequestApproveError, setHireRequestApproveError]=useState(false)
    const [HireRequestApproveErrorMessage, setHireRequestApproveErrorMessage]=useState('')
    const [HireRequestFilledPositionCount, setHireRequestFilledPositionCount]= useState(0)

     //console.log('Hire RequestId',RequestId)


    type OptionType = {
        name: string;
        value: string;
    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

    //  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    //console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId
    const UserId = GlobalData.GlobalUserId;
    let EmployeeId = GlobalData.GlobalEmployeeId

    //  Handle Approve while typing Approve and validations
    const handleApproveRemarksChange = (event:any) => {
        setHireRequestApproveRemarksValue(event.target.value);
        const trimmedHireRequestApproveRemarksValue =  event.target.value
        if( trimmedHireRequestApproveRemarksValue.length > 255 ){
            setHireRequestApproveRemarksError(false);
            setHireRequestApproveRemarksErrorMessage("");
        }
    };

    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleEditClose && typeof handleEditClose === 'function') {
            handleEditClose(event);
        }
    };

    // to render getMyPendingApprovalsList function after approving request
    const handlegetMyPendingApprovalsList= (event: React.MouseEvent) => {
        if (getMyPendingApprovalsList && typeof getMyPendingApprovalsList === 'function') {
            getMyPendingApprovalsList(event);
        }
    };

    // to render getMyApprovedList function after approving request
    const handlegetMyApprovedList= (event: React.MouseEvent) => {
        if (getMyApprovedList && typeof getMyApprovedList === 'function') {
            getMyApprovedList(event);
        }
    };

    //Drop down to fill Approve Status..
    const GetApproveList = async () => { 
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/myapprovals/get_approve_status_list`);
            //console.log("Approve" ,res.data.data);
            let formattedApprove = res.data.data.map((item:any) => {
            const { sys_approve_status_id, sys_approve_status_name } = item;
            return {
                name: sys_approve_status_name,
                value: sys_approve_status_id,
            };
            });
            setApproveOptions(formattedApprove)
        } catch (err) {
            console.log( "co",err);
        }
        };


    // To get Approved Approval details as per the id 
    const GetApprovedApprovalListById =()=>{

        commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/myapprovals/get_hire_request_approved_list_by_id`, {
            CompanyId: CompanyId,
            RequestId: RequestId,
            })
            .then((res: any) => 
            { 
            // console.log("res from db ..",res)
            const ApprovedAssetData = res.data.data[0]
            
            setHireRequestApproveRemarksValue(ApprovedAssetData.request_approve_remarks)
            let formattedApproveStatus = res.data.data.map((item:any) => {
                const { sys_approve_status_id, sys_approve_status_name } = item;
                return {
                    name: sys_approve_status_name,
                    value: sys_approve_status_id,
                };
                });
                setSelectedApproveId(formattedApproveStatus[0].value)            
            }).catch((error) =>  
            { 
            Failed(error.response.message);
            });
    }
    

    // To approve approval

    // Define a type for errors
        type Errors = {
        approveSts?:boolean;
        apprveRemarks?:boolean;
        };

    const EditHireRequest =(event:any)=>{

        event.preventDefault()
        let errors: Errors = {};

        if (selectedApproveId === '' || selectedApproveId === null || selectedApproveId === undefined) {
            errors.approveSts = true;
            setHireRequestApproveErrorMessage("Approve Status is required")
            setHireRequestApproveError(true)
        }
        
        const trimmedHireRequestApproveRemarksValue = HireRequestApproveRemarksValue.trim()
        if( trimmedHireRequestApproveRemarksValue.length >255 ){
            setHireRequestApproveRemarksError(true);
            setHireRequestApproveRemarksErrorMessage("Max 255 characters");
        }
        else {
            setHireRequestApproveRemarksError(false);
            setHireRequestApproveRemarksErrorMessage("");
        }

        let ApproveShortName;

        if(selectedApproveId === 2){
            ApproveShortName= 'A'
        }

        if(selectedApproveId === 3){
            ApproveShortName= 'R'
        }

        if(selectedApproveId === 4){
            ApproveShortName= 'H'
        }

   //to set RequestStatusIdValue 

        let RequestStatusIdValue;
        if(selectedApproveId === 2){
             RequestStatusIdValue= 3 //In progress
                }
        //SOP-76 Testing feedback corrections Modified By PCS [Hold request's RequestStatusIdValue is modified to 5]
            // if(selectedApproveId === 4){
            //     RequestStatusIdValue= 3 
            // }

            if(selectedApproveId === 4){
                RequestStatusIdValue= 5 //hold
            }  
        //EOF SOP-76 Testing feedback corrections Modified By PCS [Hold request's RequestStatusIdValue is modified to 5]


            if(selectedApproveId === 3){
                RequestStatusIdValue= 2  //closed
            }


        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setHireRequestApproveError(errors.approveSts || false);
            setHireRequestApproveRemarksError(errors.apprveRemarks || false);
            return;
        }

        const ApproveApprovalPayload={
            SaveUpdateKey: "update",
            CompanyId: CompanyId,
            RequestId:RequestId,
            RequestApproveStatus: ApproveShortName, 
            RequestApproveByEmployeeId:EmployeeId,
            RequestApproveRemarks: HireRequestApproveRemarksValue,
            RequestStatusId:RequestStatusIdValue
                                    }

        // console.log("EditApproveApprovalPayload",ApproveApprovalPayload)

        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/myapprovals/my_approval_hire_request_update`, ApproveApprovalPayload)
        .then((res) => {
          if (res.status === 200) {
            //   console.log('res',res.data.data)
              Success("Hire Request Approve Status Updated Successfully");
              handleClose(event);
              handlegetMyPendingApprovalsList(event);
              handlegetMyApprovedList(event);
          }
          }).catch((error) => {
              console.error("Error:", error);
          });
    }
 

    useEffect(() => {
        GetApproveList();
        GetApprovedApprovalListById();
    }, []); 


    return(
        <Box>
            <Box bgcolor={'white'} borderRadius={3}
            sx={{
                width:{
                    lg:600,
                    md:600,
                    xs:350
            }}} >
                <Box>
                <Box p={3}>
                        <Typography variant='h1'
                        sx={{
                            fontSize:{
                                lg:'2rem',
                                md:'2rem',
                                xs:'1.5rem'
                            }
                        }}
                        >
                      Edit Hire Request 
                        </Typography>
                    </Box>
                    
                    <form noValidate>
                        <Box p={3}>
                            <Grid container >
                                <Grid item lg={12} md={12} xs={12} >
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={ApproveOptions as OptionType[]}
                                        filterOptions={filterOptions}
                                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                                        value={ApproveOptions.find((option: OptionType) => option.value === selectedApproveId) || null}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setSelectedApproveId(newValue.value);
                                                setHireRequestApproveError(false);
                                                setHireRequestApproveErrorMessage("");
                                            } else {
                                                setSelectedApproveId('');
                                                setHireRequestApproveError(true);
                                                setHireRequestApproveErrorMessage("Approve is required");
                                            }
                                        }}
                                     
                                        renderInput={(params) => 
                                        <TextField {...params}
                                        error={HireRequestApproveError}
                                        autoFocus
                                        helperText={HireRequestApproveErrorMessage}
                                            required label="Approve Status" 
                                        variant="standard" />}
                                    />
                                </Grid>

                                <Grid item mt={3} lg={12} md={12} xs={12} >
                                            <TextField 
                                            id="outlined-basic" 
                                            label="Approve Remarks" 
                                            variant="outlined" 
                                            name='ApproveRemarks'
                                            fullWidth
                                            multiline
                                            rows={4}
                                            error={HireRequestApproveRemarksError}
                                            onChange={handleApproveRemarksChange}
                                            value={HireRequestApproveRemarksValue}
                                            helperText={HireRequestApproveRemarksErrorMessage}                                           
                                            />
                                        </Grid>
                            </Grid>
                        </Box>

                    <Box mt={5} ml={2} mr={2} mb={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Button
                                    sx={{
                                        background: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}

                                    onClick={handleClose} 
                                >
                                    Back
                                </Button>

                                <Button
                                    sx={{
                                        background: '#25425F',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        "&:hover":{
                                            backgroundColor:'#25425F',
                                            color:'white',
                                        },
                                        color: 'white',
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                    type='submit'
                                    onClick={(event)=>{
                                        EditHireRequest(event);
                                    }}
                                >
                                    Update
                                </Button>
                                </Box>
                    </form>


                </Box>
            </Box>
        
        </Box>
    )
}
export default EditHireRequestApprovalsPage;

// EOF SOP-76 feedback points L2 (7) Included BY PCS edit page for Hire requests
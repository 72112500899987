// SOP-88 Included By PCS [View Page rights by User]

import  Box  from "@mui/material/Box";
import React, { useState, useEffect } from "react";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { useNavigate } from "react-router-dom";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';

const ViewByUser = () => {
  
  // Use navigate
  const navigate = useNavigate();

  // useState
  let  [order, setOrder] = React.useState<"desc" | "asc">("desc");
  let  [orderBy, setOrderBy] = React.useState("");
  let  [tableData, setTableData] = React.useState([]);
  const [page, setPage] = React.useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [isLoading, setLoading] = useState(false);
         
  // Head cell of Employee
    const EmployeeHeadCell = [
        {
          id: "employee_name",
          numeric: false,
          disablePadding: false,
          label: "Employee Name",
          isSort: true,
          isBold: true,
        },
        {
        id: "employee_email",
        numeric: false,
        disablePadding: false,
        label: "Email",
        isSort: true,
        isBold: true,
        },
        {
        id: "employee_mobile",
        numeric: false,
        disablePadding: false,
        label: "Mobile Number",
        isSort: true,
        isBold: true,
       },
       {
        id: "work_location",
        numeric: false,
        disablePadding: false,
        label: "Work Location",
        isSort: true,
        isBold: true,
       },
      {
        id: "user_role_name",
        numeric: false,
        disablePadding: false,
        label: "Role",
        isSort: true,
        isBold: true,
      },
      {
        id: "active",
        numeric: false,
        disablePadding: false,
        label: "Active",
        isSort: true,
        isBold: true,   
      },
      {
        id: "action",
        numeric: false,
        disablePadding: false,
        label: "View Assigned pages",
        isSort: false,
      },
  ];

  const [cells, setCells] = useState<any>(EmployeeHeadCell);

  //  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId

  //  To get categories list search API  
  const getEmployeeSearchList = () => 
    {
      // Initializing Variable 
        let EmployeeName = null;
        let EmployeeEamil = null;
        let EmployeeMobile = null;
        let EmployeeRole =null;
        let WorkLocation = null;
        let bActiveState = ""; 
        
        for (const item of cells)
        {
        if (item.id === "employee_name") {
        EmployeeName = item.value;
        }
        if (item.id === "employee_email") {
        EmployeeEamil = item.value;
        }
        if (item.id === "employee_mobile") {
        EmployeeMobile = item.value;
        }
        if (item.id === "work_location") {
        WorkLocation = item.value;
        }
        if (item.id === "user_role_name") {
        EmployeeRole = item.value;
        }
        if (item.id === "active") {
        bActiveState = item.value;
        }
        }

        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/employee/get_employee_list_search`, {
        SearchEmployeeName:EmployeeName,
        SearchEmployeeEmail:EmployeeEamil,
        SearchEmployeeMobile:EmployeeMobile,
        SearchWorkLocation:WorkLocation,
        SearchEmployeeRole:EmployeeRole,
        SearchEmployeeActive: bActiveState,
        SortColumnName: orderBy,
        SortColumnDirection: order,
        StartIndex: page + 1,
        PageSize: rowsPerPage,
        CompanyId:CompanyId
        })
        .then((res: any) => 
        {
        const formattedTableData= res.data.data.map((item:any)=>{
        return {...item,
        id:item.employee_id
        }
        }) 
        setTableData(formattedTableData);
        if(res.data.data[0]===undefined)
        {
        setCount(0);
        }
        else
        {
        setCount(res.data.data[0].total_count);
        }
        })
        .catch((error) => 
        {
        if (error.response.status === 404)
        {
        setTableData([]);
        }
        Failed(error.response.message);
        });
    };

  useEffect(() => {
              getEmployeeSearchList();
                  }, [rowsPerPage, page, orderBy, order]);

  // Sort Handler
      const handleRequestSort = (event: any, property: any) =>
      {
      const isAsc = orderBy === property && order === "asc";
      order = isAsc ? "desc" : "asc";
      setOrder(order);
      orderBy = property;
      setOrderBy(orderBy);
      };

  // Action function of table icons
      const actionFunction = (id: string, actionText: string) => 
        {
          if (actionText === "view"){
          navigate(`/view_pagerights_by_employee/${id}`);
            };
        };

  // Search Handler of table
      const handleSearch = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              const search = [...cells];
              const index = cells.findIndex((c: any) => c.id === e.target.name);
              if (index !== -1) {
                  search[index].value = e.target.value;
              }
              setCells(search);
              handleChangePage(setPage,0);
              getEmployeeSearchList()
          };
        
  // Pagination Handler of table
      const handleChangePage = (event: unknown, newPage: number) => {
          setPage(newPage);
          };
      
  // Rows Per page Handler
      const handleChangeRowsPerPage = (
          event: React.ChangeEvent<HTMLInputElement>
              ) => {
          setRowsPerPage(+event.target.value);
          setPage(0);
          };

 return(
  <>
   <Box sx={{borderRadius:'10px'}}>
  <MainContainerWrapper 
   headingText=''
   isSearch={false}
  >
  <Box sx={{}}>
  <Box bgcolor={'white'}>
  <Box>
  <EnhancedTable
      headCells={EmployeeHeadCell}
      rows={tableData}
      rowsPerPage={rowsPerPage}
      page={page}
      handleSearch={handleSearch}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleRequestSort={handleRequestSort}
      order={order}
      orderBy={orderBy}
      actionFunction={actionFunction}
      isView={true}
      isDelete={false}
      isEdit={false}
      total={count}
      isSearch={true}
  />
  </Box>
  </Box>
  </Box>
  </MainContainerWrapper>
  </Box>
  </>
 )

}

export default ViewByUser;

// EOF SOP-88 Included By PCS
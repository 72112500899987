// SOP-88 Included By PCS [User rights for Employee ]

import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Card,
    Button,
    Grid,
    
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ViewByUser from './ViewByUser';
import ViewByPage from './ViewByPage';

interface CustomTabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel: React.FC<CustomTabPanelProps> = (props) => 
  {
    const { children, value, index, ...other } = props;
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
        )}
        </div>
      );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index:any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  const ViewUserPageRights =()=>{ 
     const [value, setValue] = React.useState(0);
     const handleChange = (event:any, newValue:any) => {
      setValue(newValue);
       };
  
    // Navigate function
    const navigate = useNavigate();
  
return (
    <div>
    <Typography variant="h1" sx={{ color:'#25425f', marginTop:3}}>
        View User Page Rights 
    </Typography>
    <Box sx={{ width: '100%', marginTop:3, borderRadius:5 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs sx={{backgroundColor:''}} value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="View By User" {...a11yProps(0)} sx={{marginLeft:3, color:'#25425f', marginTop:3, padding:2}} />
          <Tab label="View By Page" {...a11yProps(1)} sx={{marginLeft:3, color:'#25425f', marginTop:3, padding:2}} />
        </Tabs>
      </Box>
      <CustomTabPanel  value={value} index={0}>
        <ViewByUser/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ViewByPage/>
      </CustomTabPanel>
      </Box>
      <Grid container lg={12} >
      <Grid item lg={8} sx={{marginLeft:'40px'}}> 
      <Button
        sx={{
            background: 'white',
            border: 'none',
            borderRadius: '5px',
            padding:{
                lg: "0 70px",
                md: "0 70",
                xs:'0 50px'
            },
            height: "3rem",
            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
        }}
        onClick={()=>{
          navigate('/backoffice')
        }} 
      >
      Back
      </Button>
      </Grid>
      </Grid>
      </div>
   );
     }
    
export default ViewUserPageRights;
// EOF SOP-88 Included By PCS

// SOP-90 Included By PCS [Export to excel]

// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Success } from '../../atic-common-helpers/helpers/toast.helper';
import Swal from "sweetalert2";
import { PiExportBold } from "react-icons/pi";
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';

// Page start
const EmployeeExportToExcel = 
// To get handleAddDepartmentClose & getdepartmentlistsearch from department page
    ({
       handleEmployeeExcelClose,
        
    }: {
         handleEmployeeExcelClose: (event: React.MouseEvent) => void,
     }) =>{

    // use navigate and use state declaration
      const navigate = useNavigate();


    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);


    //  Global data from session storage
         const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
         const GlobalData = JSON.parse(GlobalDataFromSession);
        let CompanyId = GlobalData.GlobalCompanyId
        

    // To render handle close function to close dialouge box
      const handleClose = (event: React.MouseEvent) => {
        if (handleEmployeeExcelClose && typeof handleEmployeeExcelClose === 'function') {
                handleEmployeeExcelClose(event);
            }
        };
 
     
    // UseEffect Declaration
    useEffect(() => {
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
       }, []);


// To fetch  column names  
    const fetchColumns = async () =>
    {
        const EmployeeListPayload ={
            CompanyId:CompanyId,
                    }
        try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/employee/getEmployeeColumnNames`,EmployeeListPayload);
        setSelectedColumns(res.data.columnNames);
            } 
        catch (error) {
        console.error('Error fetching columns:', error);
                      }
    };
// To remove selected column
    const handleRemoveColumn = (column: string) => 
    {
    // Check if only one column is left
        if (selectedColumns.length === 1) {
        Swal.fire({
            icon: 'warning',
            text: 'Minimum one column required to export',
            confirmButtonText: 'OK'
        });
        return;
        }
        setSelectedColumns(prev => {
        const updatedColumns = prev.filter(col => col !== column);
        return updatedColumns;
            });
    };
// To download the excel 
    const handleDownload = async () => 
    {
    const excelPayload = {
        CompanyId: CompanyId,
        SelectedColumns: selectedColumns,
            };
        // console.log('excelPayload',excelPayload)
    try{
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/employee/exportToExcelEmployee`, excelPayload, { responseType: 'blob' });
        if (response.status === 200)
        {
        const { columnNames, file } = response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Employees.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
        // console.log('Column Names :', columnNames);
        }
        } 
        catch (error) {
            console.error('Error downloading file:', error);
                      }
    };

    useEffect(() => {
        fetchColumns();
    }, []);

    return(
    <>
    <Box>
    <Box display={'flex'} justifyContent={'center'}>
    <Box bgcolor={'white'} borderRadius={3}
     sx={{
    width:{
    lg:1800,
    md:1200,
    xs:1200
    },
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    padding: 2,
      }}
    >
    <Box p={3}>
    <Typography variant='h2' sx={{ textAlign: 'left', textDecoration:'underline',fontWeight: 'bold', color: '#25425F' }}>
     Employee List </Typography>
    </Box>
    <Box p={3}>
    <Grid         
    mb={2} 
    container
    spacing={{ xs: 2, md: 2 }}
    columns={{ xs: 4, sm: 8, md: 8, lg: 12 }}
    rowSpacing={{ xs: 2, md: 1 }}
    columnSpacing={{ xs: 2, md: 3 }}
    >
<Grid item xs={12} sm={12} md={12} lg={12}>
<div style={{display:'flex'}}>
    <Typography variant='h3' sx={{ marginBottom: 3, color: '#25425F' }}>Available Columns </Typography>
    <Typography variant='h4' sx={{ marginBottom: 2, color: '#25425F',marginLeft:'4px' }}>( </Typography>
    <CloseIcon style={{ fontSize: '20px', color: 'red' ,marginLeft:'5px'}} />
    <Typography  variant='h4' sx={{ marginBottom: 2, color: '#25425F',marginLeft:'5px' }}>  click to exclude from export ) </Typography>
</div>
    <Box display="flex" flexDirection="row" flexWrap="wrap">
    {selectedColumns.map((column) => (
     <Chip
        key={column} 
        label={<Typography>{column}</Typography>}
        onDelete={() => handleRemoveColumn(column)}
        deleteIcon={<CloseIcon style={{ fontSize: '16px', color: 'red' ,marginLeft:'5px'}} />}
        sx={{
        backgroundColor: 'white',
        marginTop: '2px',
        marginLeft:'5px',
        padding: '5px 10px',
        border:'1px solid lightblue',
        '&:hover': {
        border: '1px solid blue',
                    },
        }}
        />
    ))}
     </Box>
</Grid>
<Grid item xs={12} sm={6} md={6} lg={6}>
   <Button 
        sx={{
        background: '#25425F',
        color: 'white',
        height: "3rem",
        marginTop: 1,
        width: '100%',
        marginLeft: '10px', 
        borderRadius: '5px',
        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px, rgba(14, 30, 37, 0.32) 0px 2px 16px',
        '&:hover': {
            backgroundColor: '#1D3442',
        },
        }}
  onClick={handleDownload}>Export to Excel</Button>
</Grid>
<Grid item xs={12} sm={6} md={6} lg={6}>
<Button
    sx={{
    backgroundColor: 'white',
    border: '1px solid #ccc',
    color: '#25425F',
    borderRadius: '5px',
     width: '100%',
    height: "3rem",
    marginTop: 1,
     marginLeft: '10px',
    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px, rgba(14, 30, 37, 0.32) 0px 2px 16px',
    '&:hover': {
        backgroundColor: '#f0f0f0',
    },
    }}
    onClick={handleClose} 
    >
    Close 
    </Button>
    </Grid>
</Grid>
    </Box>
    </Box>
    </Box>
    </Box>
    </>
    )

}

export default EmployeeExportToExcel;

// EOF SOP-90 Included By PCS [Export to excel]
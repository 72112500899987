// SOP-88 Included By PCS [View Page rights by User]

import  Box  from "@mui/material/Box";
import React, { useState, useEffect } from "react";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { useNavigate } from "react-router-dom";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';

const ViewByPage = () => {

  // Use navigate
    const navigate = useNavigate();

  // useState
  let  [order, setOrder] = React.useState<"desc" | "asc">("desc");
  let  [orderBy, setOrderBy] = React.useState("");
  let  [tableData, setTableData] = React.useState([]);
  const [page, setPage] = React.useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
 
        
  // Head cell of Pages
    const PagesHeadCell = [
        {
          id: "menuname",
          numeric: false,
          disablePadding: false,
          label: "Page Name",
          isSort: true,
          isBold: true,
        },
        {
          id: "action",
          numeric: false,
          disablePadding: false,
          label: "View Assigned Users",
          isSort: false,
        },
    ];

  const [cells, setCells] = useState<any>(PagesHeadCell);

  // Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId
      
  // To get categories list search API  
    const getPagesSearchList = () => 
      {
        // Initializing Variable 
          let MenuName = null;
            for (const item of cells){
              if (item.id === "menuname") {
                  MenuName = item.value;
              }
              }
          commonService
          .postService(`${process.env.REACT_APP_BACKEND_URL}/viewuserpagerights/get_pages_list_search`, {
          SearchPageName:MenuName,
          SortColumnName: orderBy,
          SortColumnDirection: order,
          StartIndex: page + 1,
          PageSize: rowsPerPage,
        
          })
          .then((res: any) => 
          {
          // console.log("res",res)
          const formattedTableData= res.data.data.map((item:any)=>{
          return {...item,
          id:item.menuid,
          }
          }) 
          setTableData(formattedTableData);
          if(res.data.data[0]===undefined)
          {
          setCount(0);
          }
          else
          {
          setCount(res.data.data[0].total_count);
          }
          })
          .catch((error) => 
          {
          if (error.response.status === 404)
          {
          setTableData([]);
          }
          Failed(error.response.message);
          });
      };

  useEffect(() => {
              getPagesSearchList();
        }, [rowsPerPage, page, orderBy, order]);

  // Sort Handler
      const handleRequestSort = (event: any, property: any) =>
      {
      const isAsc = orderBy === property && order === "asc";
      order = isAsc ? "desc" : "asc";
      setOrder(order);
      orderBy = property;
      setOrderBy(orderBy);
      };

  // Action function 
      const actionFunction = (id: string, actionText: string) => 
        {
          if (actionText === "view"){
            navigate(`/view_pagerights_by_page/${id}`);
          };
        };

  // Search Handler of table
      const handleSearch = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              const search = [...cells];
              const index = cells.findIndex((c: any) => c.id === e.target.name);
              if (index !== -1) {
                  search[index].value = e.target.value;
              }
              setCells(search);
              handleChangePage(setPage,0);
              getPagesSearchList()
          };
        
  // Pagination Handler of table
      const handleChangePage = (event: unknown, newPage: number) => {
          setPage(newPage);
          };
      
  // Rows Per page Handler
      const handleChangeRowsPerPage = (
          event: React.ChangeEvent<HTMLInputElement>
              ) => {
          setRowsPerPage(+event.target.value);
          setPage(0);
          };

return(
      <>
      <Box sx={{}}>
      <MainContainerWrapper 
      // headingText='Pages'
      headingText=''
        isSearch={false}
      >
      <Box sx={{}}>
      <Box bgcolor={'white'}>
      <Box >
      <EnhancedTable
          headCells={PagesHeadCell}
          rows={tableData}
          rowsPerPage={rowsPerPage}
          page={page}
          handleSearch={handleSearch}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          actionFunction={actionFunction}
          isView={true}
          isDelete={false}
          isEdit={false}
          total={count}
          isSearch={true}
        />
        </Box>
        </Box>
        </Box>
        </MainContainerWrapper>
        </Box>
       
      </>
    )
}

export default ViewByPage;

// EOF SOP-88 Included By PCS
const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '/',
    defaultPath: '/login',
    fontFamily: `PoppinsVariableFont`,
    borderRadius: 5,
    REACT_APP_SMARTOPZ_SESSION_TIMEOUT: '3600000' ,// SOP-86 Included By PCS[session time out]
    REACT_APP_API_SECRET_KEY:'QWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTY2N' //SOP-85 Included By PCS [Including JWT token]
};

export default config;
// Included by sanjana SOP-17
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import AddEmployeePage from './AddEmployee';
import deleteAlert from '../../component/HOC/deleteAlert';

//SOP-17 Included By PCS [Testing feedback corrections - to restrict deleting super Admin]
import Swal from "sweetalert2";
//EOF SOP-17 Included By PCS [Testing feedback corrections - to restrict deleting super Admin]

//SOP-90 Inlcuded By PCS [Export to excel]
import EmployeeExportToExcel from './EmployeeExportToExcel'
import { PiExportBold } from "react-icons/pi";
//EOF SOP-90 Inlcuded By PCS [Export to excel]

const EmployeePage =()=>{

    // Use navigate
    const navigate = useNavigate();


     // useState
     let  [order, setOrder] = React.useState<"desc" | "asc">("desc");
     let  [orderBy, setOrderBy] = React.useState("");
     let  [tableData, setTableData] = React.useState([]);
     const [page, setPage] = React.useState<number>(0);
     const [count, setCount] = useState<number>(0);
     const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
     const [AddEmployeeOpen, setAddEmployeeOpen] = React.useState(false);

    //SOP-90 Inlcuded By PCS [Export to excel]
        const [ExportToExcelOpen, setExportToExcelOpen] = React.useState(false);

          // To open export excel dialog box
            const handleEmployeeExcelOpen = () => {
            setExportToExcelOpen(true);
            };

          // To close export excel dialog box
            const handleEmployeeExcelClose = () => {
            setExportToExcelOpen(false);
            };
    //EOF SOP-90 Inlcuded By PCS [Export to excel]

   // Head cell of Employee
      const EmployeeHeadCell = [
        
        {
         id: "employee_name",
         numeric: false,
         disablePadding: false,
         label: "Employee Name",
         isSort: true,
         isBold: true,
       },
       {
        id: "employee_email",
        numeric: false,
        disablePadding: false,
        label: "Email",
        isSort: true,
        isBold: true,
      },{
        id: "employee_mobile",
        numeric: false,
        disablePadding: false,
        label: "Mobile Number",
        isSort: true,
        isBold: true,
      },{
        id: "work_location",
        numeric: false,
        disablePadding: false,
        label: "Work Location",
        isSort: true,
        isBold: true,
      }
      ,{
        id: "user_role_name",
        numeric: false,
        disablePadding: false,
        label: "Role",
        isSort: true,
        isBold: true,
      },
       {
        id: "active",
        numeric: false,
        disablePadding: false,
        label: "Active",
        isSort: true,
        isBold: true,
        // defaultValue:initialActivestatus
      },
      {
        id: "action",
        numeric: false,
        disablePadding: false,
        label: "Actions",
        isSort: false,
      },
       ];

      const [cells, setCells] = useState<any>(EmployeeHeadCell);


      //  Global data from session storage
      const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
      const GlobalData = JSON.parse(GlobalDataFromSession);
      //console.log("GlobalData",GlobalData)
      let CompanyId = GlobalData.GlobalCompanyId
      //console.log("CompanyId",CompanyId)

      //  To get categories list search API  
       const getEmployeeSearchList = () => 
       {
     
     // Initializing Variable 
         let EmployeeName = null;
         let EmployeeEamil = null;
         let EmployeeMobile = null;
         let EmployeeRole =null;
         let WorkLocation = null;
         let bActiveState = ""; 
     
       for (const item of cells)
       {
          if (item.id === "employee_name") {
           EmployeeName = item.value;
         }
         if (item.id === "employee_email") {
            EmployeeEamil = item.value;
          }
          if (item.id === "employee_mobile") {
            EmployeeMobile = item.value;
          }
          if (item.id === "work_location") {
            WorkLocation = item.value;
          }
          if (item.id === "user_role_name") {
            EmployeeRole = item.value;
          }
        
         if (item.id === "active") {
            bActiveState = item.value;
         }
       }

         commonService
         .postService(`${process.env.REACT_APP_BACKEND_URL}/employee/get_employee_list_search`, {
            SearchEmployeeName:EmployeeName,
            SearchEmployeeEmail:EmployeeEamil,
            SearchEmployeeMobile:EmployeeMobile,
            SearchWorkLocation:WorkLocation,
            SearchEmployeeRole:EmployeeRole,
            SearchEmployeeActive: bActiveState,
            SortColumnName: orderBy,
            SortColumnDirection: order,
            StartIndex: page + 1,
            PageSize: rowsPerPage,
            CompanyId:CompanyId
         })
         .then((res: any) => 
         {
          // console.log("Employee list",res)
          const formattedTableData= res.data.data.map((item:any)=>{
         return {...item,
            id:item.employee_id
        }
           }) 
           //console.log("formattedTableData",formattedTableData)
           
         setTableData(formattedTableData);
         if(res.data.data[0]===undefined)
           {
             setCount(0);
           }
           else
           {
             setCount(res.data.data[0].total_count);
           }
         })
       .catch((error) => 
         {
           if (error.response.status === 404)
           {
             setTableData([]);
           }
          Failed(error.response.message);
         });
       };


        // Sort Handler
        const handleRequestSort = (event: any, property: any) =>
        {
        const isAsc = orderBy === property && order === "asc";
        order = isAsc ? "desc" : "asc";
        setOrder(order);
        orderBy = property;
        setOrderBy(orderBy);
        };


//SOP-17 Modified By PCS  [Testing feedback corrections - to restrict deleting Super Admin]
  // Handle Delete Commented By PCS
          //  const handleDeleteFleet = (id: string) => {
          //   deleteAlert({
          //     title: "Delete",
          //     message: "Are you sure?",
          //     confirmButtonText: "Yes",
          //     onConfirmed: DeleteEmployee,
          //     data: id,
          //   });
          // };
  // Handle Delete Included By PCS
  const handleDeleteFleet = (id: string) => 
     {
       commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/employee/getEmployeeById`, {
            CompanyId:CompanyId,
            EmployeeId:id
        })
        .then((res: any) => 
        { 
           const EmployeeData = res.data.data[0]
           //console.log("User Role",EmployeeData.employee_role_id)
           
           if(EmployeeData.employee_role_id == '1'){
               Swal.fire({
                html: '<span style="font-size: 1.3rem;">Cannot delete Super Admin</span>',
                confirmButtonText: 'OK',
                allowOutsideClick:false
                    });
              return; 
           }
           else{
                deleteAlert({
                  title: "Delete",
                  message: "Are you sure?",
                  confirmButtonText: "Yes",
                  onConfirmed: DeleteEmployee,
                  data: id,
                  });
              }
        }).catch((error) =>  
        { 
         //Failed(error.response.message);
        });

   };
  //EOF SOP-17 Inlcuded By PCS[Testing feedback corrections]


          const handleChangeList = () => {
            if (page === 0) getEmployeeSearchList();
            else setPage(0);
          };

        const DeleteEmployee = (id: string) =>{

          commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/employee/employee_create_update_delete`, 
            {
              SaveUpdateKey:null ,
              EmployeeId:id,
              EmployeeName:null,
              EmployeeMail:null,
              EmployeeMobile:null,
              EmployeePassword:null,
              EmployeeRole:null,
              CompanyId: CompanyId,
              Active: null,
              CreatedBy: null,
              LastModifiedBy: null,
              IsRemove: 'Y'
            })
            .then((res: any) => {
              if (res.status === 200) {
                  Success("Employee Deleted Successfully");
                  handleChangeList();
                  getEmployeeSearchList();
              }
            })
            .catch((err) => {
              // Modified by sanjana SOP-52 to add failed message content
              // Failed("");
              Failed("Reference information is found in the transaction table");
              // End of SOP-52
            });
        }

        // Action function of table icons
        const actionFunction = (id: string, actionText: string) => 
        {
            if (actionText === "edit"){navigate(`/edit_employee/${id}`);};
            if (actionText === "delete") handleDeleteFleet(id)
            if (actionText === "requestanasset"){navigate(`/request_an_asset/${id}`);};//SOP-89 Included By PCS [to include requestanasset in action]
        };
      
    
        // Search Handler of table
        const handleSearch = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const search = [...cells];
            const index = cells.findIndex((c: any) => c.id === e.target.name);
            //console.log("e.target.name",e.target.name)
            if (index !== -1) {
                search[index].value = e.target.value;
            }
            //console.log("search",search)
            setCells(search);
            handleChangePage(setPage,0);
            getEmployeeSearchList()
        };
    
    
        // Pagination Handler of table
        const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        };
    
        // Rows Per page Handler
        const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
            ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        };
    
    
        useEffect(() => {
            getEmployeeSearchList();

          }, [rowsPerPage, page, orderBy, order]); 
         
    return(
        <>
        <MainContainerWrapper  headingText='Employees'
        handleClick={() =>{
            navigate('/add_employee')
        }}
        buttonText="Add Employee"
        isSearch={false}
        isCreateButton={true}
        buttonIcon={<span>&#43;</span>}
        
        >
          <Box>
          <Box bgcolor={'white'}>
                    <Box>
                    <EnhancedTable
                    headCells={EmployeeHeadCell}
                    rows={tableData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleSearch={handleSearch}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                    actionFunction={actionFunction}
                    isView={false}
                    isDelete={true}
                    isEdit={true}
                   //SOP-89 Included By PCS [to include requestanasset]
                    isRequestanAsset={true}
                   //EOF SOP-89 Included By PCS [to include requestanasset]
                    total={count}
                    isSearch={true}
                    />
                    </Box>
                </Box>
                {/*//SOP-90 Included By PCS [EmployeeExportToExcel dialog box] */}
                  <Box>
                  <Dialog  open={ExportToExcelOpen}>
                      <EmployeeExportToExcel handleEmployeeExcelClose={handleEmployeeExcelClose}/> 
                  </Dialog>
                  </Box>
                {/* //EOF SOP-90 Included By PCS [EmployeeExportToExcel dialog box]*/}
                {/* //SOP-90 Inlcuded By PCS [Included Export button and modified alignment]*/}
                <Grid         
                    container
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 4, sm: 8, md: 8, lg: 12 }}
                    rowSpacing={{ xs: 2, md: 1 }}
                    columnSpacing={{ xs: 2, md: 3 }}
                >
                <Grid display="flex" justifyContent="flex-start" item xs={12} sm={6} md={6} lg={6}>
                       <Button
                          sx={{
                             
                              color:'white',
                              marginTop:'1%',
                              background: '#25425F',
                              border: 'none',
                              borderRadius: '5px',
                              padding:{
                                  lg: "0 70px",
                                  md: "0 70px",
                                  xs:'0 50px'
                                        },
                            '&:hover': {
                                       color: '#25425F',
                                      },

                              height: "3rem",
                              boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                          }}

                          onClick={handleEmployeeExcelOpen}
                          startIcon={<PiExportBold />}
                      >
                        Export
                      </Button>
                  </Grid>
                  {/* //EOF SOP-90 Inlcuded By PCS [Included Export button and modified alignment]*/}
                  <Grid display="flex" 
                   justifyContent={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-end', lg: 'flex-end' }}
                   item xs={12} sm={6} md={6} lg={6}>
                      <Button
                          sx={{
                              marginTop:'1%',
                              background: 'white',
                              border: 'none',
                              borderRadius: '5px',
                              padding:{
                                lg: "0 70px",
                                md: "0 70px",
                                xs:'0 50px'
    
                            },
                            height: "3rem",
                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                           }}

                          onClick={()=>{
                            {/* //SOP-75 feedback points L1 modified by PCS [changing nav link to HR Workspace] */}
                                // navigate('/home')
                                navigate('/HRDashBoard')
                            {/* //EOF SOP-75 feedback points L1 modified by PCS [changing nav link to HR workspace] */}
                            }} 
                       >
                      Back
                   </Button>
                </Grid>
                </Grid>
            </Box>
        </MainContainerWrapper>

        
        </>
    )

}
export default  EmployeePage;

// End of SOP-17
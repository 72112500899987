// SOP-88 Included By PCS [View Page rights by User]

import {
Box,
Card,
Button,
TextField,
Stack,
FormControl,
InputAdornment,
Avatar,
useMediaQuery,
Menu,
MenuItem,
InputLabel,
Grid,
Paper,
Divider,
Tooltip,
Dialog,
Select,
} from '@mui/material';
import React, { useState, useEffect } from "react";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { useNavigate, useParams } from "react-router-dom";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import axios from "axios";
import { Link } from 'react-router-dom';

const ViewUserRightsByEmployee = () => {
  
  //Use navigate
   const navigate = useNavigate();
   const {id} = useParams();
  const EmployeeId = id

  // useState
  let  [order, setOrder] = React.useState<"desc" | "asc">("desc");
  let  [orderBy, setOrderBy] = React.useState("");
  let  [tableData, setTableData] = React.useState([]);
  const [page, setPage] = React.useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [isLoading, setLoading] = useState(false);
  const [EmployeeName, setEmployeeName]=useState('')
          
// Head cell of Pages
    const PagesHeadCell = [
        
    {
    id: "menuname",
    numeric: false,
    disablePadding: false,
    label: "Menu Name",
    isSort: true,
    isBold: true,
    },
   {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "View Assigned Users",
    isSort: false,
    },
                 
];

   const [cells, setCells] = useState<any>(PagesHeadCell);

   // Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId


   // To get categories list search API  
    const getEmployeeSearchList = () => 
    {
    // Initializing Variable 
        let MenuName = null;
        for (const item of cells)
        {
        if (item.id === "menuname") 
        {
            MenuName = item.value;
        }
        }
        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/viewuserpagerights/search_get_view_pagerights_by_employee`, {
        EmployeeId:EmployeeId,
        CompanyId:CompanyId,
        SearchPageName:MenuName,
        SortColumnName:orderBy,
        SortColumnDirection:order,
        StartIndex:page + 1,
        PageSize:rowsPerPage,
        })
        .then((res: any) => 
        {
        const formattedTableData= res.data.data.map((item:any)=>{
        return {...item,
        id:item.menuid,
        menuname: (
            <Tooltip title="View Assigned Users" arrow>
            <Link to={`/view_pagerights_by_page/${item.menuid}`} style={{ textDecoration: 'underline', color: 'darkblue' }}>
                {item.menuname}
            </Link>
            </Tooltip>
        ),
        }
        }) 
        setTableData(formattedTableData);
        if(res.data.data[0]===undefined)
        {
        setCount(0);
        }
        else
        {
        setCount(res.data.data[0].total_count);
        }
        })
        .catch((error) => 
        {
        if (error.response.status === 404)
        {
        setTableData([]);
        }
        Failed(error.response.message);
        });
    };

    const getEmployeeById = async () => 
    {
        const EmployeeListPayload ={
        EmployeeId:EmployeeId,
        CompanyId:CompanyId
                }
            try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/employee/getEmployeeById`,EmployeeListPayload);
            setEmployeeName(res.data.data[0].employee_name)
            } 
            catch (err) {
            console.log( "co",err);
            }
    };

   useEffect(() => {
    getEmployeeById();
    getEmployeeSearchList();
        }, [rowsPerPage, page, orderBy, order]);

// Sort Handler
    const handleRequestSort = (event: any, property: any) =>
    {
    const isAsc = orderBy === property && order === "asc";
    order = isAsc ? "desc" : "asc";
    setOrder(order);
    orderBy = property;
    setOrderBy(orderBy);
    };

// Action function of table icons
    const actionFunction = (id: string, actionText: string) => 
    {
        if (actionText === "view"){
            navigate(`/view_pagerights_by_page/${id}`);
        };
    };

// Search Handler of table
    const handleSearch = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const search = [...cells];
            const index = cells.findIndex((c: any) => c.id === e.target.name);
            if (index !== -1) {
                search[index].value = e.target.value;
            }
            setCells(search);
            handleChangePage(setPage,0);
            getEmployeeSearchList()
        };
    
// Pagination Handler of table
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        };
        
// Rows Per page Handler
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
            ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        };
        
 return(
  <>
  <MainContainerWrapper 
  headingText={`Assigned Pages for ( ${EmployeeName} )`} 
  isSearch={false}
  >
  <Box>
  <Box bgcolor={'white'}>
  <Box>
  <EnhancedTable
    headCells={PagesHeadCell}
    rows={tableData}
    rowsPerPage={rowsPerPage}
    page={page}
    handleSearch={handleSearch}
    handleChangePage={handleChangePage}
    handleChangeRowsPerPage={handleChangeRowsPerPage}
    handleRequestSort={handleRequestSort}
    order={order}
    orderBy={orderBy}
    actionFunction={actionFunction}
    isView={true}
    isDelete={false}
    isEdit={false}
    total={count}
    isSearch={true}
  />
  </Box>
  </Box>
  <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
  </Box>
  </Box>

  <Grid item lg={8}> 
    <Button
    sx={{
        background: 'white',
        border: 'none',
        borderRadius: '5px',
        padding:{
            lg: "0 70px",
            md: "0 70",
            xs:'0 50px'
            },
        height: "3rem",
        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
    }}
    onClick={()=>{
    navigate('/view_user_pagerights')
    }} 
    >
Back
    </Button>
  </Grid>
  </MainContainerWrapper>
  </>
 )

}

export default ViewUserRightsByEmployee;

// EOF SOP-88 Included By PCS
import axios from "axios";
import config from "../constant/config";//SOP-85 Included By PCS [for JWT token]


axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const setAxiosDefauls = () => {
  axios.interceptors.request.use(onRequestFulfilled, onRequestRejected);
  axios.interceptors.response.use(onResponseFulfilled, onResponseRejected);
};

export const resetSession = () => {

  localStorage.remove("at-dl-auth");
};

//SOP-85 Modified By PCS[config renamed to configuration due to conflict with config.js file]
// const onRequestFulfilled = (config: any) => {
const onRequestFulfilled = (configuration: any) => {
    let token = localStorage.getItem("avt-auth-token");
    if (token && token !== "undefined" ) {
    //EOF SOP-85 Modified By PCS [config is from db.js]
     const SecretKeys = config.REACT_APP_API_SECRET_KEY;
      configuration.headers = {
      "x-api-key":SecretKeys,
       Authorization: "Bearer " + token,
      };
 
  }
//SOP-85 Modified By PCS['config' renamed to 'configuration' due to conflict with config.js file]
 return Promise.resolve(configuration);
  
};

const onRequestRejected = (error: any) => {
  console.error("Request Error:", error);
  return Promise.reject(error);
};

//SOP-85 Modified By PCS['config' renamed to 'configuration' due to conflict with config.js file]
const onResponseFulfilled = (configuration: any) => {
    return Promise.resolve(configuration);
  };

const onResponseRejected = (error: any) => {
 if (error?.response?.status === 401) {
    // localStorage.clear();
    console.log('erre',error)
    alert("Session Expired now");
    window.location.href = "/";
     
  }

console.error("Response Error:", error);
  return Promise.reject(error);
};

import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import config from "./constant/config";
import "./index.scss";
import "./asset/scss/style.scss";
import "./asset/fonts/Poppins/Poppins-Regular.ttf";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//SOP-78  Included By PCS [Session management]
import { useNavigate } from "react-router-dom";
import { IdleTimerProvider } from 'react-idle-timer'; 
//EOF SOP-78  Included By PCS[Session management]


//SOP-78  Included By PCS [Session management]
const IdleTimerWrapper = () => {

    //Global data from session storage
        let GlobalDataFromSession: any= sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        const navigate = useNavigate(); 


        const handleOnIdle = () => {

                            if (GlobalDataFromSession !== null) {
                              sessionStorage.removeItem("GlobalVariable");
                              alert('Your session is expired');
                              navigate('/');
                              }
                              else {
                               
                                    }

                            };

      const handleOnActive = () => {};

      const handleOnAction = () => {};

    // Define the idle timeout (in milliseconds)


       //SOP-86 Modified By PCS [to change session time to 1hr & to get the value from config.js]
         // const idleTimeout = 1200000; // 20 minutes
         const idleTimeout = parseInt(config.REACT_APP_SMARTOPZ_SESSION_TIMEOUT);
       //EOF SOP-86 Modified By PCS [to change session time to 1hr & to get the value from config.js]
   

        return (
          <IdleTimerProvider
            timeout={idleTimeout}
            onIdle={handleOnIdle}
            onActive={handleOnActive}
            onAction={handleOnAction}
          >
            <App />
          </IdleTimerProvider>
        );
        
      };
//EOF SOP-78  Included By PCS [Session management]


const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    
    <BrowserRouter basename={config.basename}>
   
    <ToastContainer />
    {/* //SOP-78 Commented By PCS [Session management] */}
      {/* <App />  */}
    {/* //EOF SOP-78 Commented By PCS[Session management]  */}

    {/* //SOP-78 Included By PCS[Session management] */}
    <IdleTimerWrapper />
    {/* //EOF SOP-78 Included By PCS[Session management] */}

    </BrowserRouter>
   
  </Provider>
);

reportWebVitals();

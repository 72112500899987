// Included by sanjana SOP-34 to create Allocate ticket page
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import dayjs from 'dayjs';

const AllocateTicketPage = // To get handleAddClose & getPendingTicketsList from catgories page
({
    handleAddClose,
    getPendingTicketsList,
    //SOP-80 Modified By PCS [to refresh open ticket grid]
    // getAllocatedTicketsList,
    getPendingTicketingServicesList,
     //EOF SOP-80 Modified By PCS [to refresh open ticket grid]
    TicketId
}: {
    handleAddClose: (event: React.MouseEvent) => void,
    getPendingTicketsList: (event: React.MouseEvent) => void,
    //SOP-80 Modified By PCS [to refresh open ticket grid]
    // getAllocatedTicketsList: (event: React.MouseEvent) => void,
    getPendingTicketingServicesList: (event: React.MouseEvent) => void,
    //EOF SOP-80 Modified By PCS [to refresh open ticket grid]
    
    TicketId:any
})=>{

    // use state
    const [AllocationsRemarksError, setAllocationsRemarksError]=useState(false)
    const [AllocationsRemarksValue, setAllocationsRemarksValue]=useState('')
    const [AllocationsRemarksErrorMessage, setAllocationsRemarksErrorMessage]=useState('')
    const [EmployeeOptions, setEmployeeOptions] = useState<OptionType[]>([]);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState<any | null>('');
    const [EmployeeError, setEmployeeError]=useState(false)
    const [EmployeeErrorMessage, setEmployeeErrorMessage]=useState('')


    //SOP-87 Inlcuded By PCS [to get employee details]
        const [EmployeeName, setEmployeeName]=useState('')
        const [EmployeeEmail, setEmployeeEmail]=useState('')
   //EOF SOP-87 Inlcuded By PCS

    type OptionType = {
        name: string;
        value: string;
    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

    //  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    // console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId
    const UserId = GlobalData.GlobalUserId;

    //SOP-87 Included By PCS 
    const GlobalCompanyName = GlobalData.GlobalCompanyNAme
    //EOF SOP-87 Included By PCS 


    //  Handle Allocations while typing Allocations and validations
    const handleAllocationsRemarksChange = (event:any) => {
        setAllocationsRemarksValue(event.target.value);
        const trimmedAllocationsRemarksValue =  event.target.value
        if( trimmedAllocationsRemarksValue.length !== 255 ){
            setAllocationsRemarksError(false);
            setAllocationsRemarksErrorMessage("");
        }
    };

    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleAddClose && typeof handleAddClose === 'function') {
            handleAddClose(event);
        }
    };

    // to render getPendingTicketsList function after approving request
    const handlegetPendingTicketsList= (event: React.MouseEvent) => {
        if (getPendingTicketsList && typeof getPendingTicketsList === 'function') {
            getPendingTicketsList(event);
        }
    };

    // to render getAllocatedTicketsList function after approving request
    const handlegetAllocatedTicketsList= (event: React.MouseEvent) => {

         //SOP-80 Modified By PCS [to refresh open ticket grid]
            // if (getAllocatedTicketsList && typeof getAllocatedTicketsList === 'function') {
            //     getAllocatedTicketsList(event);
            // }
            if (getPendingTicketingServicesList && typeof getPendingTicketingServicesList === 'function') {
                getPendingTicketingServicesList(event);
            }
        //EOF SOP-80 Modified By PCS [to refresh open ticket grid]


    };

    // Employee Dropdown API
    const GetEmployeeList = async () => {

        const EmployeeListPayload ={
            CompanyId:CompanyId,
            active:"Y"
        }

    
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/employee/getEmployeeList`,EmployeeListPayload);
            //console.log("Employee List" ,res.data.data);
            let formattedEmployeeList = res.data.data.map((item:any) => {
            const { employee_id, employee_name } = item;
            return {
                name: employee_name,
                value: employee_id,
            };
            });
            setEmployeeOptions(formattedEmployeeList)
        } catch (err) {
            //console.log( "co",err);
        }
        };


    // To Allocations approval

    // Define a type for errors
    type Errors = {
    AllocationsSts?:boolean;
    apprveRemarks?:boolean;
    };

    

   

    const AllocateTicket = async (event: any) => {
        
        event.preventDefault()
        let errors: Errors = {};

        if (selectedEmployeeId === '' || selectedEmployeeId === null || selectedEmployeeId === undefined) {
            errors.AllocationsSts = true;
            setEmployeeErrorMessage("Employee required")
            setEmployeeError(true)
        }
        
        const trimmedAllocationsRemarksValue = AllocationsRemarksValue.trim()
        if( trimmedAllocationsRemarksValue.length >255 ){
            errors.apprveRemarks = true
            setAllocationsRemarksError(true);
            setAllocationsRemarksErrorMessage("Max 255 characters");
        }
        else {
            setAllocationsRemarksError(false);
            setAllocationsRemarksErrorMessage("");
        }


        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setEmployeeError(errors.AllocationsSts || false);
            setAllocationsRemarksError(errors.apprveRemarks || false);
            return;
        }

        const AllocateTicketPayload={
            SaveUpdateKey: "update",
            CompanyId: CompanyId,
            TicketId: TicketId,
            TicketAllocateStatus:'Y',
            TicketAllocateTo:selectedEmployeeId,
            TicketAllocateBy:UserId,
            TicketAllocateRemarks:AllocationsRemarksValue
        }

        //console.log("AllocateTicketPayload",AllocateTicketPayload)

            try {
                const res = await commonService.postService(
                    `${process.env.REACT_APP_BACKEND_URL}/ticketing/ticket_request_update`, 
                    AllocateTicketPayload
                );


          if (res.status === 200) {
              Success("Ticket Allocation Updated Successfully");
              await GetTicketDetailsByTicketId();//SOP-87 Included By PCS
              handleClose(event);
              handlegetPendingTicketsList(event);
              handlegetAllocatedTicketsList(event);
          }
        
        }
        catch (error) {
            console.error("Error:", error);
        }

    }
    
    //SOP-87 Included By PCS [to send ticket allocation email]
        //To get employee details by employeeid
            const getEmployeeDetailsById = async (EmployeeId:any) =>
                {
                    const EmployeePayload ={
                        EmployeeId:EmployeeId,
                        CompanyId:CompanyId
                       }
                        try {
                            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/employee/getEmployeeById`,EmployeePayload);
                            setEmployeeName (res.data.data[0].employee_name)
                            setEmployeeEmail(res.data.data[0].employee_email)  
                            } 
                        catch (err) {
                            console.log( "co",err);
                                    }
                };
           
            // To send email after ticket allocation
               const sendTicketAllocationEmail = async (ticketDetails:any) =>
                    {
                       const PayloadTicketAllocation = {
                            CompanyName:GlobalCompanyName,
                            CompanyId:CompanyId,
                            EmployeeEmail:EmployeeEmail,
                            EmployeeName:EmployeeName,
                            TicketId:TicketId,
                            TicketPriority:ticketDetails.priority,
                            TicketDescription:ticketDetails.description,
                            TicketShortDescription:ticketDetails.shortDescription,
                            TicketCreatedDate:ticketDetails.createdDate,
                            TicketCreatedBy:ticketDetails.createdBy,
                            TicketCategoryName:ticketDetails.categoryName,
                            TicketSubCategoryName:ticketDetails.subCategoryName, 
                            TicketLocation:ticketDetails.location, 
                            TicketAllocatedBy:ticketDetails.allocatedby, 
                            TicketAllocateddate:ticketDetails.allocateddate, 
                                                     };
                                         
                            try{
                               const res = await commonService.postService(`${process.env.REACT_APP_BACKEND_URL}/allocation/toSendTicketAllocationEmail`, PayloadTicketAllocation);
                                if (res.status === 200) {
                                 }
                            } 
                            catch (error){
                                console.error("Error while sending Ticket allocation email:", error);
                            }

                    }

            // EOF To send email after ticket allocation
            //To get ticket details by ticket id
    
                const GetTicketDetailsByTicketId = async () =>
                    {
                         const TicketIdPayload = {
                            CompanyId:CompanyId,
                            TicketId:TicketId };
                        try {
                                const res = await commonService.postService(`${process.env.REACT_APP_BACKEND_URL}/allocation/get_ticket_details_by_ticketid`, TicketIdPayload);
                                const TicketData = res.data.data[0]
                                // console.log("TicketData",TicketData)
                                const ticketDetails = {
                                            description: TicketData.ticket_issuedescription,
                                            priority: TicketData.priorityname,
                                            shortDescription: TicketData.ticket_issueshortdescription,
                                            createdDate: TicketData.createddatetime,
                                            createdBy: TicketData.ticketraisedbyname,
                                            categoryName: TicketData.categoryname,
                                            subCategoryName: TicketData.subcategoryname,
                                            location: TicketData.location,
                                            allocatedby:TicketData.allocatedbyname,
                                            allocateddate:TicketData.allocateddatetime
                                                    };
                                            sendTicketAllocationEmail(ticketDetails);//passing values to mail function

                            }
                        catch(error:any) 
                           {
                                Failed(error.response.message);
                           }
                    }
            //EOF SOP-87 Included By PCS [send email after ticket allocation]

    useEffect(() => {
        GetEmployeeList();
           }, []); 

    return(
        <Box>
            <Box>
            <Box bgcolor={'white'} borderRadius={3}
            sx={{
                width:{
                    lg:600,
                    md:600,
                    xs:350
            }}} >
                <Box>
                <Box p={3}>
                        <Typography variant='h1'
                        sx={{
                            fontSize:{
                                lg:'2rem',
                                md:'2rem',
                                xs:'1.5rem'
                            }
                        }}
                        >
                        Allocate
                        </Typography>
                    </Box>
                    
                    <form noValidate>
                        <Box p={3}>
                            <Grid container >
                                <Grid item lg={12} md={12} xs={12} >
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={EmployeeOptions as OptionType[]}
                                        filterOptions={filterOptions}
                                        getOptionLabel={(option: unknown) => (option as OptionType).name}
                                        value={EmployeeOptions.find((option: OptionType) => option.value === selectedEmployeeId) || null}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setSelectedEmployeeId(newValue.value);
                                                setEmployeeError(false);
                                                setEmployeeErrorMessage("");
                                                getEmployeeDetailsById(newValue.value)//SOP-87 Included By PCS
                                               
                                            } else {
                                                setSelectedEmployeeId('');
                                                setEmployeeError(true);
                                                setEmployeeErrorMessage("Employee is required");
                                            }
                                        }}
                                        // sx={{ width: 235, ml: 1 }}
                                        renderInput={(params) => 
                                        <TextField {...params}
                                        error={EmployeeError}
                                        autoFocus
                                        helperText={EmployeeErrorMessage}
                                            required label="Employee" 
                                        variant="standard" />}
                                    />
                                </Grid>

                                <Grid item mt={3} lg={12} md={12} xs={12} >
                                            <TextField 
                                            id="outlined-basic" 
                                            label="Allocations Remarks" 
                                            variant="outlined" 
                                            name='AllocationsRemarks'
                                            fullWidth
                                            multiline
                                            rows={4}
                                            error={AllocationsRemarksError}
                                            onChange={handleAllocationsRemarksChange}
                                            value={AllocationsRemarksValue}
                                            helperText={AllocationsRemarksErrorMessage}                                           
                                            />
                                        </Grid>
                            </Grid>
                        </Box>

                    <Box mt={5} ml={2} mr={2} mb={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Button
                                    sx={{
                                        background: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        // color: {accent},
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}

                                    onClick={handleClose} 
                                >
                                    Back
                                </Button>

                                <Button
                                    sx={{
                                        background: '#25425F',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                
                                        },
                                        "&:hover":{
                                            backgroundColor:'#25425F',
                                            color:'white',
                                        },
                                        color: 'white',
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                    type='submit'
                                    onClick={(event)=>{
                                        AllocateTicket(event);
                                    }}
                                >
                                    Save
                                </Button>
                                </Box>
                    </form>


                </Box>
            </Box>
            </Box>
        
        </Box>
    )

}

export default AllocateTicketPage;

// End of SOP-34